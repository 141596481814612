import { Tabs } from 'antd';
import { IconDownload } from 'src/components/icons/download/IconDownload';
import { IconUpload } from 'src/components/icons/upload/IconUpload';
import Table from 'src/components/table/Table';
import TableHeader from 'src/components/table/header/TableHeader';
import { sourcesTabs } from 'src/constants/sources';
import { NO_INVOICES_UPLOADED, NO_RESULTS } from 'src/constants/validation';
import useSouecesList from 'src/hooks/use-sources-list';
import { TMappedInvoicesListRecord } from 'src/utils/map-invoices-table';
import styles from './SourcesList.module.scss';
import InvoiceUploadModal from 'src/components/invoice-upload-modal/InvoiceUploadModal';

const SourcesList = () => {
  const {
    columns,
    data,
    total,
    handleTableChange,
    setSearch,
    loading,
    width,
    debouncedSearch,
    search,
    open,
    setOpen,
    noActions,
    current,
  } = useSouecesList();

  return (
    <div>
      <Tabs activeKey={'1'} items={sourcesTabs} />
      <TableHeader
        searchPlaceholder={'Invoice name, customer'}
        setSearch={setSearch}
        primaryActionTitle={'Upload Invoices'}
        primaryActionIcon={<IconUpload width="16" height="16" />}
        primaryAction={() => {
          setOpen(true);
        }}
        search={search}
        onlyPrimaryAction
        noActions={noActions || loading}
        customActionTitle={'Export to .xlsx'}
        customActionIcon={<IconDownload width="16" height="16" />}
      />
      <Table<TMappedInvoicesListRecord>
        emptyTextDescription={
          debouncedSearch ? NO_RESULTS : NO_INVOICES_UPLOADED
        }
        columns={columns}
        dataSource={data}
        onChange={handleTableChange}
        rowClassName={() => {
          return styles.interactive;
        }}
        loading={loading}
        width={width}
        height={'calc(100vh - 326px)'}
        total={total}
        current={current}
      />
      <InvoiceUploadModal
        onClose={() => {
          setOpen(false);
        }}
        visible={open}
      />
    </div>
  );
};

export default SourcesList;
