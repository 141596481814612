import clsx from 'clsx';
import { type FunctionComponent, type SVGProps } from 'react';

import styles from './IconClose.module.scss';
import { TIconProps } from 'src/types/icons';

type IconCloseProps = Omit<TIconProps, 'iconId'> & SVGProps<SVGSVGElement>;

export const IconClose: FunctionComponent<IconCloseProps> = ({
  className,
  width = 24,
  height = 24,
  fill,
  ...props
}): JSX.Element => {
  return (
    <svg
      className={clsx(className, styles.component)}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-element="icon"
      {...props}
    >
      <path
        d="M12 13.275L7.90001 17.375C7.71668 17.5583 7.50835 17.6458 7.27501 17.6375C7.04168 17.6291 6.83335 17.5333 6.65001 17.35C6.46668 17.1666 6.37501 16.9541 6.37501 16.7125C6.37501 16.4708 6.46668 16.2583 6.65001 16.075L10.725 12L6.62501 7.89998C6.44168 7.71664 6.35418 7.50414 6.36251 7.26248C6.37085 7.02081 6.46668 6.80831 6.65001 6.62498C6.83335 6.44164 7.04585 6.34998 7.28751 6.34998C7.52918 6.34998 7.74168 6.44164 7.92501 6.62498L12 10.725L16.1 6.62498C16.2833 6.44164 16.4958 6.34998 16.7375 6.34998C16.9792 6.34998 17.1917 6.44164 17.375 6.62498C17.5583 6.80831 17.65 7.02081 17.65 7.26248C17.65 7.50414 17.5583 7.71664 17.375 7.89998L13.275 12L17.375 16.1C17.5583 16.2833 17.65 16.4916 17.65 16.725C17.65 16.9583 17.5583 17.1666 17.375 17.35C17.1917 17.5333 16.9792 17.625 16.7375 17.625C16.4958 17.625 16.2833 17.5333 16.1 17.35L12 13.275Z"
        fill={fill || 'currentColor'}
      />
    </svg>
  );
};
