import { Button, Tooltip } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { IconArchive } from 'src/components/icons/archive/IconArchive';
import { IconEdit } from 'src/components/icons/edit/IconEdit';
import { SortOrder } from 'src/components/icons/sort/IconSort';
import Cell from 'src/components/table/cell/Cell';
import File from 'src/components/table/file/File';
import Highlighter from 'src/components/table/highlighter/Highlighter';
import Url from 'src/components/table/url/Url';
import {
  ActionType,
  ModalContextActions,
  TModalContextStateAction,
} from 'src/contexts/modal-context';
import styles from 'src/styles/actions.module.scss';
import { IMappedMyProducts } from 'src/types/mappings';
import { formatDate } from 'src/utils/date-formatter';
import { TMappedMyProductListRecord } from 'src/utils/map-product-table';
import { formatNumber } from 'src/utils/number-formatter';

export enum MyProductsColumns {
  NAME = 'name',
  MATERIAL_NUMBER = 'materialNumber',
  PART_NUMBER = 'partNumber',
  BARCODE = 'barCode',
  EPD_FILE = 'epdFile',
  EXTERNAL_EPD_URL = 'externalEpdUrl',
  RRP_PRICE = 'rrpPrice',
  CURRENCY = 'currency',
  QUANTITY = 'quantity',
  UOM = 'unitOfMeasurement',
  CREATED_AT = 'createdAt',
  CREATED_BY = 'createdByName',
  MODIFIED_AT = 'modifiedAt',
  MODIFIED_BY = 'modifiedByName',
  ACTIONS = 'actions',
}

export const defaultMyProductsColumns = [
  MyProductsColumns.NAME,
  MyProductsColumns.MATERIAL_NUMBER,
  MyProductsColumns.PART_NUMBER,
  MyProductsColumns.BARCODE,
  MyProductsColumns.EPD_FILE,
  MyProductsColumns.EXTERNAL_EPD_URL,
  MyProductsColumns.RRP_PRICE,
  MyProductsColumns.CURRENCY,
  MyProductsColumns.QUANTITY,
  MyProductsColumns.UOM,
  MyProductsColumns.CREATED_AT,
  MyProductsColumns.CREATED_BY,
  MyProductsColumns.MODIFIED_AT,
  MyProductsColumns.MODIFIED_BY,
  MyProductsColumns.ACTIONS,
];

export const myProductsColumnLabel = {
  [MyProductsColumns.NAME]: 'Product name',
  [MyProductsColumns.MATERIAL_NUMBER]: 'Material number',
  [MyProductsColumns.PART_NUMBER]: 'Part number',
  [MyProductsColumns.BARCODE]: 'Barcode',
  [MyProductsColumns.EPD_FILE]: 'EPD file',
  [MyProductsColumns.EXTERNAL_EPD_URL]: 'EPD link',
  [MyProductsColumns.RRP_PRICE]: 'RRP',
  [MyProductsColumns.CURRENCY]: 'Currency',
  [MyProductsColumns.QUANTITY]: 'Qty',
  [MyProductsColumns.UOM]: 'UoM',
  [MyProductsColumns.CREATED_AT]: 'Created on',
  [MyProductsColumns.CREATED_BY]: 'Created by',
  [MyProductsColumns.MODIFIED_AT]: 'Modified on',
  [MyProductsColumns.MODIFIED_BY]: 'Modified by',
  [MyProductsColumns.ACTIONS]: '',
};

export const myProductsColumnWidth = {
  [MyProductsColumns.NAME]: 240,
  [MyProductsColumns.MATERIAL_NUMBER]: 155,
  [MyProductsColumns.PART_NUMBER]: 155,
  [MyProductsColumns.BARCODE]: 155,
  [MyProductsColumns.EPD_FILE]: 155,
  [MyProductsColumns.EXTERNAL_EPD_URL]: 155,
  [MyProductsColumns.RRP_PRICE]: 120,
  [MyProductsColumns.CURRENCY]: 96,
  [MyProductsColumns.QUANTITY]: 70,
  [MyProductsColumns.UOM]: 70,
  [MyProductsColumns.CREATED_AT]: 120,
  [MyProductsColumns.CREATED_BY]: 155,
  [MyProductsColumns.MODIFIED_AT]: 120,
  [MyProductsColumns.MODIFIED_BY]: 155,
  [MyProductsColumns.ACTIONS]: 60,
};

export const myProductsColumnFixed: Partial<
  Record<MyProductsColumns, 'left' | 'right'>
> = {
  [MyProductsColumns.NAME]: 'left',
  [MyProductsColumns.ACTIONS]: 'right',
};

export const myProductsColumnSortOrder: Partial<
  Record<MyProductsColumns, SortOrder>
> = {
  [MyProductsColumns.CREATED_AT]: SortOrder.DESC,
};

type TGetMyProductsColumnRenderParams = {
  search: string;
  openEdit: (record: TMappedMyProductListRecord) => void;
  modalDispatch: React.Dispatch<TModalContextStateAction>;
  currencies: DefaultOptionType[];
  uoms: DefaultOptionType[];
};

export const getMyProductsColumnRender = ({
  search,
  currencies,
  uoms,
  openEdit,
  modalDispatch,
}: TGetMyProductsColumnRenderParams) => ({
  [MyProductsColumns.NAME]: (
    value: IMappedMyProducts[MyProductsColumns.NAME]
  ) => {
    return (
      <Cell isValue={!!value}>
        <Highlighter value={value} search={search} />
      </Cell>
    );
  },
  [MyProductsColumns.MATERIAL_NUMBER]: (
    value: IMappedMyProducts[MyProductsColumns.MATERIAL_NUMBER]
  ) => {
    return (
      <Cell isValue={!!value}>
        <Highlighter value={value} search={search} />
      </Cell>
    );
  },
  [MyProductsColumns.PART_NUMBER]: (
    value: IMappedMyProducts[MyProductsColumns.PART_NUMBER]
  ) => {
    return (
      <Cell isValue={!!value}>
        <Highlighter value={value} search={search} />
      </Cell>
    );
  },
  [MyProductsColumns.BARCODE]: (
    value: IMappedMyProducts[MyProductsColumns.BARCODE]
  ) => {
    return (
      <Cell isValue={!!value}>
        <Highlighter value={value} search={search} />
      </Cell>
    );
  },
  [MyProductsColumns.EPD_FILE]: (
    value: IMappedMyProducts[MyProductsColumns.EPD_FILE]
  ) => {
    return (
      <Cell isValue={!!value?.fileName}>
        <File file={value} />
      </Cell>
    );
  },
  [MyProductsColumns.EXTERNAL_EPD_URL]: (
    value: IMappedMyProducts[MyProductsColumns.EXTERNAL_EPD_URL]
  ) => {
    return (
      <Cell isValue={!!value}>
        <Url value={value || ''} />
      </Cell>
    );
  },
  [MyProductsColumns.RRP_PRICE]: (
    value: IMappedMyProducts[MyProductsColumns.RRP_PRICE]
  ) => {
    return <Cell isValue={!!value}>{formatNumber(value)}</Cell>;
  },
  [MyProductsColumns.CURRENCY]: (
    value: IMappedMyProducts[MyProductsColumns.CURRENCY]
  ) => {
    return (
      <Cell isValue={!!value}>
        {currencies.find((el) => el.value === value)?.label}
      </Cell>
    );
  },
  [MyProductsColumns.QUANTITY]: (
    value: IMappedMyProducts[MyProductsColumns.QUANTITY]
  ) => {
    return <Cell isValue={!!value}>{formatNumber(value)}</Cell>;
  },
  [MyProductsColumns.UOM]: (
    value: IMappedMyProducts[MyProductsColumns.UOM]
  ) => {
    return (
      <Cell isValue={!!value}>
        {uoms.find((el) => el.value === value)?.label}
      </Cell>
    );
  },
  [MyProductsColumns.CREATED_AT]: (
    value: IMappedMyProducts[MyProductsColumns.CREATED_AT]
  ) => {
    return <Cell isValue={!!value}>{formatDate({ date: value })}</Cell>;
  },
  [MyProductsColumns.CREATED_BY]: (
    value: IMappedMyProducts[MyProductsColumns.CREATED_BY]
  ) => {
    return <Cell isValue={!!value}>{value}</Cell>;
  },
  [MyProductsColumns.MODIFIED_AT]: (
    value: IMappedMyProducts[MyProductsColumns.MODIFIED_AT]
  ) => {
    return <Cell isValue={!!value}>{formatDate({ date: value })}</Cell>;
  },
  [MyProductsColumns.MODIFIED_BY]: (
    value: IMappedMyProducts[MyProductsColumns.MODIFIED_BY]
  ) => {
    return <Cell isValue={!!value}>{value}</Cell>;
  },
  [MyProductsColumns.ACTIONS]: (
    value: IMappedMyProducts[MyProductsColumns.ACTIONS],
    record: TMappedMyProductListRecord
  ) => {
    return (
      <div className={styles.component}>
        {value.canEdit && (
          <Tooltip title="Edit">
            <Button
              size="small"
              type="text"
              onClick={(e) => {
                e.stopPropagation();
                openEdit(record);
              }}
              icon={<IconEdit color={styles.iconFill} height="16" width="16" />}
            />
          </Tooltip>
        )}
        {value.canDelete && (
          <Tooltip title="Archive">
            <Button
              size="small"
              type="text"
              onClick={(e) => {
                e.stopPropagation();
                modalDispatch({
                  type: ModalContextActions.SHOW_MODAL,
                  value: {
                    title: 'Archive product',
                    description: `Product ”${record.name}” will be removed from the list.`,
                    actionText: 'Archive',
                    action: ActionType.ARCHIVE,
                    dataId: record.key,
                    dataName: record.name || '-',
                  },
                });
              }}
              icon={
                <IconArchive color={styles.iconFill} height="16" width="16" />
              }
            />
          </Tooltip>
        )}
      </div>
    );
  },
});
