import { Button, DatePicker, Empty, Form, InputNumber, Tooltip } from 'antd';
import clsx from 'clsx';
import FormItem from 'src/components/form-item/FormItem';
import { IconAdd } from 'src/components/icons/add/IconAdd';
import { IconAwesome } from 'src/components/icons/awesome/IconAwesome';
import { IconDate } from 'src/components/icons/date/IconDate';
import { IconDelete } from 'src/components/icons/delete/IconDelete';
import Loader from 'src/components/loader/Loader';
import Select from 'src/components/select/Select';
import {
  DUPLICATE_CUSTOMER,
  DUPLICATE_PRODUCT,
  NO_CUSTOMERS_ADDED,
  NO_PRODUCTS_ADDED,
  NO_RESULTS,
  REQUIRED,
} from 'src/constants/validation';
import useAssignModalForm from 'src/hooks/use-assign-modal-form';
import { formatNumber } from 'src/utils/number-formatter';
import {
  getCustomerSelectOptions,
  getProductSelectOptions,
} from 'src/utils/option-renderer';
import styles from './AssignForm.module.scss';

type TAssignFormProps = {
  close: () => void;
  productId?: string;
  customerId?: string;
  isCustomer?: boolean;
};

const AssignForm = ({
  close,
  productId,
  customerId,
  isCustomer = true,
}: TAssignFormProps) => {
  const {
    form,
    initialValue,
    scrollRef,
    options,
    listData,
    uniqueValidator,
    optionLoading,
    focusedIndex,
    setSearch,
    customerData,
    productData,
    setFocused,
    setFocusedIndex,
    focused,
    currencies,
    uoms,
    onMagicButton,
    onSelect,
    loading,
    submit,
    initialLoading,
    setCustomerData,
    setProductData,
    search,
    removeRow,
  } = useAssignModalForm({ productId, close, customerId, isCustomer });

  return (
    <>
      <Form form={form} layout="vertical">
        <Form.List name="data">
          {(fields, { add, remove }) => (
            <div className={styles.container}>
              <div className={styles.form} ref={scrollRef}>
                {fields.map((field, i) => {
                  const selectedOptionIndex = options[field.name]?.findIndex(
                    (el) => el.value === listData?.[i].object
                  );
                  let newOptions = options[field.name];

                  if (selectedOptionIndex + 1) {
                    const option = options[field.name][selectedOptionIndex];
                    const filteredOptions = options[field.name].filter(
                      (_, i) => i !== selectedOptionIndex
                    );
                    newOptions = [option, ...filteredOptions];
                  }

                  const searchValue = search[field.name];
                  const emptyDataMessage = isCustomer
                    ? NO_CUSTOMERS_ADDED
                    : NO_PRODUCTS_ADDED;

                  return (
                    <div className={styles.row} key={field.key}>
                      <FormItem
                        className={clsx(styles.noMargin, styles.object)}
                        name={[field.name, 'object']}
                        rules={[
                          { required: true, message: REQUIRED },
                          {
                            validator: uniqueValidator(i),
                            message: isCustomer
                              ? DUPLICATE_CUSTOMER
                              : DUPLICATE_PRODUCT,
                          },
                        ]}
                        label={isCustomer ? 'Customer' : 'Product'}
                      >
                        <Select
                          loading={optionLoading && i === focusedIndex}
                          showSearch
                          options={newOptions}
                          searchValue={searchValue}
                          notFoundContent={
                            <Empty
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                              description={
                                searchValue ? NO_RESULTS : emptyDataMessage
                              }
                            />
                          }
                          onSearch={(search) =>
                            setSearch((searchList) => {
                              const newSearch = [...searchList];
                              newSearch[i] = search;
                              return newSearch;
                            })
                          }
                          optionRender={(option) => {
                            if (isCustomer) {
                              return getCustomerSelectOptions(
                                customerData[field.name],
                                option.value
                              );
                            }
                            return getProductSelectOptions(
                              productData[field.name],
                              option.value
                            );
                          }}
                          onSelect={(value) => onSelect(value, i)}
                          filterOption={false}
                          placeholder={
                            focused && focusedIndex === i
                              ? 'Type to search'
                              : 'Select value'
                          }
                          onFocus={() => {
                            setFocused(true);
                            setFocusedIndex(i);
                          }}
                          onBlur={() => {
                            setFocused(false);
                            setFocusedIndex(null);
                          }}
                        />
                      </FormItem>
                      <FormItem
                        className={styles.noMargin}
                        name={[field.name, 'listPrice']}
                        label="List price"
                      >
                        <InputNumber<number>
                          className={styles.price}
                          addonAfter={
                            <FormItem
                              className={styles.noMargin}
                              name={[field.name, 'currency']}
                            >
                              <Select
                                options={currencies}
                                className={styles.currencyAddon}
                              />
                            </FormItem>
                          }
                          min={0.01}
                          controls={false}
                          formatter={(value) => {
                            if (!value && value !== 0) {
                              return '';
                            }
                            if (value <= 0) {
                              return formatNumber(0.01);
                            }
                            return formatNumber(value);
                          }}
                        />
                      </FormItem>
                      <FormItem
                        name={[field.name, 'quantity']}
                        className={styles.noMargin}
                        label="Assigned Qty, UoM"
                      >
                        <InputNumber<number>
                          className={styles.quantity}
                          addonAfter={
                            <FormItem
                              name={[field.name, 'uom']}
                              className={styles.noMargin}
                            >
                              <Select
                                options={uoms}
                                className={styles.uomAddon}
                              />
                            </FormItem>
                          }
                          min={0.01}
                          controls={false}
                          formatter={(value) => {
                            if (!value && value !== 0) {
                              return '';
                            }
                            if (value <= 0) {
                              return formatNumber(0.01);
                            }
                            return formatNumber(value);
                          }}
                        />
                      </FormItem>
                      <div className={styles.relative}>
                        <FormItem
                          name={[field.name, 'priceEffective']}
                          className={styles.noMargin}
                          label="Price effective"
                        >
                          <DatePicker
                            onFocus={() => {
                              setFocusedIndex(i);
                            }}
                            format={'DD/MM/YYYY'}
                            className={styles.date}
                            suffixIcon={
                              <IconDate
                                width="16"
                                height="16"
                                color={styles.iconCalendar}
                              />
                            }
                          />
                        </FormItem>
                        {!i &&
                          !!listData?.[i]?.priceEffective &&
                          listData.length > 1 && (
                            <Tooltip
                              title={`Apply to all ${isCustomer ? 'customers' : 'products'}`}
                            >
                              <Button
                                className={styles.magic1}
                                onClick={() => onMagicButton()}
                                size="small"
                                type="text"
                                icon={<IconAwesome height="16" width="16" />}
                              />
                            </Tooltip>
                          )}
                      </div>
                      <div className={styles.relative}>
                        <FormItem
                          name={[field.name, 'priceExpiration']}
                          className={styles.noMargin}
                          label="Price expiration"
                        >
                          <DatePicker
                            onFocus={() => {
                              setFocusedIndex(i);
                            }}
                            format={'DD/MM/YYYY'}
                            className={styles.date}
                            suffixIcon={
                              <IconDate
                                width="16"
                                height="16"
                                color={styles.iconCalendar}
                              />
                            }
                          />
                        </FormItem>
                        {!i &&
                          !!listData?.[i]?.priceExpiration &&
                          listData.length > 1 && (
                            <Tooltip
                              title={`Apply to all ${isCustomer ? 'customers' : 'products'}`}
                            >
                              <Button
                                className={styles.magic2}
                                onClick={() => onMagicButton(true)}
                                size="small"
                                type="text"
                                icon={<IconAwesome height="16" width="16" />}
                              />
                            </Tooltip>
                          )}
                      </div>
                      {fields.length > 1 && (
                        <Button
                          type="text"
                          icon={<IconDelete height="16" width="16" />}
                          className={styles.delete}
                          onClick={(e) => {
                            e.stopPropagation();
                            remove(field.name);
                            removeRow(field.name);
                          }}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
              <Button
                disabled={fields.length >= 10}
                className={styles.add}
                icon={<IconAdd width="16" height="16" />}
                type="dashed"
                onClick={() => {
                  if (fields.length < 10) {
                    if (isCustomer) {
                      setCustomerData((data) => {
                        data[fields.length] = [];
                        return [...data];
                      });
                    } else {
                      setProductData((data) => {
                        data[fields.length] = [];
                        return [...data];
                      });
                    }

                    add({ ...initialValue });
                    // Note: timeout used to make sure it will be after add() in the event loop
                    setTimeout(() => {
                      scrollRef.current?.scrollTo({
                        top: scrollRef.current?.clientHeight + 100,
                      });
                    }, 0);
                  }
                }}
              >
                Add {isCustomer ? 'Customer' : 'Product'}
              </Button>
            </div>
          )}
        </Form.List>
        <div className={styles.buttons}>
          <Button disabled={loading} onClick={close}>
            Cancel
          </Button>
          <Button loading={loading} type="primary" onClick={submit}>
            Assign
          </Button>
        </div>
      </Form>
      <Loader visible={initialLoading} text="Loading" />
    </>
  );
};

export default AssignForm;
