import { TDashboardCardProps } from 'src/components/dashboard/card/DashboardCard';
import { IconAcute } from 'src/components/icons/acute/IconAcute';
import { IconArchive } from 'src/components/icons/archive/IconArchive';
import { IconAttachment } from 'src/components/icons/attachment/IconAttachment';
import { IconDescription } from 'src/components/icons/description/IconDescription';
import { IconHandshake } from 'src/components/icons/handshake/IconHandshake';
import { IconHub } from 'src/components/icons/hub/IconHub';
import { IconInventory } from 'src/components/icons/inventory/IconInventory';
import { IconTrendingUp } from 'src/components/icons/trending-up/IconTrendingUp';
import { IDashboardMetrics } from 'src/types/general';
import { ProtectedRoutes } from 'src/constants/routes';
import { formatNumber } from './number-formatter';
import { formatDate } from './date-formatter';

export const MetricsGroups = [
  {
    group: 0,
    title: 'My Products',
  },
  {
    group: 1,
    title: 'My Suppliers’ products assigned to me',
  },
  {
    group: 2,
    title: 'My Suppliers',
  },
  {
    group: 3,
    title: 'My Customers',
  },
  {
    group: 4,
    title: 'My favorite insights',
  },
];

export type TMappedMetric = TDashboardCardProps & {
  group: number;
  order: number;
};

export const getMetricMeta = (metrics: IDashboardMetrics) => {
  const mappedMetrics = Object.keys(metrics).map((metric): TMappedMetric => {
    switch (metric) {
      case 'suppliersStatus':
        return {
          order: 0,
          title: {
            title: 'My Suppliers',
            icon: <IconHub height="16" width="16" />,
            amount: formatNumber(metrics.suppliersStatus.total, 0),
            url: `${ProtectedRoutes.SUPPLIERS}`,
          },
          group: 2,
          details: [
            {
              title: 'Coupled:',
              amount: formatNumber(metrics.suppliersStatus.coupled, 0),
            },
            {
              title: 'Pending:',
              amount: formatNumber(metrics.suppliersStatus.pending, 0),
              secondary: true,
            },
          ],
        };
      case 'customersStatus':
        return {
          order: 0,
          title: {
            title: 'My Customers',
            icon: <IconHandshake height="16" width="16" />,
            amount: formatNumber(metrics.customersStatus.total, 0),
            url: `${ProtectedRoutes.CUSTOMERS}`,
          },
          group: 3,
          details: [
            {
              title: 'Coupled:',
              amount: formatNumber(metrics.customersStatus.coupled, 0),
            },
            {
              title: 'Decoupled:',
              amount: formatNumber(metrics.customersStatus.decoupled, 0),
              warning: true,
            },
          ],
        };
      case 'tenantProductsStatus':
        return {
          order: 0,
          title: {
            title: 'My Products',
            icon: <IconInventory height="16" width="16" />,
            amount: formatNumber(metrics.tenantProductsStatus.total, 0),
            url: `${ProtectedRoutes.PRODUCTS}`,
          },
          group: 0,
          details: [
            {
              title: 'Active:',
              amount: formatNumber(metrics.tenantProductsStatus.active, 0),
            },
            {
              title: 'Data quality issues:',
              amount: formatNumber(
                metrics.tenantProductsStatus.dataQualityIssues,
                0
              ),
              danger: true,
            },
          ],
        };
      case 'epdFiles':
        return {
          order: 1,
          title: {
            title: 'EPD files',
            icon: <IconAttachment height="16" width="16" />,
            amount: formatNumber(metrics.epdFiles.epdTotal, 0),
            url: `${ProtectedRoutes.PRODUCTS}`,
          },
          group: 0,
          details: [
            {
              title: 'Products with EPD:',
              amount: formatNumber(metrics.epdFiles.productsWithEpd, 0),
            },
            {
              title: 'Products without EPD:',
              amount: formatNumber(metrics.epdFiles.productsWithoutEpd, 0),
              danger: true,
            },
          ],
        };
      case 'tenantProductsPriceExpiration':
        return {
          order: 2,
          title: {
            title: 'Price expiration',
            icon: <IconAcute height="16" width="16" />,
          },
          group: 0,
          details: [
            {
              title: 'Price effective:',
              amount: formatNumber(
                metrics.tenantProductsPriceExpiration.nonExpired,
                0
              ),
            },
            {
              title: 'Price expires within 7d:',
              amount: formatNumber(
                metrics.tenantProductsPriceExpiration.expiringIn7Days,
                0
              ),
            },
            {
              title: 'Price expired:',
              amount: formatNumber(
                metrics.tenantProductsPriceExpiration.expired,
                0
              ),
              danger: true,
            },
          ],
        };
      case 'tenantTopSellingProducts':
        return {
          order: 3,
          mode: 'list',
          emptyListMessage: 'No selling history',
          title: {
            title: 'Top selling products',
            icon: <IconTrendingUp height="16" width="16" />,
          },
          group: 0,
          details: metrics.tenantTopSellingProducts.map((stat) => ({
            title: stat.productName,
            amount: formatNumber(stat.sellingCount, 0),
            url: `${ProtectedRoutes.PRODUCTS}/${stat.productId}`,
          })),
        };
      case 'tenantProductsArchived':
        return {
          order: 4,
          mode: 'info',
          title: null,
          group: 0,
          details: [
            {
              title: 'Archived Products',
              amount: formatNumber(metrics.tenantProductsArchived.archived, 0),
              icon: <IconArchive />,
            },
          ],
        };
      case 'supplierProductsStatus':
        return {
          order: 0,
          title: {
            title: 'Assigned Products',
            icon: <IconInventory height="16" width="16" />,
            amount: formatNumber(metrics.supplierProductsStatus.total, 0),
            url: `${ProtectedRoutes.PRODUCTS}?supplierList=true`,
          },
          group: 1,
          details: [
            {
              title: 'Active:',
              amount: formatNumber(metrics.supplierProductsStatus.active, 0),
            },
            {
              title: 'Archived:',
              amount: formatNumber(metrics.supplierProductsStatus.archived, 0),
              danger: true,
            },
          ],
        };
      case 'supplierProductsPriceExpiration':
        return {
          order: 1,
          title: {
            title: 'Price expiration',
            icon: <IconAcute height="16" width="16" />,
          },
          group: 1,
          details: [
            {
              title: 'Price effective:',
              amount: formatNumber(
                metrics.supplierProductsPriceExpiration.nonExpired,
                0
              ),
            },
            {
              title: 'Price expires within 7d:',
              amount: formatNumber(
                metrics.supplierProductsPriceExpiration.expiringIn7Days,
                0
              ),
            },
            {
              title: 'Price expired:',
              amount: formatNumber(
                metrics.supplierProductsPriceExpiration.expired,
                0
              ),
              danger: true,
            },
          ],
        };
      case 'supplierTopPurchasedProducts':
        return {
          order: 2,
          mode: 'list',
          emptyListMessage: 'No purchase history',
          title: {
            title: 'Top purchased products',
            icon: <IconTrendingUp height="16" width="16" />,
          },
          group: 1,
          details: metrics.supplierTopPurchasedProducts.map((stat) => ({
            title: stat.productName,
            amount: formatNumber(stat.purchasedCount, 0),
            url: `${ProtectedRoutes.SUPPLIERS}/${stat.supplierCompanyId}/${stat.productId}`,
          })),
        };
      case 'favouriteReports':
        return {
          order: 0,
          mode: 'list',
          emptyListMessage: 'No reports yet',
          title: {
            title: 'My favorite reports',
            icon: <IconDescription height="16" width="16" />,
          },
          group: 4,
          details: metrics.favouriteReports.map((stat) => ({
            title: stat.name,
            amount: formatDate({ date: stat.lastCreated }),
            url: `${ProtectedRoutes.INSIGHTS}`,
            secondary: true,
          })),
        };

      default:
        return {
          order: 0,
          title: null,
          group: 999,
          details: [],
        };
    }
  });
  return mappedMetrics;
};
