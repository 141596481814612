import { useEffect, useMemo, useState } from 'react';
import { INSIGHTS_NOTIFICATION } from 'src/constants/local-storage';

const FLAG = 'disabled';

const useInsightsNotification = () => {
  const isDisabled = useMemo(
    () => localStorage.getItem(INSIGHTS_NOTIFICATION) === FLAG,
    []
  );
  const [alert, setAlert] = useState(true);
  const [checked, setChecked] = useState(false);

  const alertHeight = alert && !isDisabled ? 58 : 0;

  useEffect(() => {
    if (checked && !alert) {
      localStorage.setItem(INSIGHTS_NOTIFICATION, FLAG);
    }
  }, [alert, checked]);

  return { isDisabled, setAlert, checked, setChecked, alertHeight };
};

export default useInsightsNotification;
