import dayjs from 'dayjs';
import { SortOrder } from 'src/components/icons/sort/IconSort';
import { HTTP, useNetworkContext } from 'src/contexts/network-context';
import {
  TGetProjectsParams,
  TGetProjectsResponse,
  TGetSustainabilityReportParams,
  TGetSustainabilityReportResponse,
} from 'src/types/backend';
import { DEFAULT_DATE_FORMAT } from 'src/utils/date-formatter';
import { downloadFile } from 'src/utils/download';
import { getSustainabilityReportParams } from 'src/utils/get-sustainability-report-params';
import useMessage from './use-message';
import { EXPORT_FAILED } from 'src/constants/validation';

const useReports = () => {
  const { fetch } = useNetworkContext();
  const { showErrorMessage } = useMessage();

  const getSustainabilityReport = async (
    data: TGetSustainabilityReportParams
  ) => {
    const params = getSustainabilityReportParams(data);
    const result = await fetch<TGetSustainabilityReportResponse, unknown>({
      url: '/report/sustainability/paged',
      method: HTTP.GET,
      params,
    });

    return result;
  };

  const getProjects = async (data: TGetProjectsParams) => {
    const params = new URLSearchParams();
    params.append('Page', `${data.pagination?.current}`);
    params.append('PageSize', `${data.pagination?.pageSize}`);
    params.append('Search', data.search);
    if (data.sortField) {
      params.append('SortBy', `${data.sortField}`);
      params.append('Descending', `${data.sortOrder === SortOrder.DESC}`);
    }

    const result = await fetch<TGetProjectsResponse, unknown>({
      url: '/report/projects/paged',
      method: HTTP.GET,
      params,
    });

    return result;
  };

  const exportReport = async (
    data: TGetSustainabilityReportParams,
    settings: {
      key: string;
      value: string;
    }[]
  ) => {
    const params = getSustainabilityReportParams(data);
    fetch<Blob, unknown>({
      url: `/report/sustainability/export`,
      responseType: 'blob',
      method: HTTP.POST,
      data: settings,
      params,
    })
      .then((response) => {
        const company = response.result?.headers['company-name'];

        const date = dayjs();
        downloadFile(
          response,
          `${company}_Sustainability Report_${date.format(DEFAULT_DATE_FORMAT)}_${date.format('hh:mm:ss A')}`
        );
      })
      .catch(() => {
        showErrorMessage(EXPORT_FAILED);
      });
  };

  return { getSustainabilityReport, getProjects, exportReport };
};

export default useReports;
