import { METHODS } from 'http';
import { HTTP, useNetworkContext } from 'src/contexts/network-context';
import { TUploadInvoiceResponse } from 'src/types/backend';
import { IFile } from 'src/types/general';
import { downloadFile } from 'src/utils/download';

const useFiles = () => {
  const { fetch } = useNetworkContext();

  const abortController = new AbortController();

  const download = (
    file: Partial<IFile> | null | undefined,
    isInvoice: boolean
  ) => {
    if (!file?.blobName) {
      return;
    }
    fetch<Blob, unknown>({
      url: `/${isInvoice ? 'invoice' : 'epd'}/download/${file.blobName}`,
      responseType: 'blob',
    }).then((response) => {
      downloadFile(response, file.fileName || '');
    });
  };

  const uploadInvoice = async (
    data: FormData,
    setProgress: (value: number) => void
  ) => {
    const result = await fetch<TUploadInvoiceResponse, unknown>({
      url: '/invoice/upload',
      data,
      method: HTTP.POST,
      signal: abortController.signal,
      onUploadProgress: (progress) => {
        if (progress.progress) {
          setProgress(progress.progress * 100);
        }
      },
    });

    return result;
  };

  const deleteInvoice = async (invoiceId: string) => {
    const result = await fetch({
      url: `/invoice/${invoiceId}`,
      method: HTTP.DELETE,
    });

    return result;
  };

  const abortUploadInvoice = () => {
    abortController.abort();
  };

  const processInvoice = async (data: string[]) => {
    const result = await fetch({
      url: '/invoice/process',
      method: HTTP.PUT,
      data,
    });

    return result;
  };

  return {
    download,
    uploadInvoice,
    abortUploadInvoice,
    deleteInvoice,
    processInvoice,
  };
};

export default useFiles;
