import { DefaultOptionType } from 'antd/es/select';
import {
  PropsWithChildren,
  createContext,
  useContext,
  type FunctionComponent,
} from 'react';
import useMeasurements from 'src/hooks/use-measurements';
import { TGetIndicatorsResponse } from 'src/types/backend';
import { IIndicator } from 'src/types/general';
import { TResponse } from './network-context';

export type TMeasasurementsContextValue = {
  loading: boolean;
  getAllMeasurements: () => Promise<void>;
  getIndicators: () => Promise<TResponse<TGetIndicatorsResponse, unknown>>;
  uoms: DefaultOptionType[];
  currencies: DefaultOptionType[];
  indicators: IIndicator[];
};
const measurementsContexInitialValue: TMeasasurementsContextValue = {
  getAllMeasurements: async () => {},
  getIndicators: async () => {
    return {
      result: null,
      errors: null,
    };
  },
  loading: false,
  uoms: [],
  currencies: [],
  indicators: [],
};

const MeasurementsContext = createContext<TMeasasurementsContextValue>(
  measurementsContexInitialValue
);

type MeasurementsContextProviderProps = PropsWithChildren;

export const MeasurementsContextProvider: FunctionComponent<
  MeasurementsContextProviderProps
> = ({ children }): JSX.Element => {
  const {
    loading,
    getAllMeasurements,
    getIndicators,
    uoms,
    currencies,
    indicators,
  } = useMeasurements();

  return (
    <MeasurementsContext.Provider
      value={{
        loading,
        getAllMeasurements,
        getIndicators,
        uoms,
        currencies,
        indicators,
      }}
    >
      {children}
    </MeasurementsContext.Provider>
  );
};

export const useMeasurementsContext = () => {
  const context = useContext(MeasurementsContext);

  if (!context) {
    throw new Error(
      '"MeasurementsContext" context is not in scope. Use this hook in a component that is wrapped with the <MeasurementsContextProvider /> component.'
    );
  }

  return context;
};
