import { ColumnType } from 'antd/es/table';
import { useMemo } from 'react';

const useTableWidth = <T>(columns: ColumnType<T>[]) => {
  return useMemo(
    () => columns.reduce((acc, col) => (acc += Number(col.width || 0)), 0),
    [columns]
  );
};

export default useTableWidth;
