import { Progress } from 'antd';
import { RcFile } from 'antd/es/upload';
import { useEffect, useMemo, useState } from 'react';
import useFiles from 'src/hooks/use-files';
import File from '../File';
import styles from './AutoUploadFile.module.scss';

type TAutoUploadFileProps = {
  file: RcFile;
  supplierId: string | null;
  customerId: string | null;
  onDelete: () => void;
  onUpload: (value: string) => void;
  disabled: boolean;
  setDisabled: (value: boolean) => void;
  abortTrigger: boolean;
  onFailed: (value: boolean) => void;
  onLoading: (value: boolean) => void;
  withLimit?: number;
};

const FILE_LIMIT = 3145728; // Note: 3MB

const AutoUploadFile = ({
  file,
  supplierId,
  customerId,
  onDelete,
  disabled,
  setDisabled,
  abortTrigger,
  onUpload,
  onFailed,
  onLoading,
  withLimit = FILE_LIMIT,
}: TAutoUploadFileProps) => {
  const { uploadInvoice, abortUploadInvoice, deleteInvoice } = useFiles();
  const [invoiceId, setInvoiceId] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);

  const formData = useMemo(() => {
    const body = new FormData();
    body.append('File', file);
    if (supplierId) {
      body.append('SupplierId', supplierId);
    }
    if (customerId) {
      body.append('CustomerId', customerId);
    }

    return body;
  }, [customerId, supplierId, file]);

  useEffect(() => {
    uploadFile();
    return () => {
      abortUploadInvoice();
    };
  }, []);

  useEffect(() => {
    if (abortTrigger) {
      onInvoiceDelete();
    }
  }, [abortTrigger]);

  const setGeneralError = (value: boolean) => {
    setError(value);
    onFailed(value);
  };

  const setGeneralLoading = (value: boolean) => {
    setLoading(value);
    onLoading(value);
  };

  const uploadFile = async () => {
    if (!file) {
      return;
    }
    if (file.size > withLimit) {
      setGeneralError(true);
      return;
    }
    setGeneralError(false);
    setGeneralLoading(true);

    const res = await uploadInvoice(formData, setProgress);

    if (res.result?.data) {
      const id = res.result.data.id;
      setInvoiceId(id);
      onUpload(id);
    }

    if (res.errors && res.errors.code !== 'ERR_CANCELED') {
      setGeneralError(true);
    }

    setGeneralLoading(false);
  };

  const onInvoiceDelete = async () => {
    if (loading) {
      abortUploadInvoice();
    } else if (invoiceId) {
      setDisabled(true);
      await deleteInvoice(invoiceId);
      setDisabled(false);
    }
  };

  return (
    <div className={styles.component}>
      <File
        file={{
          fileName: file.name || '',
          blobName: file.name || '',
          createdAt: new Date(),
        }}
        isError={error}
        disabled={disabled}
        retry={uploadFile}
        onDelete={async () => {
          await onInvoiceDelete();
          onDelete();
        }}
      />

      {loading && (
        <Progress
          className={styles.progress}
          percent={progress}
          showInfo={false}
        />
      )}
    </div>
  );
};

export default AutoUploadFile;
