import clsx from 'clsx';
import { Hexagon, THexagonData } from 'src/components/hexagon/Hexagon';
import { CustomerLogo } from '../logo/CustomerLogo';
import { HivesLogo } from '../logo/HivesLogo';
import { SupplierLogo } from '../logo/SupplierLogo';
import styles from './HiveContainer.module.scss';

export type THiveItem = {
  type:
    | 'blank'
    | 'supplier-logo'
    | 'customer-logo'
    | 'hive-logo'
    | 'status'
    | 'amount';
  data?: THexagonData;
};

const MARGIN = 34;
const HEX_WIDTH = 128;

export type THiveColumn = { items: THiveItem[] };

type THiveContainerProps = {
  columns: THiveColumn[];
  className?: string;
  navigate?: () => void;
};

const HiveContainer = ({
  columns,
  className,
  navigate,
}: THiveContainerProps) => {
  const containerWidth = (HEX_WIDTH - MARGIN) * columns.length + MARGIN;

  return (
    <div
      className={clsx(styles.container, className)}
      style={{
        gridTemplateColumns: `repeat(${columns.length}, ${HEX_WIDTH}px)`,
        maxWidth: containerWidth,
      }}
    >
      {columns.map(({ items }, i) => (
        <div
          className={clsx(styles.column, styles[`column${i}`])}
          key={`column-${i}`}
        >
          {items.map((item, j) => {
            const key = `column-${i}${j}`;
            switch (item.type) {
              case 'blank':
                return <div key={key} className={styles.blank} />;
              case 'supplier-logo':
                return <SupplierLogo key={key} />;
              case 'customer-logo':
                return <CustomerLogo key={key} />;
              case 'hive-logo':
                return <HivesLogo key={key} />;
              case 'status':
                if (!item.data) {
                  return <div key={key} className={styles.blank} />;
                }
                return (
                  <Hexagon
                    key={key}
                    title={item.data.title}
                    status={item.data.status}
                    stroke={styles.stroke}
                  />
                );
              case 'amount':
                if (!item.data) {
                  return <div key={key} className={styles.blank} />;
                }
                return (
                  <Hexagon
                    key={key}
                    title={item.data.title}
                    exceptionCount={item.data.exceptionCount}
                    value={item.data.value}
                    type={item.data.type}
                    icon={item.data.icon}
                    navigate={navigate}
                  />
                );

              default:
                return <div key={key} className={styles.blank} />;
            }
          })}
        </div>
      ))}
    </div>
  );
};

export default HiveContainer;
