import { DefaultOptionType } from 'antd/es/select';
import { ColumnsType } from 'antd/es/table';
import { IconSort, SortOrder } from 'src/components/icons/sort/IconSort';
import {
  PurchaseHistoryColumns,
  defaultPurchaseHistoryColumns,
  getPurchaseHistoryColumnRender,
  purchaseHistoryColumnAlign,
  purchaseHistoryColumnFixed,
  purchaseHistoryColumnLabel,
  purchaseHistoryColumnSortOrder,
  purchaseHistoryColumnWidth,
} from 'src/constants/purchase-history';
import { IPurchaseHistory } from 'src/types/general';
import { ArrayElement } from 'src/types/generic';
import { IMappedPurchaseHistory } from 'src/types/mappings';

export type TMappedPurchaseHistoryRecord = IMappedPurchaseHistory & {
  key: string;
};

type TGetAssignedProductColumnsParams = {
  currencies: DefaultOptionType[];
  uoms: DefaultOptionType[];
};

export const getMappedPurchaseHistoryData = (
  data: IPurchaseHistory[]
): TMappedPurchaseHistoryRecord[] => {
  return data.map((row) => {
    const res: TMappedPurchaseHistoryRecord = {
      key: row.id,
      [PurchaseHistoryColumns.ORDER]: row.invoice?.purchaseOrderNumber || null,
      [PurchaseHistoryColumns.INVOICE]: row.invoice,
      [PurchaseHistoryColumns.INVOICE_DATE]: row.invoice?.invoiceDate || null,
      [PurchaseHistoryColumns.QUANTITY]: row.quantity,
      [PurchaseHistoryColumns.UOM]: row.unitOfMeasurementId,
      [PurchaseHistoryColumns.TOTAL_PRICE]: row.totalAmount,
      [PurchaseHistoryColumns.TOTAL_CURRENCY]: row.currency,
      [PurchaseHistoryColumns.PRICE]: row.productOffer?.price || null,
      [PurchaseHistoryColumns.CURRENCY]: row.productOffer?.currency || 0,
      [PurchaseHistoryColumns.PROJECT_ID]: row.project?.personalId || null,
      [PurchaseHistoryColumns.PROJECT_NAME]: row.project?.name || null,
    };
    return res;
  });
};

export const getPurchaseHistoryColumnSetting = function ({
  currencies,
  uoms,
}: TGetAssignedProductColumnsParams) {
  const render = getPurchaseHistoryColumnRender({
    currencies,
    uoms,
  });
  const result = defaultPurchaseHistoryColumns.map(
    (property): ArrayElement<ColumnsType<TMappedPurchaseHistoryRecord>> => {
      switch (property) {
        case PurchaseHistoryColumns.ORDER:
        case PurchaseHistoryColumns.INVOICE:
        case PurchaseHistoryColumns.INVOICE_DATE:
        case PurchaseHistoryColumns.QUANTITY:
        case PurchaseHistoryColumns.UOM:
        case PurchaseHistoryColumns.TOTAL_PRICE:
        case PurchaseHistoryColumns.TOTAL_CURRENCY:
        case PurchaseHistoryColumns.PRICE:
        case PurchaseHistoryColumns.CURRENCY:
        case PurchaseHistoryColumns.PROJECT_ID:
        case PurchaseHistoryColumns.PROJECT_NAME:
          return {
            key: property,
            title: purchaseHistoryColumnLabel[property],
            width: purchaseHistoryColumnWidth[property],
            fixed: purchaseHistoryColumnFixed[property],
            align: purchaseHistoryColumnAlign[property],
            defaultSortOrder: purchaseHistoryColumnSortOrder[property],
            ellipsis: true,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: render[property],
          };
        default:
          return { hidden: true };
      }
    }
  );
  return result;
};
