import Highlighter from '../highlighter/Highlighter';
import styles from './Email.module.scss';

type TEmailProps = {
  search?: string;
  children: string;
};

const Email = ({ children, search = '' }: TEmailProps) => {
  return (
    <Highlighter
      className={styles.component}
      value={children}
      search={search}
    />
  );
};

export default Email;
