import { Form } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { ProductOffersColumns } from 'src/constants/product-offers';
import { useMeasurementsContext } from 'src/contexts/measurements-context';
import { TMappedProductOfferRecord } from 'src/utils/map-assigned-product-table';

const useProductOfferTableForm = () => {
  const [form] = Form.useForm<TMappedProductOfferRecord>();
  const [data, setData] = useState<TMappedProductOfferRecord[]>([]);

  const { uoms, currencies } = useMeasurementsContext();

  const priceEffective = Form.useWatch<dayjs.Dayjs>(
    [ProductOffersColumns.START_DATE],
    form
  );
  const priceExpiration = Form.useWatch<dayjs.Dayjs>(
    [ProductOffersColumns.EXPIRATION_DATE],
    form
  );

  useEffect(() => {
    const priceEffectiveDate = priceEffective?.startOf('day').toDate();
    const priceExpirationDate = priceExpiration?.startOf('day').toDate();

    if (priceEffectiveDate >= priceExpirationDate) {
      form.setFieldValue(
        ProductOffersColumns.EXPIRATION_DATE,
        priceEffective.add(1, 'day')
      );
    }
  }, [priceEffective, priceExpiration]);

  const setEditRow = (index: number, record: TMappedProductOfferRecord) => {
    const initialValue: TMappedProductOfferRecord = {
      ...record,
      [ProductOffersColumns.CURRENCY]:
        record[ProductOffersColumns.CURRENCY] ||
        (currencies.find((el) => el.label === 'AUD')?.value as number),
      [ProductOffersColumns.UOM]:
        record[ProductOffersColumns.UOM] ||
        (uoms.find((el) => el.label === 'pcs')?.value as string),
      [ProductOffersColumns.QUANTITY]:
        record[ProductOffersColumns.QUANTITY] || null,
      [ProductOffersColumns.PRICE]: record[ProductOffersColumns.PRICE] || null,
      [ProductOffersColumns.START_DATE]: record[ProductOffersColumns.START_DATE]
        ? (dayjs(record[ProductOffersColumns.START_DATE]) as any)
        : null,
      [ProductOffersColumns.EXPIRATION_DATE]: record[
        ProductOffersColumns.EXPIRATION_DATE
      ]
        ? (dayjs(record[ProductOffersColumns.EXPIRATION_DATE]) as any)
        : null,
    };
    form.setFieldsValue(initialValue);

    setData((data) => {
      data[index].isEditing = true;
      return [...data];
    });
  };

  const isEditing = useMemo(() => data.some((el) => el.isEditing), [data]);

  const unsetEditRow = (index: number) => {
    form.resetFields(undefined);

    setData((data) => {
      data[index].isEditing = false;
      return [...data];
    });
  };

  return {
    form,
    data,
    setEditRow,
    unsetEditRow,
    isEditing,
    uoms,
    currencies,
    setData,
  };
};

export default useProductOfferTableForm;
