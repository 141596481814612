import clsx from 'clsx';
import type { FunctionComponent, ReactNode } from 'react';

import { Badge, Tooltip, Typography } from 'antd';
import { HiveStatus, statusIcon } from 'src/constants/hive-map';
import { formatNumber } from 'src/utils/number-formatter';
import styles from './Hexagon.module.scss';

export type THexagonData = {
  icon?: ReactNode;
  status?: HiveStatus;
  title: string;
  value?: number;
  type?: 'danger' | 'disabled';
  exceptionCount?: number;
};

type THexagonProps = {
  width?: number;
  height?: number;
  className?: string;
  fill?: string;
  stroke?: string;
  navigate?: () => void;
} & THexagonData;

export const Hexagon: FunctionComponent<THexagonProps> = ({
  className,
  fill,
  stroke,
  icon,
  width = 128,
  height = 114,
  status,
  title,
  value,
  type,
  exceptionCount,
  navigate,
}): JSX.Element => {
  const withAction = !!navigate;
  const isDisabled = type === 'disabled';
  const isDanger = type === 'danger';

  return (
    <div
      style={{ width, height }}
      className={clsx(
        styles.component,
        {
          [styles.action]: withAction,
          [styles.disabled]: withAction && isDisabled,
        },
        className
      )}
      onClick={() => {
        if (withAction && !isDisabled) {
          navigate();
        }
      }}
      data-element="hex"
    >
      <Tooltip title="Exceptions">
        <Badge count={exceptionCount} className={styles.badge} />
      </Tooltip>
      <svg
        className={styles.svg}
        width={width}
        height={height}
        viewBox="0 0 128 114"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-element="hexagon"
      >
        <path
          d="M2.84369 60.1754C1.71877 58.2105 1.71877 55.7895 2.84369 53.8246L30.695 5.17542C31.8199 3.21046 33.8989 2 36.1487 2H91.8513C94.1011 2 96.1801 3.21046 97.305 5.17542L125.156 53.8246C126.281 55.7895 126.281 58.2105 125.156 60.1754L97.305 108.825C96.1801 110.79 94.1011 112 91.8513 112H36.1487C33.8989 112 31.8199 110.79 30.695 108.825L2.84369 60.1754Z"
          fill={fill || styles.bgColor}
          stroke={stroke || styles.borderColor}
          strokeWidth="4"
        />
      </svg>
      <div className={styles.container}>
        {status && <div className={styles.status}>{statusIcon[status]}</div>}
        <div className={styles.info}>
          <div className={styles.titleContainer}>
            {icon}
            <Typography.Text className={styles.title}>{title}</Typography.Text>
          </div>
          {value && (
            <Typography.Text
              className={clsx(styles.value, {
                [styles.disabled]: isDisabled,
                [styles.danger]: isDanger,
              })}
            >
              {formatNumber(value, 0)}
            </Typography.Text>
          )}
        </div>
      </div>
    </div>
  );
};
