import clsx from 'clsx';
import { type FunctionComponent, type SVGProps } from 'react';

import styles from './IconRestore.module.scss';
import { TIconProps } from 'src/types/icons';

type IconRestoreProps = Omit<TIconProps, 'iconId'> & SVGProps<SVGSVGElement>;

export const IconRestore: FunctionComponent<IconRestoreProps> = ({
  className,
  width = 24,
  height = 24,
  fill,
  ...props
}): JSX.Element => {
  return (
    <svg
      className={clsx(className, styles.component)}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-element="icon"
      {...props}
    >
      <path
        d="M9.7 10.75C9.98333 10.45 10.325 10.2083 10.725 10.025C11.125 9.84167 11.55 9.75 12 9.75C12.9 9.75 13.6667 10.0667 14.3 10.7C14.9333 11.3333 15.25 12.1 15.25 13C15.25 13.9 14.9333 14.6667 14.3 15.3C13.6667 15.9333 12.9 16.25 12 16.25C11.4 16.25 10.8542 16.1 10.3625 15.8C9.87083 15.5 9.48333 15.1083 9.2 14.625C9.1 14.4417 8.95417 14.3208 8.7625 14.2625C8.57083 14.2042 8.38333 14.2167 8.2 14.3C8 14.3833 7.8625 14.5292 7.7875 14.7375C7.7125 14.9458 7.73333 15.1417 7.85 15.325C8.25 16.0583 8.81667 16.6458 9.55 17.0875C10.2833 17.5292 11.1 17.75 12 17.75C13.3 17.75 14.4167 17.2833 15.35 16.35C16.2833 15.4167 16.75 14.3 16.75 13C16.75 11.7 16.2833 10.5833 15.35 9.65C14.4167 8.71667 13.3 8.25 12 8.25C11.3667 8.25 10.775 8.36667 10.225 8.6C9.675 8.83333 9.18333 9.15 8.75 9.55V8.75C8.75 8.53333 8.67917 8.35417 8.5375 8.2125C8.39583 8.07083 8.21667 8 8 8C7.78333 8 7.60417 8.07083 7.4625 8.2125C7.32083 8.35417 7.25 8.53333 7.25 8.75V11.5C7.25 11.7167 7.32083 11.8958 7.4625 12.0375C7.60417 12.1792 7.78333 12.25 8 12.25H10.75C10.9667 12.25 11.1458 12.1792 11.2875 12.0375C11.4292 11.8958 11.5 11.7167 11.5 11.5C11.5 11.2833 11.4292 11.1042 11.2875 10.9625C11.1458 10.8208 10.9667 10.75 10.75 10.75H9.7ZM6 22C5.45 22 4.97917 21.8042 4.5875 21.4125C4.19583 21.0208 4 20.55 4 20V4C4 3.45 4.19583 2.97917 4.5875 2.5875C4.97917 2.19583 5.45 2 6 2H13.175C13.4417 2 13.6958 2.05 13.9375 2.15C14.1792 2.25 14.3917 2.39167 14.575 2.575L19.425 7.425C19.6083 7.60833 19.75 7.82083 19.85 8.0625C19.95 8.30417 20 8.55833 20 8.825V20C20 20.55 19.8042 21.0208 19.4125 21.4125C19.0208 21.8042 18.55 22 18 22H6ZM6 20H18V8.85L13.15 4H6V20Z"
        fill={fill || 'currentColor'}
      />
    </svg>
  );
};
