import { DefaultOptionType } from 'antd/es/select';
import { ColumnsType } from 'antd/es/table';
import { IconSort, SortOrder } from 'src/components/icons/sort/IconSort';
import {
  MyProductsColumns,
  defaultMyProductsColumns,
  getMyProductsColumnRender,
  myProductsColumnFixed,
  myProductsColumnLabel,
  myProductsColumnSortOrder,
  myProductsColumnWidth,
} from 'src/constants/products';
import { TModalContextStateAction } from 'src/contexts/modal-context';
import { IProduct } from 'src/types/general';
import { ArrayElement } from 'src/types/generic';
import { IMappedMyProducts } from 'src/types/mappings';

export type TMappedMyProductListRecord = IMappedMyProducts & {
  key: string;
};

export const getMappedProductData = (
  data: IProduct[]
): TMappedMyProductListRecord[] => {
  return data.map((row) => {
    const res: TMappedMyProductListRecord = {
      key: row.id,
      [MyProductsColumns.NAME]: row.name,
      [MyProductsColumns.MATERIAL_NUMBER]: row.materialNumber,
      [MyProductsColumns.PART_NUMBER]: row.partNumber,
      [MyProductsColumns.BARCODE]: row.barCode,
      [MyProductsColumns.EPD_FILE]: row.epdFile,
      [MyProductsColumns.EXTERNAL_EPD_URL]: row.externalEpdUrl,
      [MyProductsColumns.CREATED_AT]: row.createdAt,
      [MyProductsColumns.CREATED_BY]: row.createdByName,
      [MyProductsColumns.CURRENCY]: row.currency,
      [MyProductsColumns.RRP_PRICE]: row.rrpPrice,
      [MyProductsColumns.UOM]: row.unitOfMeasurementId,
      [MyProductsColumns.QUANTITY]: row.quantity,
      [MyProductsColumns.MODIFIED_AT]: row.modifiedAt,
      [MyProductsColumns.MODIFIED_BY]: row.modifiedByName,
      [MyProductsColumns.ACTIONS]: {
        canDelete: row.canDelete,
        canEdit: row.canEdit,
      },
    };
    return res;
  });
};

type TGetProductColumnSettingParams = {
  modalDispatch: React.Dispatch<TModalContextStateAction>;
  isActionAllowed: boolean;
  search: string;
  openEdit: (record: TMappedMyProductListRecord) => void;
  currencies: DefaultOptionType[];
  uoms: DefaultOptionType[];
};

export const getProductColumnSetting = ({
  modalDispatch,
  isActionAllowed,
  search,
  openEdit,
  currencies,
  uoms,
}: TGetProductColumnSettingParams) => {
  const render = getMyProductsColumnRender({
    search,
    modalDispatch,
    openEdit,
    currencies,
    uoms,
  });
  const result: ColumnsType<TMappedMyProductListRecord> =
    defaultMyProductsColumns.map(
      (property): ArrayElement<ColumnsType<TMappedMyProductListRecord>> => {
        switch (property) {
          case MyProductsColumns.NAME:
          case MyProductsColumns.MATERIAL_NUMBER:
          case MyProductsColumns.PART_NUMBER:
          case MyProductsColumns.BARCODE:
          case MyProductsColumns.EPD_FILE:
          case MyProductsColumns.EXTERNAL_EPD_URL:
          case MyProductsColumns.RRP_PRICE:
          case MyProductsColumns.CURRENCY:
          case MyProductsColumns.QUANTITY:
          case MyProductsColumns.UOM:
          case MyProductsColumns.CREATED_AT:
          case MyProductsColumns.CREATED_BY:
          case MyProductsColumns.MODIFIED_AT:
          case MyProductsColumns.MODIFIED_BY:
            return {
              title: myProductsColumnLabel[property],
              width: myProductsColumnWidth[property],
              key: property,
              fixed: myProductsColumnFixed[property],
              ellipsis: true,
              defaultSortOrder: myProductsColumnSortOrder[property],
              dataIndex: property,
              sorter: true,
              showSorterTooltip: false,
              sortIcon: (props) => (
                <IconSort
                  width="16"
                  height="16"
                  order={props.sortOrder as SortOrder}
                />
              ),
              render: render[property],
            };
          case MyProductsColumns.ACTIONS:
            if (!isActionAllowed) {
              return {
                hidden: true,
              };
            }
            return {
              width: myProductsColumnWidth[property],
              key: property,
              fixed: myProductsColumnFixed[property],
              dataIndex: property,
              render: render[property],
            };
          default:
            return {
              hidden: true,
            };
        }
      }
    );
  return result;
};
