import { IconAdd } from 'src/components/icons/add/IconAdd';
import Table from 'src/components/table/Table';
import TableHeader from 'src/components/table/header/TableHeader';
import UsersDrawer, { Mode } from 'src/components/users-drawer/UsersDrawer';
import { NO_RESULTS, NO_USERS } from 'src/constants/validation';
import useAdminRoute from 'src/hooks/use-admin-route';
import useUsersList from 'src/hooks/use-users-list';
import { TMappedUsersRecord } from 'src/utils/map-users';

const UsersList = () => {
  useAdminRoute();

  const {
    setSearch,
    columns,
    handleTableChange,
    loading,
    width,
    data,
    debouncedSearch,
    total,
    search,
    current,
    open,
    mode,
    userId,
    closeDrawer,
    openDrawer,
  } = useUsersList();

  return (
    <div>
      <TableHeader
        search={search}
        title={'Users'}
        bold={false}
        searchPlaceholder={'Name, email, phone'}
        setSearch={setSearch}
        onlyPrimaryAction
        primaryActionTitle="Create User"
        primaryActionIcon={<IconAdd height="16" width="16" />}
        primaryAction={() => {
          openDrawer(Mode.CREATE);
        }}
      />
      <Table<TMappedUsersRecord>
        emptyTextDescription={debouncedSearch ? NO_RESULTS : NO_USERS}
        columns={columns}
        dataSource={data}
        onChange={handleTableChange}
        loading={loading}
        width={width}
        height={'calc(100vh - 264px)'}
        total={total}
        current={current}
      />
      <UsersDrawer
        open={open}
        userId={userId}
        mode={mode}
        onClose={closeDrawer}
      />
    </div>
  );
};

export default UsersList;
