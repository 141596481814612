import { ColumnsType } from 'antd/es/table';
import { ColumnFilterItem } from 'antd/es/table/interface';
import { IconFilter } from 'src/components/icons/filter/IconFilter';
import { IconSort, SortOrder } from 'src/components/icons/sort/IconSort';
import Cell from 'src/components/table/cell/Cell';
import TableFilters from 'src/components/table/filters/TableFilters';
import TableDateFilters from 'src/components/table/filters/date/TableDateFilters';
import {
  StaticReportColumns,
  getReportsColumnRender,
  reportFilterEmptyDescription,
  reportFilterPlaceholder,
  reportsColumnLabel,
  reportsColumnWidth,
} from 'src/constants/sustainability-report';
import styles from 'src/styles/actions.module.scss';
import { IIndicator, ISustainabilityReport } from 'src/types/general';
import { ArrayElement } from 'src/types/generic';
import { IMappedSustainabilityReport } from 'src/types/mappings';
import { getTextWidth } from './get-text-width';
import { TReportColumns } from './table-configuration';

export type TMappedSustainabilityReportRecord = IMappedSustainabilityReport & {
  key: string;
};

type TGetReportColumnSettingParams = {
  search: string;
  properties: TReportColumns;
  indicators: IIndicator[];
  supplierFilters?: ColumnFilterItem[];
  projectIdFilters?: ColumnFilterItem[];
  projectNameFilters?: ColumnFilterItem[];
  invoiceDateFilters?: ColumnFilterItem[];
  searchSupplierLoading: boolean;
  isFilterSupplierSearch: boolean;
  setFilterSupplierSearch: (value: string) => void;
  searchIdLoading: boolean;
  isFilterIdSearch: boolean;
  setFilterIdSearch: (value: string) => void;
  searchNameLoading: boolean;
  isFilterNameSearch: boolean;
  setFilterNameSearch: (value: string) => void;
};

export const getMappedReportData = (
  data: ISustainabilityReport[],
  indicators: IIndicator[]
): TMappedSustainabilityReportRecord[] => {
  return data.map((row) => {
    const res: TMappedSustainabilityReportRecord = {
      key: row.requestRecordId,
      [StaticReportColumns.PRODUCT_NAME]: row.product.name,
      [StaticReportColumns.MATERIAL_NUMBER]: row.product.materialNumber,
      [StaticReportColumns.PART_NUMBER]: row.product.partNumber,
      [StaticReportColumns.BARCODE]: row.product.barCode,
      [StaticReportColumns.SUPPLIER]: row.supplier.name,
      [StaticReportColumns.ORDER]: row.invoice.purchaseOrderNumber,
      [StaticReportColumns.INVOICE_NAME]: row.invoice,
      [StaticReportColumns.INVOICE_DATE]: row.invoice.invoiceDate,
      [StaticReportColumns.INVOICE_QUANTITY]: row.invoice.quantity,
      [StaticReportColumns.INVOICE_UOM]:
        row.invoice.unitOfMeasurement.shortName,
      [StaticReportColumns.PROJECT_ID]: row.project.personalId,
      [StaticReportColumns.PROJECT_NAME]: row.project.name,
      [StaticReportColumns.EPD]: row.epd,
      [StaticReportColumns.CREATED_AT]: row.createdAt,
      [StaticReportColumns.STAGE]: row.stage.name,
    };
    indicators.forEach((indicator) => {
      const indicatorMatch = row.environmentalIndicatorsEffortValues.find(
        (el) => el.id === indicator.id
      );
      if (indicatorMatch) {
        res[indicator.id] = indicatorMatch.value;
      } else {
        res[indicator.id] = null;
      }
    });

    return res;
  });
};

export const getReportColumnSetting = function <T>({
  search,
  indicators,
  properties,
  setFilterSupplierSearch,
  isFilterSupplierSearch,
  searchSupplierLoading,
  setFilterNameSearch,
  isFilterNameSearch,
  searchNameLoading,
  setFilterIdSearch,
  isFilterIdSearch,
  searchIdLoading,
  supplierFilters,
  projectIdFilters,
  projectNameFilters,
  invoiceDateFilters,
}: TGetReportColumnSettingParams) {
  const render = getReportsColumnRender(search);
  const result = properties.map((property): ArrayElement<ColumnsType<T>> => {
    const align =
      property === StaticReportColumns.INVOICE_QUANTITY ? 'right' : 'left';
    const fixed =
      property === StaticReportColumns.PRODUCT_NAME ? 'left' : undefined;
    const defaultSortOrder =
      property === StaticReportColumns.CREATED_AT ? SortOrder.DESC : undefined;

    let filters: ColumnFilterItem[] | undefined;
    let loading = false;
    let isFilterSearch = false;
    let setSearch = (value: string) => {};

    switch (property) {
      case StaticReportColumns.SUPPLIER:
      case StaticReportColumns.PROJECT_ID:
      case StaticReportColumns.PROJECT_NAME:
      case StaticReportColumns.INVOICE_DATE:
        switch (property) {
          case StaticReportColumns.SUPPLIER:
            filters = supplierFilters;
            loading = searchSupplierLoading;
            isFilterSearch = isFilterSupplierSearch;
            setSearch = setFilterSupplierSearch;
            break;
          case StaticReportColumns.INVOICE_DATE:
            filters = invoiceDateFilters;
            break;
          case StaticReportColumns.PROJECT_ID:
            filters = projectIdFilters;
            loading = searchIdLoading;
            isFilterSearch = isFilterIdSearch;
            setSearch = setFilterIdSearch;
            break;
          case StaticReportColumns.PROJECT_NAME:
            filters = projectNameFilters;
            loading = searchNameLoading;
            isFilterSearch = isFilterNameSearch;
            setSearch = setFilterNameSearch;
            break;
        }
        return {
          title: reportsColumnLabel[property],
          width: reportsColumnWidth[property],
          key: property,
          fixed,
          ellipsis: true,
          align,
          defaultSortOrder,
          dataIndex: property,
          sorter: true,
          showSorterTooltip: false,
          sortIcon: (props) => (
            <IconSort
              width="16"
              height="16"
              order={props.sortOrder as SortOrder}
            />
          ),
          filterDropdown: (props) =>
            StaticReportColumns.INVOICE_DATE === property ? (
              <TableDateFilters {...props} />
            ) : (
              <TableFilters
                {...props}
                placeholder={reportFilterPlaceholder[property]}
                emptyDescription={reportFilterEmptyDescription[property]}
                filters={filters}
                setSearch={setSearch}
                loading={loading}
                isFilterSearch={isFilterSearch}
              />
            ),
          filters,
          filterMultiple: true,
          filterIcon: (filtered) => (
            <IconFilter
              width="16"
              height="16"
              color={filtered ? styles.iconFillActive : undefined}
            />
          ),
          render: render[property],
        };
      case StaticReportColumns.PRODUCT_NAME:
      case StaticReportColumns.MATERIAL_NUMBER:
      case StaticReportColumns.MATERIAL_NUMBER:
      case StaticReportColumns.PART_NUMBER:
      case StaticReportColumns.BARCODE:
      case StaticReportColumns.ORDER:
      case StaticReportColumns.INVOICE_NAME:
      case StaticReportColumns.INVOICE_QUANTITY:
      case StaticReportColumns.INVOICE_UOM:
      case StaticReportColumns.EPD:
      case StaticReportColumns.CREATED_AT:
      case StaticReportColumns.STAGE:
        return {
          title: reportsColumnLabel[property],
          width: reportsColumnWidth[property],
          key: property,
          fixed,
          ellipsis: true,
          align,
          defaultSortOrder,
          dataIndex: property,
          sorter: true,
          showSorterTooltip: false,
          sortIcon: (props) => (
            <IconSort
              width="16"
              height="16"
              order={props.sortOrder as SortOrder}
            />
          ),
          render: render[property],
        };
      default:
        const indicator = indicators.find(
          (indicator) => indicator.id === property
        );
        if (!indicator) {
          return { hidden: true };
        }
        const textWidth = getTextWidth(indicator.name || '');
        const uomWidth = getTextWidth(
          indicator.unitOfMeasurement.shortName || ''
        );
        return {
          title: `${indicator.name},
          ${indicator.unitOfMeasurement.shortName}`,
          align: 'right',
          className: 'table-header-with-pre',
          key: property,
          width: textWidth > uomWidth ? textWidth : uomWidth,
          ellipsis: true,
          dataIndex: property,
          render: (value) => {
            return <Cell isValue={!!value}>{value}</Cell>;
          },
        };
    }
  });
  return result;
};
