import clsx from 'clsx';
import { type FunctionComponent, type SVGProps } from 'react';

import styles from './IconAwesome.module.scss';
import { TIconProps } from 'src/types/icons';

type IconAwesomeProps = Omit<TIconProps, 'iconId'> & SVGProps<SVGSVGElement>;

export const IconAwesome: FunctionComponent<IconAwesomeProps> = ({
  className,
  width = 24,
  height = 24,
  fill,
  ...props
}): JSX.Element => {
  return (
    <svg
      className={clsx(className, styles.component)}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-element="icon"
      {...props}
    >
      <path
        d="M19.46 8.00002L20.25 6.25002L22 5.46002C22.39 5.28002 22.39 4.73002 22 4.55002L20.25 3.76002L19.46 2.00002C19.28 1.61002 18.73 1.61002 18.55 2.00002L17.76 3.75002L16 4.54002C15.61 4.72002 15.61 5.27002 16 5.45002L17.75 6.24002L18.54 8.00002C18.72 8.39002 19.28 8.39002 19.46 8.00002ZM11.5 9.50002L9.91001 6.00002C9.56001 5.22002 8.44001 5.22002 8.09001 6.00002L6.50001 9.50002L3.00001 11.09C2.22001 11.45 2.22001 12.56 3.00001 12.91L6.50001 14.5L8.09001 18C8.45001 18.78 9.56001 18.78 9.91001 18L11.5 14.5L15 12.91C15.78 12.55 15.78 11.44 15 11.09L11.5 9.50002ZM18.54 16L17.75 17.75L16 18.54C15.61 18.72 15.61 19.27 16 19.45L17.75 20.24L18.54 22C18.72 22.39 19.27 22.39 19.45 22L20.24 20.25L22 19.46C22.39 19.28 22.39 18.73 22 18.55L20.25 17.76L19.46 16C19.28 15.61 18.72 15.61 18.54 16Z"
        fill={fill || 'currentColor'}
      />
    </svg>
  );
};
