import { Table as AntdTable, Empty } from 'antd';
import { ColumnsType, TableProps } from 'antd/es/table';

type TTableProps<T> = {
  className?: string;
  dataSource: T[];
  total?: number;
  columns: ColumnsType<T>;
  emptyTextDescription: string;
  onChange: TableProps<T>['onChange'];
  loading?: boolean;
  width: number | string;
  height: number | string;
  rowClassName?: (row: T) => string;
  onRow?: (
    record: T,
    id?: number
  ) => {
    onClick: () => void;
  };
  isPagination?: boolean;
  current?: number;
};

const Table = function <T>({
  className,
  dataSource,
  columns,
  total,
  emptyTextDescription,
  onChange,
  loading,
  width,
  height,
  onRow,
  rowClassName,
  isPagination = true,
  current,
}: TTableProps<T>) {
  return (
    <AntdTable<T>
      className={className}
      bordered
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={emptyTextDescription}
          />
        ),
      }}
      rowClassName={rowClassName}
      columns={columns}
      dataSource={dataSource}
      onChange={onChange}
      onRow={onRow}
      loading={loading}
      scroll={{ x: width, y: height }}
      pagination={
        isPagination && {
          pageSizeOptions: [10, 20, 50],
          showSizeChanger: true,
          showQuickJumper: true,
          total: total,
          current,
        }
      }
    />
  );
};

export default Table;
