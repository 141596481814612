import {
  Button,
  DatePicker,
  Form,
  InputNumber,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { IconCheck } from 'src/components/icons/check/IconCheck';
import { IconClose } from 'src/components/icons/close/IconClose';
import { IconDate } from 'src/components/icons/date/IconDate';
import { IconEdit } from 'src/components/icons/edit/IconEdit';
import { IconHistory } from 'src/components/icons/history/IconHistory';
import { SortOrder } from 'src/components/icons/sort/IconSort';
import { IconUnlink } from 'src/components/icons/unlink/IconUnlink';
import Select from 'src/components/select/Select';
import Cell from 'src/components/table/cell/Cell';
import Highlighter from 'src/components/table/highlighter/Highlighter';
import styles from 'src/styles/actions.module.scss';
import { IMappedProductOffer } from 'src/types/mappings';
import { formatDate } from 'src/utils/date-formatter';
import { TMappedProductOfferRecord } from 'src/utils/map-assigned-product-table';
import { formatNumber } from 'src/utils/number-formatter';

export enum ProductOffersColumns {
  GLN = 'customer.gln',
  NAME = 'product.name',
  MATERIAL_NUMBER = 'product.materialNumber',
  PART_NUMBER = 'product.partNumber',
  BARCODE = 'product.barCode',
  STATUS = 'product.isDeleted',
  SUPPLIER = 'supplier.name',
  CUSTOMER = 'customer.name',
  PRICE = 'productOfferPrice.price',
  CURRENCY = 'productOfferPrice.currency',
  QUANTITY = 'quantity',
  UOM = 'unitOfMeasurement',
  START_DATE = 'productOfferPrice.startDate',
  EXPIRATION_DATE = 'productOfferPrice.expirationDate',
  CREATED_AT = 'createdAt',
  INDUSTRY = 'customer.industry',
  ACTIONS = 'actions',
}

export const defaultProductDetailsColumns = [
  ProductOffersColumns.GLN,
  ProductOffersColumns.CUSTOMER,
  ProductOffersColumns.PRICE,
  ProductOffersColumns.CURRENCY,
  ProductOffersColumns.QUANTITY,
  ProductOffersColumns.UOM,
  ProductOffersColumns.START_DATE,
  ProductOffersColumns.EXPIRATION_DATE,
  ProductOffersColumns.CREATED_AT,
  ProductOffersColumns.INDUSTRY,
  ProductOffersColumns.ACTIONS,
];

export const defaultAssginedProductsColumns = [
  ProductOffersColumns.NAME,
  ProductOffersColumns.STATUS,
  ProductOffersColumns.PRICE,
  ProductOffersColumns.CURRENCY,
  ProductOffersColumns.QUANTITY,
  ProductOffersColumns.UOM,
  ProductOffersColumns.START_DATE,
  ProductOffersColumns.EXPIRATION_DATE,
  ProductOffersColumns.CREATED_AT,
  ProductOffersColumns.MATERIAL_NUMBER,
  ProductOffersColumns.PART_NUMBER,
  ProductOffersColumns.BARCODE,
  ProductOffersColumns.ACTIONS,
];

export const defaultSuppliersProductsColumns = [
  ProductOffersColumns.NAME,
  ProductOffersColumns.STATUS,
  ProductOffersColumns.SUPPLIER,
  ProductOffersColumns.CREATED_AT,
  ProductOffersColumns.PRICE,
  ProductOffersColumns.CURRENCY,
  ProductOffersColumns.QUANTITY,
  ProductOffersColumns.UOM,
  ProductOffersColumns.START_DATE,
  ProductOffersColumns.EXPIRATION_DATE,
  ProductOffersColumns.MATERIAL_NUMBER,
  ProductOffersColumns.PART_NUMBER,
  ProductOffersColumns.BARCODE,
];

export const getProductOffersColumnLabel = (isSupplier?: boolean) => ({
  [ProductOffersColumns.GLN]: 'GLN',
  [ProductOffersColumns.CUSTOMER]: 'Customer name',
  [ProductOffersColumns.INDUSTRY]: 'Industry',
  [ProductOffersColumns.NAME]: 'Product name',
  [ProductOffersColumns.STATUS]: 'Product status',
  [ProductOffersColumns.SUPPLIER]: 'Supplier',
  [ProductOffersColumns.PRICE]: 'List price',
  [ProductOffersColumns.CURRENCY]: 'Currency',
  [ProductOffersColumns.QUANTITY]: isSupplier ? 'Qty' : 'Assigned Qty',
  [ProductOffersColumns.UOM]: isSupplier ? 'UoM' : 'Assigned UoM',
  [ProductOffersColumns.START_DATE]: 'Price effective',
  [ProductOffersColumns.EXPIRATION_DATE]: 'Price expiration',
  [ProductOffersColumns.CREATED_AT]: 'Assigned on',
  [ProductOffersColumns.MATERIAL_NUMBER]: 'Material number',
  [ProductOffersColumns.PART_NUMBER]: 'Part number',
  [ProductOffersColumns.BARCODE]: 'Barcode',
  [ProductOffersColumns.ACTIONS]: '',
});

export const getProductOffersColumnWidth = (
  singleAction: boolean,
  isSupplier?: boolean
): Partial<Record<ProductOffersColumns, number>> => ({
  [ProductOffersColumns.GLN]: 128,
  [ProductOffersColumns.NAME]: 200,
  [ProductOffersColumns.STATUS]: 138,
  [ProductOffersColumns.SUPPLIER]: 200,
  [ProductOffersColumns.CUSTOMER]: 200,
  [ProductOffersColumns.PRICE]: 120,
  [ProductOffersColumns.CURRENCY]: 96,
  [ProductOffersColumns.QUANTITY]: isSupplier ? 70 : 128,
  [ProductOffersColumns.UOM]: isSupplier ? 70 : 134,
  [ProductOffersColumns.START_DATE]: 134,
  [ProductOffersColumns.EXPIRATION_DATE]: 146,
  [ProductOffersColumns.CREATED_AT]: 128,
  [ProductOffersColumns.INDUSTRY]: 155,
  [ProductOffersColumns.MATERIAL_NUMBER]: 152,
  [ProductOffersColumns.PART_NUMBER]: 136,
  [ProductOffersColumns.BARCODE]: 136,
  [ProductOffersColumns.ACTIONS]: singleAction ? 32 : 60,
});

export const productOffersColumnFixed: Partial<
  Record<ProductOffersColumns, 'left' | 'right'>
> = {
  [ProductOffersColumns.GLN]: 'left',
  [ProductOffersColumns.NAME]: 'left',
  [ProductOffersColumns.ACTIONS]: 'right',
};

export const productOffersColumnSortOrder: Partial<
  Record<ProductOffersColumns, SortOrder>
> = {
  [ProductOffersColumns.CREATED_AT]: SortOrder.DESC,
};

export const productOffersColumnAlign: Partial<
  Record<ProductOffersColumns, 'right'>
> = {
  [ProductOffersColumns.PRICE]: 'right',
  [ProductOffersColumns.QUANTITY]: 'right',
};

type TGetProductOffersColumnRenderParams = {
  search: string;
  currencies: DefaultOptionType[];
  uoms: DefaultOptionType[];
  save: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    key: string
  ) => Promise<void>;
  edit: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    index: number,
    record: TMappedProductOfferRecord
  ) => void;
  cancel: (e: React.MouseEvent<HTMLElement, MouseEvent>, index: number) => void;
  archive: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    record: TMappedProductOfferRecord
  ) => void;
  archiveTooltip: string;
  disabled: boolean;
};

export const getProductOffersColumnRender = function ({
  search,
  currencies,
  uoms,
  save,
  edit,
  cancel,
  archive,
  archiveTooltip,
  disabled,
}: TGetProductOffersColumnRenderParams) {
  return {
    [ProductOffersColumns.GLN]: (
      value: IMappedProductOffer[ProductOffersColumns.GLN]
    ) => {
      return <Cell isValue={!!value}>{value}</Cell>;
    },
    [ProductOffersColumns.NAME]: (
      value: IMappedProductOffer[ProductOffersColumns.NAME]
    ) => {
      return (
        <Cell isValue={!!value}>
          <Highlighter value={value || null} search={search} />
        </Cell>
      );
    },
    [ProductOffersColumns.STATUS]: (
      value: IMappedProductOffer[ProductOffersColumns.STATUS]
    ) => {
      return (
        <Tag color={value ? 'red' : undefined}>
          {value ? 'Archived' : 'Active'}
        </Tag>
      );
    },
    [ProductOffersColumns.SUPPLIER]: (
      value: IMappedProductOffer[ProductOffersColumns.SUPPLIER]
    ) => {
      return <Cell isValue={!!value}>{value}</Cell>;
    },
    [ProductOffersColumns.CUSTOMER]: (
      value: IMappedProductOffer[ProductOffersColumns.CUSTOMER]
    ) => {
      return <Cell isValue={!!value}>{value}</Cell>;
    },
    [ProductOffersColumns.PRICE]: (
      value: IMappedProductOffer[ProductOffersColumns.PRICE],
      record: TMappedProductOfferRecord
    ) => {
      if (record.isEditing) {
        return (
          <Form.Item
            className={styles.noMargin}
            name={ProductOffersColumns.PRICE}
          >
            <InputNumber<number>
              className={styles.numeric}
              size="small"
              min={0.01}
              controls={false}
              formatter={formatter}
            />
          </Form.Item>
        );
      }
      return <Cell isValue={!!value}>{formatNumber(value)}</Cell>;
    },
    [ProductOffersColumns.CURRENCY]: (
      value: IMappedProductOffer[ProductOffersColumns.CURRENCY],
      record: TMappedProductOfferRecord
    ) => {
      if (record.isEditing) {
        return (
          <Form.Item
            className={styles.noMargin}
            name={ProductOffersColumns.CURRENCY}
          >
            <Select size="small" options={currencies} />
          </Form.Item>
        );
      }
      return (
        <Cell isValue={!!value}>
          {currencies.find((el) => el.value === value)?.label}
        </Cell>
      );
    },
    [ProductOffersColumns.QUANTITY]: (
      value: IMappedProductOffer[ProductOffersColumns.QUANTITY],
      record: TMappedProductOfferRecord
    ) => {
      if (record.isEditing) {
        return (
          <Form.Item
            className={styles.noMargin}
            name={ProductOffersColumns.QUANTITY}
          >
            <InputNumber<number>
              className={styles.numeric}
              size="small"
              min={0.01}
              controls={false}
              formatter={formatter}
            />
          </Form.Item>
        );
      }
      return <Cell isValue={!!value}>{formatNumber(value)}</Cell>;
    },
    [ProductOffersColumns.UOM]: (
      value: IMappedProductOffer[ProductOffersColumns.UOM],
      record: TMappedProductOfferRecord
    ) => {
      if (record.isEditing) {
        return (
          <Form.Item
            className={styles.noMargin}
            name={ProductOffersColumns.UOM}
          >
            <Select size="small" options={uoms} />
          </Form.Item>
        );
      }
      return (
        <Cell isValue={!!value}>
          {uoms.find((el) => el.value === value)?.label}
        </Cell>
      );
    },
    [ProductOffersColumns.START_DATE]: (
      value: IMappedProductOffer[ProductOffersColumns.START_DATE],
      record: TMappedProductOfferRecord
    ) => {
      if (record.isEditing) {
        return (
          <Form.Item
            className={styles.noMargin}
            name={ProductOffersColumns.START_DATE}
          >
            <DatePicker
              size="small"
              format={'DD/MM/YYYY'}
              suffixIcon={
                <IconDate width="16" height="16" color={styles.iconCalendar} />
              }
            />
          </Form.Item>
        );
      }
      return <Cell isValue={!!value}>{formatDate({ date: value })}</Cell>;
    },
    [ProductOffersColumns.EXPIRATION_DATE]: (
      value: IMappedProductOffer[ProductOffersColumns.EXPIRATION_DATE],
      record: TMappedProductOfferRecord
    ) => {
      if (record.isEditing) {
        return (
          <Form.Item
            className={styles.noMargin}
            name={ProductOffersColumns.EXPIRATION_DATE}
            initialValue={value ? dayjs(value) : value}
          >
            <DatePicker
              size="small"
              format={'DD/MM/YYYY'}
              suffixIcon={
                <IconDate width="16" height="16" color={styles.iconCalendar} />
              }
            />
          </Form.Item>
        );
      }
      return (
        <Cell isValue={!!value}>
          <Typography.Text
            className={clsx(styles.noMargin, {
              [styles.expired]: new Date(value!) < new Date(),
            })}
          >
            {formatDate({ date: value })}
          </Typography.Text>
        </Cell>
      );
    },
    [ProductOffersColumns.CREATED_AT]: (
      value: IMappedProductOffer[ProductOffersColumns.CREATED_AT]
    ) => {
      return <Cell isValue={!!value}>{formatDate({ date: value })}</Cell>;
    },
    [ProductOffersColumns.INDUSTRY]: (
      value: IMappedProductOffer[ProductOffersColumns.INDUSTRY]
    ) => {
      return <Cell isValue={!!value}>{value}</Cell>;
    },
    [ProductOffersColumns.MATERIAL_NUMBER]: (
      value: IMappedProductOffer[ProductOffersColumns.MATERIAL_NUMBER]
    ) => {
      return (
        <Cell isValue={!!value}>
          <Highlighter value={value || ''} search={search} />
        </Cell>
      );
    },
    [ProductOffersColumns.PART_NUMBER]: (
      value: IMappedProductOffer[ProductOffersColumns.PART_NUMBER]
    ) => {
      return (
        <Cell isValue={!!value}>
          <Highlighter value={value || ''} search={search} />
        </Cell>
      );
    },
    [ProductOffersColumns.BARCODE]: (
      value: IMappedProductOffer[ProductOffersColumns.BARCODE]
    ) => {
      return (
        <Cell isValue={!!value}>
          <Highlighter value={value || ''} search={search} />
        </Cell>
      );
    },
    [ProductOffersColumns.ACTIONS]: (
      value: IMappedProductOffer[ProductOffersColumns.ACTIONS],
      record: TMappedProductOfferRecord,
      index: number,
      isSingle = false
    ) => {
      if (isSingle) {
        return (
          <Tooltip title="Purchase history">
            <Button
              size="small"
              type="text"
              icon={
                <IconHistory color={styles.iconFill} height="16" width="16" />
              }
            />
          </Tooltip>
        );
      }
      return record.isEditing ? (
        <div className={styles.component}>
          <Tooltip title="Save">
            <Button
              size="small"
              type="text"
              onClick={async (e) => await save(e, record.key)}
              icon={
                <IconCheck
                  color={styles.iconFillActive}
                  height="16"
                  width="16"
                />
              }
            />
          </Tooltip>
          <Tooltip title="Cancel">
            <Button
              size="small"
              type="text"
              onClick={(e) => cancel(e, index)}
              icon={
                <IconClose
                  color={styles.iconFillActive}
                  height="16"
                  width="16"
                />
              }
            />
          </Tooltip>
        </div>
      ) : (
        <div
          className={clsx(styles.component, {
            [styles.end]: !value.canEdit,
          })}
        >
          {value.canEdit && (
            <Tooltip title="Edit">
              <Button
                disabled={disabled}
                size="small"
                type="text"
                onClick={(e) => edit(e, index, record)}
                icon={
                  <IconEdit color={styles.iconFill} height="16" width="16" />
                }
              />
            </Tooltip>
          )}
          {value.canDelete && (
            <Tooltip title={archiveTooltip}>
              <Button
                size="small"
                type="text"
                onClick={(e) => archive(e, record)}
                icon={
                  <IconUnlink color={styles.iconFill} height="16" width="16" />
                }
              />
            </Tooltip>
          )}
        </div>
      );
    },
  };
};

const formatter = (value?: number) => {
  if (!value && value !== 0) {
    return '';
  }
  if (value <= 0) {
    return formatNumber(0.01);
  }
  return formatNumber(value);
};
