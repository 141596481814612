import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Mode } from 'src/components/products/drawer/ProductsDrawer';
import { useMeasurementsContext } from 'src/contexts/measurements-context';

const useProductsList = () => {
  const { getAllMeasurements } = useMeasurementsContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const searchParam = searchParams.get('supplierList');
  const defaultTab = searchParam && searchParam === 'true' ? '2' : '1';
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState<Mode | null>(null);
  const [productId, setProductId] = useState<string | null>(null);

  const closeDrawer = () => {
    setProductId('');
    setOpen(false);
  };

  const openDrawer = (mode: Mode, productId?: string) => {
    setMode(mode);
    if (productId) {
      setProductId(productId);
    }
    setOpen(true);
  };

  useEffect(() => {
    getAllMeasurements();
  }, []);

  const handleTabChange = (key: string) => {
    if (key === '2') {
      setSearchParams((params) => {
        params.set('supplierList', 'true');
        return params;
      });
    } else {
      setSearchParams((params) => {
        params.set('supplierList', 'false');
        return params;
      });
    }
  };

  return {
    productId,
    mode,
    open,
    closeDrawer,
    defaultTab,
    handleTabChange,
    openDrawer,
  };
};

export default useProductsList;
