import clsx from 'clsx';
import { type FunctionComponent, type SVGProps } from 'react';

import styles from './IconQualityIssue.module.scss';
import { TIconProps } from 'src/types/icons';
import { Tooltip } from 'antd';
import { HiveStatus } from 'src/constants/hive-map';

type IconQualityIssueProps = Omit<TIconProps, 'iconId'> &
  SVGProps<SVGSVGElement>;

export const IconQualityIssue: FunctionComponent<IconQualityIssueProps> = ({
  className,
  width = 24,
  height = 24,
  ...props
}): JSX.Element => {
  return (
    <Tooltip title={HiveStatus.QUALITY_ISSUE}>
      <svg
        className={clsx(className, styles.component)}
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-element="icon"
        {...props}
      >
        <rect
          width="24"
          height="24"
          rx="8"
          fill="#111111"
          fillOpacity="0.04"
        />
        <rect
          x="0.5"
          y="0.5"
          width="23"
          height="23"
          rx="7.5"
          stroke="#111111"
          strokeOpacity="0.45"
        />

        <path
          d="M5.86516 17.6C5.74394 17.6 5.63766 17.5725 5.54633 17.5175C5.4551 17.4625 5.3841 17.39 5.33333 17.3C5.27777 17.2111 5.25277 17.1111 5.25833 17C5.26388 16.8889 5.29444 16.7834 5.34999 16.6834L11.4833 6.45002C11.5389 6.35002 11.614 6.2778 11.7088 6.23335C11.8035 6.18891 11.9007 6.16669 12.0005 6.16669C12.1002 6.16669 12.1972 6.18891 12.2917 6.23335C12.3861 6.2778 12.4611 6.35002 12.5167 6.45002L18.65 16.6834C18.7055 16.7834 18.7361 16.8889 18.7417 17C18.7472 17.1111 18.7222 17.2111 18.6667 17.3C18.6111 17.3889 18.5389 17.4611 18.45 17.5167C18.3611 17.5722 18.256 17.6 18.1348 17.6H5.86516ZM11.9965 15.6C12.1655 15.6 12.3083 15.5429 12.425 15.4285C12.5417 15.3142 12.6 15.1725 12.6 15.0035C12.6 14.8345 12.5428 14.6917 12.4285 14.575C12.3142 14.4584 12.1725 14.4 12.0035 14.4C11.8345 14.4 11.6917 14.4572 11.575 14.5715C11.4583 14.6859 11.4 14.8275 11.4 14.9965C11.4 15.1655 11.4572 15.3084 11.5715 15.425C11.6858 15.5417 11.8275 15.6 11.9965 15.6ZM11.9965 13.6C12.1655 13.6 12.3083 13.5425 12.425 13.4275C12.5417 13.3125 12.6 13.17 12.6 13V11C12.6 10.83 12.5428 10.6875 12.4285 10.5725C12.3142 10.4575 12.1725 10.4 12.0035 10.4C11.8345 10.4 11.6917 10.4575 11.575 10.5725C11.4583 10.6875 11.4 10.83 11.4 11V13C11.4 13.17 11.4572 13.3125 11.5715 13.4275C11.6858 13.5425 11.8275 13.6 11.9965 13.6Z"
          fill="#111111"
          fillOpacity="0.88"
        />
      </svg>
    </Tooltip>
  );
};
