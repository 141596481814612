import { Spin } from 'antd';
import styles from './Loader.module.scss';

type TLoaderProps = {
  visible: boolean;
  text?: string;
};

const Loader = ({ visible, text = 'Loading' }: TLoaderProps) => {
  if (!visible) {
    return null;
  }

  return (
    <div className={styles.component}>
      <Spin tip={text}>{text}</Spin>
    </div>
  );
};

export default Loader;
