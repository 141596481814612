import { useMemo, useState } from 'react';
import { SortOrder } from 'src/components/icons/sort/IconSort';
import { useModalContext } from 'src/contexts/modal-context';
import { ITableParamsWithSearch } from 'src/types/generic';
import {
  TMappedCustomerRecord,
  getCompanyColumnSetting,
  getMappedCustomerData,
} from 'src/utils/map-companies-table';
import useCompanies from './use-companies';
import useTableSettings from './use-table-settings';
import useTableWidth from './use-table-width';

const useCustomersList = () => {
  const { getCustomers } = useCompanies();

  const { modalContextDispatch } = useModalContext();

  const [data, setData] = useState<TMappedCustomerRecord[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchCustomers = async (tableParams: ITableParamsWithSearch) => {
    setLoading(true);
    const result = await getCustomers({
      ...tableParams,
      includeDeleted: true,
    });

    if (result.result?.data.data) {
      setData(getMappedCustomerData(result.result.data.data));
      setTotal(result.result.data.totalCount);
    }
    setLoading(false);
  };

  const { tableParams, search, setSearch, handleTableChange } =
    useTableSettings({
      sortField: 'gln',
      sortOrder: SortOrder.ASC,
      fetcher: fetchCustomers,
    });

  const columns = useMemo(() => {
    const res = getCompanyColumnSetting<TMappedCustomerRecord>({
      modalDispatch: modalContextDispatch,
      search: tableParams.search,
      isCustomer: true,
    });
    return res;
  }, [tableParams]);

  const tableWidth = useTableWidth(columns);

  return {
    handleTableChange,
    setSearch,
    loading,
    columns,
    width: tableWidth,
    data,
    debouncedSearch: tableParams.search,
    current: tableParams.pagination?.current,
    selectedId,
    setSelectedId,
    total,
    search,
  };
};

export default useCustomersList;
