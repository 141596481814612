import { ColumnsType } from 'antd/es/table';
import { IconSort, SortOrder } from 'src/components/icons/sort/IconSort';
import {
  UsersColumns,
  defaultUserColumns,
  getUsersColumnRender,
  usersColumnFixed,
  usersColumnLabel,
  usersColumnSortOrder,
  usersColumnWidth,
} from 'src/constants/users';
import { TModalContextStateAction } from 'src/contexts/modal-context';
import { IUser } from 'src/types/general';
import { ArrayElement } from 'src/types/generic';
import { IMappedUsers } from 'src/types/mappings';

export type TMappedUsersRecord = IMappedUsers & {
  key: string;
};

type TGetUsersColumnSettingParams = {
  search: string;
  openEdit: (row: TMappedUsersRecord) => void;
  modalDispatch: React.Dispatch<TModalContextStateAction>;
};

export const getMappedUsersData = (data: IUser[]): TMappedUsersRecord[] => {
  return data.map((row) => {
    const res: TMappedUsersRecord = {
      key: row.id,
      [UsersColumns.NAME]: row.fullName,
      [UsersColumns.JOB_TITLE]: row.jobTitle,
      [UsersColumns.STATUS]: {
        status: row.status,
        statusActions: row.statusActions,
        canEdit: row.canEdit,
      },
      [UsersColumns.ROLE]: row.role,
      [UsersColumns.EMAIL]: row.email,
      [UsersColumns.PHONE]: row.phoneNumber,
      [UsersColumns.CREATED_AT]: row.createdAt,
      [UsersColumns.LAST_ACTIVITY]: row.lastActiveAt,
      [UsersColumns.ACTIONS]: {
        canEdit: row.canEdit,
        canInvite: row.canInvite,
      },
    };

    return res;
  });
};

export const getUsersColumnSetting = function ({
  search,
  openEdit,
  modalDispatch,
}: TGetUsersColumnSettingParams) {
  const render = getUsersColumnRender(search, openEdit, modalDispatch);
  const result = defaultUserColumns.map(
    (property): ArrayElement<ColumnsType<TMappedUsersRecord>> => {
      switch (property) {
        case UsersColumns.NAME:
        case UsersColumns.JOB_TITLE:
        case UsersColumns.STATUS:
        case UsersColumns.ROLE:
        case UsersColumns.EMAIL:
        case UsersColumns.PHONE:
        case UsersColumns.CREATED_AT:
        case UsersColumns.LAST_ACTIVITY:
          return {
            title: usersColumnLabel[property],
            width: usersColumnWidth[property],
            key: property,
            fixed: usersColumnFixed[property],
            ellipsis: true,
            defaultSortOrder: usersColumnSortOrder[property],
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: render[property],
          };
        case UsersColumns.ACTIONS:
          return {
            width: usersColumnWidth[property],
            key: property,
            fixed: usersColumnFixed[property],
            dataIndex: property,
            render: render[property],
          };
        default:
          return {
            hidden: true,
          };
      }
    }
  );
  return result;
};
