import { Button, Checkbox, CheckboxProps } from 'antd';
import { useMemo, useState } from 'react';
import SearchInput from 'src/components/search-input/SearchInput';
import { useMeasurementsContext } from 'src/contexts/measurements-context';
import useIndicatorsCheckboxes from 'src/hooks/use-indicators-checkboxes';
import useProductCheckboxes from 'src/hooks/use-product-checkboxes';
import {
  TReportColumns,
  getInitialColumns,
} from 'src/utils/table-configuration';
import CheckboxGroup from '../checkbox-group/CheckboxGroup';
import styles from './ConfigurationDrawerForm.module.scss';

type TConfigurationDrawerProps = {
  onClose: () => void;
  onSave: (value: TReportColumns) => void;
};

const ConfigurationDrawerForm = ({
  onClose,
  onSave,
}: TConfigurationDrawerProps) => {
  const { indicators } = useMeasurementsContext();

  const [search, setSearch] = useState('');

  const initialColumns = useMemo(() => getInitialColumns(), []);

  const {
    indicatorOptions,
    indicatorColumns,
    setSelectedIndicator,
    allIndicatorsChecked,
    allIndicatorsUnchecked,
    isAllChecked,
  } = useIndicatorsCheckboxes(indicators, initialColumns);

  const {
    columnsOptions,
    columns,
    setSelectedColumn,
    allColumnsChecked,
    allColumnsUnchecked,
    isAllCallumnsChecked,
  } = useProductCheckboxes(initialColumns);

  const checkAll = isAllChecked && isAllCallumnsChecked;
  const indeterminate = !checkAll;

  const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
    if (e.target.checked) {
      setSelectedIndicator(allIndicatorsChecked);
      setSelectedColumn(allColumnsChecked);
    } else {
      setSelectedIndicator(allIndicatorsUnchecked);
      setSelectedColumn(allColumnsUnchecked);
    }
  };

  return (
    <>
      <SearchInput
        placeholder="Column name"
        search={search}
        setSearch={setSearch}
      />
      <div className={styles.container}>
        <Checkbox
          className={styles.checkbox}
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          Select all
        </Checkbox>
        <CheckboxGroup
          options={columnsOptions}
          search={search}
          label="Product-related data"
          value={columns}
          setSelected={setSelectedColumn}
        />
        <CheckboxGroup
          options={indicatorOptions}
          search={search}
          label="Environmental indicators"
          value={indicatorColumns}
          setSelected={setSelectedIndicator}
        />
      </div>
      <div className={styles.buttonContainer}>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            const res = [...columns, ...indicatorColumns];
            onSave(res);
            onClose();
          }}
          type="primary"
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default ConfigurationDrawerForm;
