import clsx from 'clsx';
import { type FunctionComponent, type SVGProps } from 'react';

import styles from './IconForwardInbox.module.scss';
import { TIconProps } from 'src/types/icons';

type IconForwardInboxProps = Omit<TIconProps, 'iconId'> &
  SVGProps<SVGSVGElement>;

export const IconForwardInbox: FunctionComponent<IconForwardInboxProps> = ({
  className,
  width = 24,
  height = 24,
  fill,
  ...props
}): JSX.Element => {
  return (
    <svg
      className={clsx(className, styles.component)}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-element="icon"
      {...props}
    >
      <path
        d="M20 6H4V18H12C12.2833 18 12.5208 18.0958 12.7125 18.2875C12.9042 18.4792 13 18.7167 13 19C13 19.2833 12.9042 19.5208 12.7125 19.7125C12.5208 19.9042 12.2833 20 12 20H4C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H20C20.55 4 21.0208 4.19583 21.4125 4.5875C21.8042 4.97917 22 5.45 22 6V12C22 12.2833 21.9042 12.5208 21.7125 12.7125C21.5208 12.9042 21.2833 13 21 13C20.7167 13 20.4792 12.9042 20.2875 12.7125C20.0958 12.5208 20 12.2833 20 12V6ZM4 6V18V13V13.075V6ZM12 11L20 6V8L12.525 12.675C12.3583 12.775 12.1833 12.825 12 12.825C11.8167 12.825 11.6417 12.775 11.475 12.675L4 8V6L12 11ZM19.175 20H16C15.7167 20 15.4792 19.9042 15.2875 19.7125C15.0958 19.5208 15 19.2833 15 19C15 18.7167 15.0958 18.4792 15.2875 18.2875C15.4792 18.0958 15.7167 18 16 18H19.175L18.275 17.1C18.075 16.9 17.9792 16.6667 17.9875 16.4C17.9958 16.1333 18.1 15.9 18.3 15.7C18.5 15.5167 18.7333 15.4208 19 15.4125C19.2667 15.4042 19.5 15.5 19.7 15.7L22.3 18.3C22.4 18.4 22.475 18.5083 22.525 18.625C22.575 18.7417 22.6 18.8667 22.6 19C22.6 19.1333 22.575 19.2583 22.525 19.375C22.475 19.4917 22.4 19.6 22.3 19.7L19.7 22.3C19.5167 22.4833 19.2875 22.5792 19.0125 22.5875C18.7375 22.5958 18.5 22.5 18.3 22.3C18.1167 22.1167 18.025 21.8833 18.025 21.6C18.025 21.3167 18.1167 21.0833 18.3 20.9L19.175 20Z"
        fill={fill || 'currentColor'}
      />
    </svg>
  );
};
