import axios, {
  AxiosError,
  AxiosProgressEvent,
  AxiosResponse,
  GenericAbortSignal,
  ResponseType,
} from 'axios';
import {
  FunctionComponent,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'src/constants/routes';
import { TGetAccessTokenResponse } from 'src/types/backend';
import {
  REFRESH_TOKEN,
  UserContextActions,
  useUserContext,
} from './user-context';

export const API_URL = process.env.REACT_APP_API_URL;

export enum HTTP {
  POST = 'post',
  PUT = 'put',
  GET = 'get',
  PATCH = 'patch',
  DELETE = 'delete',
}

export type TFetchParams = {
  url: string;
  method?: HTTP;
  params?: unknown;
  data?: unknown;
  responseType?: ResponseType;
  retry?: boolean;
  signal?: GenericAbortSignal;
  onUploadProgress?: (progress: AxiosProgressEvent) => void;
};

export type TValidationError = {
  errors: unknown;
  status: number;
  title: string;
  traceId: string;
  type: string;
};

export type TResponse<T, E> = {
  result: AxiosResponse<T> | null;
  errors: AxiosError<E> | null;
};

export type TNetworkContext = {
  fetch: <T, E>(params: TFetchParams) => Promise<TResponse<T, E>>;
};

const NetworkContext = createContext<TNetworkContext>({
  fetch: () => {
    return new Promise(() => {});
  },
});

type NetworkContextProviderProps = PropsWithChildren;

const axiosInstance = axios.create({
  baseURL: API_URL,
});

export const NetworkContextProvider: FunctionComponent<
  NetworkContextProviderProps
> = ({ children }) => {
  const {
    userContextState: { accessToken },
    userContextDispatch,
  } = useUserContext();

  const navigate = useNavigate();

  const isRefreshing = useRef(false);

  const retryQueue = useRef<Array<() => void>>([]);

  // Note: interceptor to handle auth token
  useEffect(() => {
    const requestInterceptor = axiosInstance.interceptors.request.use(
      (config) => {
        if (accessToken) {
          config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
      }
    );

    if (accessToken) {
      setTimeout(() => {
        retryQueue.current.forEach((retry) => retry());
        retryQueue.current = [];
      }, 200);
    }

    return () => {
      axiosInstance.interceptors.request.eject(requestInterceptor);
    };
  }, [accessToken]);

  const fetch = async function <T, E>({
    url,
    method = HTTP.GET,
    responseType = 'json',
    params,
    data,
    retry = true,
    signal,
    onUploadProgress,
  }: TFetchParams) {
    try {
      const response = await axiosInstance({
        url,
        responseType,
        method,
        params,
        data,
        signal,
        onUploadProgress,
      });
      return { result: response as T, errors: null } as TResponse<T, E>;
    } catch (error) {
      const axiosError = error as AxiosError<E>;

      if (axiosError.status === 401 && retry) {
        if (!isRefreshing.current) {
          isRefreshing.current = true;
          await handleTokenRefresh();
          isRefreshing.current = false;
        }
        const prom = new Promise<TResponse<T, E>>((resolve) => {
          retryQueue.current.push(async () => {
            const result = await fetch<T, E>({
              url,
              method,
              responseType,
              params,
              data,
              retry: false,
            });
            return resolve(result);
          });
        });
        return prom;
      }

      return { result: null, errors: axiosError } as TResponse<T, E>;
    }
  };

  const handleTokenRefresh = async (): Promise<string | null> => {
    const refreshToken = localStorage.getItem('refreshToken');

    if (!refreshToken) {
      navigate(Routes.SIGNIN);
      return null;
    }

    try {
      const response: AxiosResponse<TGetAccessTokenResponse> = await axios.post(
        `${API_URL}/identity/refresh`,
        {
          refreshToken,
        }
      );

      const newAccessToken = response.data.accessToken;

      if (newAccessToken) {
        localStorage.setItem(REFRESH_TOKEN, response.data.refreshToken);
        userContextDispatch({
          type: UserContextActions.SET_USER,
          value: newAccessToken,
        });
        return newAccessToken;
      }
    } catch (error) {
      const axiosError = error as AxiosError;

      if (axiosError.status === 401 || axiosError.status === 400) {
        console.log(
          `%c⏩\tSession expired.`,
          'color: #876800;'
        );
        localStorage.removeItem(REFRESH_TOKEN);
        userContextDispatch({
          type: UserContextActions.LOGOUT,
        });
      }
    }

    return null;
  };

  return (
    <NetworkContext.Provider value={{ fetch }}>
      {children}
    </NetworkContext.Provider>
  );
};

export const useNetworkContext = () => {
  const context = useContext(NetworkContext);

  if (!context) {
    throw new Error(
      '"NetworkContext" context is not in scope. Use this hook in a component that is wrapped with the <NetworkContextProvider /> component.'
    );
  }

  return context;
};
