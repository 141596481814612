import { Alert, Checkbox } from 'antd';
import { useEffect } from 'react';
import { IconDownload } from 'src/components/icons/download/IconDownload';
import { IconInfo } from 'src/components/icons/info/IconInfo';
import { IconSettings } from 'src/components/icons/settings/IconSettings';
import Table from 'src/components/table/Table';
import ConfigurationDrawer from 'src/components/table/configuration-drawer/ConfigurationDrawer';
import TableHeader from 'src/components/table/header/TableHeader';
import { NO_DATA_GENERATED, NO_RESULTS } from 'src/constants/validation';
import { useMeasurementsContext } from 'src/contexts/measurements-context';
import useInsightsList from 'src/hooks/use-insights-list';
import useInsightsNotification from 'src/hooks/use-insights-notification';
import { TMappedSustainabilityReportRecord } from 'src/utils/map-sustainability-report';
import { setInitialColumns } from 'src/utils/table-configuration';
import styles from './InsightsList.module.scss';

const InsightsList = () => {
  const { getIndicators, loading: indicatorsLoading } =
    useMeasurementsContext();

  const {
    setSearch,
    columns,
    handleTableChange,
    loading,
    width,
    data,
    total,
    debouncedSearch,
    search,
    setSelectedColumns,
    open,
    setOpen,
    exportSustainabilityReport,
    current,
  } = useInsightsList();

  useEffect(() => {
    getIndicators();
  }, []);

  const { isDisabled, setAlert, checked, setChecked, alertHeight } =
    useInsightsNotification();
  return (
    <div>
      <TableHeader
        search={search}
        title={'Sustainability report'}
        bold={false}
        searchPlaceholder={'Product name, number, code, order #'}
        setSearch={setSearch}
        customActionTitle={'Export to .xlsx'}
        customActionIcon={<IconDownload width="16" height="16" />}
        cusotmAction={exportSustainabilityReport}
        disabled={indicatorsLoading}
        noActions
        showSettings
        openSettings={() => {
          setOpen(true);
        }}
      />
      {!isDisabled && (
        <Alert
          className={styles.alert}
          onClose={() => setAlert(false)}
          closable
          message={
            <>
              Configure
              <IconSettings className={styles.icon} height="16" width="16" />
              the table with desired environmental indicators.
            </>
          }
          showIcon
          icon={<IconInfo height="16" width="16" />}
          action={
            <Checkbox
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
            >
              Don't show this again
            </Checkbox>
          }
          type="info"
        />
      )}
      <Table<TMappedSustainabilityReportRecord>
        emptyTextDescription={debouncedSearch ? NO_RESULTS : NO_DATA_GENERATED}
        columns={columns}
        dataSource={data}
        onChange={handleTableChange}
        loading={loading}
        width={width}
        height={`calc(100vh - ${266 + alertHeight}px)`}
        total={total}
        current={current}
      />
      <ConfigurationDrawer
        open={open}
        onClose={() => setOpen(false)}
        onSave={(value) => {
          setInitialColumns(value);
          setSelectedColumns(value);
        }}
      />
    </div>
  );
};

export default InsightsList;
