import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
  type FunctionComponent,
} from 'react';
import { useLocation } from 'react-router-dom';

export type TNavigationContextValue = {
  title: (string | null)[];
  setTitle: (text: (string | null)[]) => void;
};
const messageContexInitialValue: TNavigationContextValue = {
  title: [],
  setTitle: () => {},
};

const NavigationContext = createContext<TNavigationContextValue>(
  messageContexInitialValue
);

type NavigationContextProviderProps = PropsWithChildren;

export const NavigationContextProvider: FunctionComponent<
  NavigationContextProviderProps
> = ({ children }): JSX.Element => {
  const location = useLocation();
  const [title, setTitle] = useState<(string | null)[]>([]);
  useEffect(() => {
    setTitle([]);
  }, [location]);

  return (
    <NavigationContext.Provider value={{ setTitle, title }}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigationContext = () => {
  const context = useContext(NavigationContext);

  if (!context) {
    throw new Error(
      '"NavigationContext" context is not in scope. Use this hook in a component that is wrapped with the <NavigationContextProvider /> component.'
    );
  }

  return context;
};
