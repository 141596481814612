import Highlight from 'react-highlight-words';

type THighlighterProps = {
  value: string | null;
  search: string;
  className?: string;
};

const Highlighter = ({ value, search, className }: THighlighterProps) => {
  return (
    <Highlight
      className={className}
      searchWords={[search]}
      autoEscape
      textToHighlight={value || ''}
      highlightStyle={{
        backgroundColor: 'transparent',
        color: '#cdab04',
        fontWeight: 800,
      }}
    />
  );
};

export default Highlighter;
