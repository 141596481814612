import { Button, DatePicker } from 'antd';
import { FilterDropdownProps } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import { IconDate } from 'src/components/icons/date/IconDate';
import styles from './TableDateFilters.module.scss';
import { useEffect, useState } from 'react';

const TableDateFilters = ({
  selectedKeys,
  setSelectedKeys,
  clearFilters,
  confirm,
}: FilterDropdownProps) => {
  const [value, setValue] = useState<[string, string] | null>(null);

  useEffect(() => {
    setValue(selectedKeys.length ? (selectedKeys as [string, string]) : null);
  }, [selectedKeys]);
  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <DatePicker.RangePicker
          separator="–"
          format={'DD/MM/YYYY'}
          value={
            value?.filter((el) => el).map((el) => dayjs(el, 'DD/MM/YYYY')) as [
              dayjs.Dayjs,
              dayjs.Dayjs,
            ]
          }
          placeholder={['From', 'To']}
          onChange={(_, string) => {
            if (string?.filter((el) => el).length) {
              setValue(string);
            } else {
              setValue(null);
            }
          }}
          suffixIcon={<IconDate width="16" height="16" />}
        />
      </div>
      <div className={styles.footer}>
        <Button
          size="small"
          type="text"
          disabled={!selectedKeys.length}
          onClick={clearFilters}
        >
          Reset
        </Button>
        <Button
          size="small"
          type="primary"
          onClick={() => {
            if (value) {
              setSelectedKeys(value);
            } else {
              clearFilters?.();
            }
            confirm({ closeDropdown: true });
          }}
        >
          OK
        </Button>
      </div>
    </div>
  );
};

export default TableDateFilters;
