import { Typography } from 'antd';
import { IconOpen } from 'src/components/icons/open/IconOpen';
import styles from './Url.module.scss';
import { PropsWithChildren } from 'react';

const { Link } = Typography;

type TUrlProps = PropsWithChildren<{
  value: string;
  iconShown?: boolean;
  openTab?: boolean;
}>;

const Url = ({
  value,
  children,
  iconShown = true,
  openTab = true,
}: TUrlProps) => {
  return (
    <div className={styles.component}>
      <Link
        onClick={(e) => e.stopPropagation()}
        className={styles.text}
        href={value}
        target={openTab ? '_blank' : undefined}
      >
        {children || value}
      </Link>
      {iconShown && <IconOpen width="16" height="16" className={styles.icon} />}
    </div>
  );
};

export default Url;
