import { TableProps } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { SortOrder } from 'src/components/icons/sort/IconSort';
import { BROADCAST_NAME } from 'src/constants/local-storage';
import { useMessageContext } from 'src/contexts/message-context';
import { useModalContext } from 'src/contexts/modal-context';
import { useNavigationContext } from 'src/contexts/navigation-context';
import { useUserContext } from 'src/contexts/user-context';
import { IProduct, IProductOfferDetails } from 'src/types/general';
import { ITableParams } from 'src/types/generic';
import {
  getMappedProductDetailsData,
  getProductDetailsColumnSetting,
} from 'src/utils/map-product-details-table';
import {
  TMappedPurchaseHistoryRecord,
  getMappedPurchaseHistoryData,
  getPurchaseHistoryColumnSetting,
} from 'src/utils/map-purchase-history-table';
import useProductOfferTableForm from './use-product-offer-table-form';
import useProducts from './use-products';
import useTableWidth from './use-table-width';
import { useMeasurementsContext } from 'src/contexts/measurements-context';

const useProductDetails = () => {
  const { product_id } = useParams();
  const [searchParams] = useSearchParams();

  const isSupplier = searchParams.get('isSupplier') === 'true';

  const productId = product_id || '';

  const {
    getProduct,
    editAssignedCustomer,
    getProductCustomers,
    getProductOfferDetails,
    getProductOfferPurchaseHistory,
  } = useProducts();

  const { getAllMeasurements, loading: measurementsLoading } =
    useMeasurementsContext();

  const { showErrorMessage, showSuccessMessage } = useMessageContext();

  const { modalContextDispatch } = useModalContext();

  const { setTitle } = useNavigationContext();

  const {
    userContextState: { isAdmin, isAdvancedUser },
  } = useUserContext();

  const basicUser = !isAdmin && !isAdvancedUser;

  const {
    form,
    data,
    setEditRow,
    unsetEditRow,
    isEditing,
    uoms,
    currencies,
    setData,
  } = useProductOfferTableForm();

  const [purchasedData, setPurchasedData] = useState<
    TMappedPurchaseHistoryRecord[]
  >([]);
  const [product, setProduct] = useState<IProduct | null>(null);
  const [productOffer, setProductOffer] = useState<IProductOfferDetails | null>(
    null
  );
  const [total, setTotal] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [tableParams, setTableParams] = useState<ITableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sortField: 'createdAt',
    sortOrder: SortOrder.DESC,
  });

  const columns = useMemo(() => {
    const res = getProductDetailsColumnSetting({
      modalDispatch: modalContextDispatch,
      setEditRow,
      setLoading,
      unsetEditRow,
      edit: editAssignedCustomer,
      productId,
      uoms,
      currencies,
      actionsHidden: basicUser || !total,
      isEditing,
      form,
      showSuccessMessage,
      showErrorMessage,
    });
    return res;
  }, [tableParams, uoms, currencies, isEditing, basicUser, total]);

  const purchasedColumns = useMemo(() => {
    const res = getPurchaseHistoryColumnSetting({
      uoms,
      currencies,
    });
    return res;
  }, [tableParams, uoms, currencies]);

  const fetchCustomers = async () => {
    setLoading(true);
    const customersResult = await getProductCustomers(productId, tableParams);
    const customersResponse = customersResult.result?.data;

    if (customersResponse) {
      setData(getMappedProductDetailsData(customersResponse.data));
      setTotal(customersResponse.totalCount);
    }
    const productResult = await getProduct(productId);
    const productResponse = productResult.result?.data;

    if (productResponse) {
      setProduct(productResponse);
      setTitle([productResponse.name]);
    }
    setLoading(false);
  };

  const fetchPurchaseHistory = async () => {
    setLoading(true);
    const productsResult = await getProductOfferPurchaseHistory(
      productId,
      tableParams
    );
    const productsResponse = productsResult.result?.data;

    if (productsResponse) {
      setPurchasedData(getMappedPurchaseHistoryData(productsResponse.data));
      setTotal(productsResponse.totalCount);
    }
    const productResult = await getProductOfferDetails(productId);
    const productResponse = productResult.result?.data;

    if (productResponse) {
      setProductOffer(productResponse);
      setTitle([productResponse.product.name]);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (isSupplier) {
      fetchPurchaseHistory();
      return;
    }
    fetchCustomers();
    const broadcast = new BroadcastChannel(BROADCAST_NAME);
    broadcast.onmessage = fetchCustomers;
    return () => {
      broadcast.close();
    };
  }, [tableParams]);

  useEffect(() => {
    getAllMeasurements();
  }, []);

  const handleTableChange: TableProps<any>['onChange'] = (
    pagination,
    filters,
    sorter
  ) => {
    setTableParams({
      pagination,
      filters,
      sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
      sortField: Array.isArray(sorter)
        ? undefined
        : sorter.order
          ? sorter.field
          : undefined,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const tableWidth = useTableWidth(columns);

  return {
    handleTableChange,
    loading: loading || measurementsLoading,
    columns,
    width: tableWidth,
    data,
    total,
    product,
    form,
    open,
    currencies,
    productId,
    setOpen,
    purchasedColumns,
    purchasedData,
    productOffer,
    isSupplier,
  };
};

export default useProductDetails;
