import { Select as AntSelect, SelectProps } from 'antd';
import styles from 'src/styles/actions.module.scss';
import { IconArrowDown } from '../icons/arrow-down/IconArrowDown';

const Select = (props: SelectProps) => {
  return (
    <AntSelect
      {...props}
      suffixIcon={<IconArrowDown height="16" width="16" fill={styles.iconFill} />}
    />
  );
};

export default Select;
