export const getErrorText = (obj: unknown) => {
  let str: string[] = [];
  if (typeof obj !== 'object') {
    return str;
  }

  for (let i in obj) {
    const error = obj[i as keyof typeof obj];
    if (typeof error === 'object') {
      str = [...str, ...getErrorText(error)];
    } else {
      str.push(error);
    }
  }
  return str;
};
