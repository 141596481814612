import { PropsWithChildren } from 'react';
import styles from './DashboardSection.module.scss';
import { Typography } from 'antd';

type TDashboardSectionProps = PropsWithChildren<{
  title: string;
}>;

const DashboardSection = ({ title, children }: TDashboardSectionProps) => {
  return (
    <div className={styles.component}>
      <Typography.Text className={styles.text}>{title}</Typography.Text>
      <div className={styles.container}>{children}</div>
    </div>
  );
};

export default DashboardSection;
