import { Checkbox, Empty, Typography } from 'antd';
import styles from './CheckboxGroup.module.scss';
import { StaticReportColumns } from 'src/constants/sustainability-report';
import { NO_RESULTS } from 'src/constants/validation';
import Highlighter from '../../highlighter/Highlighter';

export type TCheckboxOption = {
  label: string | null;
  columns: Array<StaticReportColumns | string>;
  value: StaticReportColumns | string;
  disabled: boolean;
};

type TCheckboxGroupProps = {
  value: string[];
  label: string;
  options: TCheckboxOption[];
  setSelected: React.Dispatch<React.SetStateAction<boolean[]>>;
  search: string;
};

const CheckboxGroup = ({
  value,
  label,
  options,
  setSelected,
  search,
}: TCheckboxGroupProps) => {
  const checkboxes = options
    .map((option, i) => ({
      label: option.label,
      children: (
        <Checkbox
          onChange={(e) => {
            setSelected((list) => {
              list[i] = e.target.checked;
              return [...list];
            });
          }}
          disabled={option.disabled}
          key={option.value}
          value={option.value}
          className={styles.checkbox}
        >
          <Highlighter search={search} value={option.label || ''} />
        </Checkbox>
      ),
    }))
    .filter(
      (el) =>
        search.length < 3 ||
        el.label?.toLowerCase().includes(search.toLowerCase())
    )
    .map((el) => el.children);
  return (
    <div className={styles.container}>
      <Typography.Text className={styles.label}>{label}</Typography.Text>

      {checkboxes.length ? (
        <Checkbox.Group className={styles.group} value={value}>
          {checkboxes}
        </Checkbox.Group>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={NO_RESULTS} />
      )}
    </div>
  );
};

export default CheckboxGroup;
