import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { THiveColumn } from 'src/components/hexagon/container/HiveContainer';
import { IconAcute } from 'src/components/icons/acute/IconAcute';
import { IconLink } from 'src/components/icons/link/IconLink';
import { IconUnlink } from 'src/components/icons/unlink/IconUnlink';
import styles from 'src/styles/actions.module.scss';
import { ICustomerMetric, ISupplierMetric } from 'src/types/general';
import { ProtectedRoutes } from 'src/constants/routes';
import useMetrics from './use-metrics';

const useHiveMap = () => {
  const { getCustomerMetrics, getSupplierMetrics } = useMetrics();

  const navigate = useNavigate();

  const navigateToSuppliers = () => {
    navigate(ProtectedRoutes.SUPPLIERS);
  };

  const navigateToCustomers = () => {
    navigate(ProtectedRoutes.CUSTOMERS);
  };

  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState<ICustomerMetric | null>(null);
  const [supplier, setSupplier] = useState<ISupplierMetric | null>(null);

  useEffect(() => {
    const fetchMetrics = async () => {
      setLoading(true);
      const customer = await getCustomerMetrics();
      const customerResponse = customer.result?.data;
      if (customerResponse) {
        setCustomer(customerResponse);
      }
      const supplier = await getSupplierMetrics();
      const supplierResponse = supplier.result?.data;
      if (supplierResponse) {
        setSupplier(supplierResponse);
      }
      setLoading(false);
    };
    fetchMetrics();
  }, []);

  const supplierColumns: THiveColumn[] = useMemo(
    () => [
      {
        items: [
          {
            type: 'amount',
            data: {
              icon: (
                <IconUnlink height="16" width="16" color={styles.iconFill} />
              ),
              title: 'Decoupled',
              value: supplier?.decoupled || 0,
              type: 'danger',
              exceptionCount: undefined,
            },
          },
          {
            type: 'amount',
            data: {
              icon: (
                <IconAcute height="16" width="16" color={styles.iconFill} />
              ),
              title: 'Pending',
              value: supplier?.pending || 0,
              type: 'disabled',
            },
          },
        ],
      },
      {
        items: [
          {
            type: 'amount',
            data: {
              icon: <IconLink height="16" width="16" color={styles.iconFill} />,
              title: 'Coupled',
              value: supplier?.coupled || 0,
              exceptionCount: 30,
            },
          },
        ],
      },
      {
        items: [
          {
            type: 'blank',
          },
          {
            type: 'supplier-logo',
          },
        ],
      },
    ],
    [supplier]
  );

  const customerColumns: THiveColumn[] = useMemo(
    () => [
      {
        items: [
          {
            type: 'blank',
          },
          {
            type: 'customer-logo',
          },
        ],
      },
      {
        items: [
          {
            type: 'amount',
            data: {
              icon: <IconLink height="16" width="16" color={styles.iconFill} />,
              title: 'Coupled',
              value: customer?.coupled || 0,
              exceptionCount: 12,
            },
          },
        ],
      },

      {
        items: [
          {
            type: 'amount',
            data: {
              icon: (
                <IconUnlink height="16" width="16" color={styles.iconFill} />
              ),
              title: 'Decoupled',
              value: customer?.decoupled || 0,
              type: 'danger',
              exceptionCount: undefined,
            },
          },
          {
            type: 'amount',
            data: {
              icon: (
                <IconAcute height="16" width="16" color={styles.iconFill} />
              ),
              title: 'Pending',
              value: customer?.pending || 0,
              type: 'disabled',
            },
          },
        ],
      },
    ],
    [customer]
  );

  return {
    loading,
    supplierColumns,
    customerColumns,
    navigateToCustomers,
    navigateToSuppliers,
  };
};

export default useHiveMap;
