import { IconDownload } from 'src/components/icons/download/IconDownload';
import Table from 'src/components/table/Table';
import TableHeader from 'src/components/table/header/TableHeader';
import { NO_PRODUCTS_ASSIGNED, NO_RESULTS } from 'src/constants/validation';
import useSupplierProductsTable from 'src/hooks/use-suppliers-products-table';
import { TMappedProductOfferRecord } from 'src/utils/map-assigned-product-table';
import styles from './SuppliersProductsTable.module.scss';

const SuppliersProductsTable = () => {
  const {
    setSearch,
    columns,
    handleTableChange,
    loading,
    width,
    data,
    total,
    debouncedSearch,
    openRead,
    search,
    current,
  } = useSupplierProductsTable();

  return (
    <>
      <TableHeader
        searchPlaceholder={'Product name, number, code'}
        setSearch={setSearch}
        search={search}
        noActions={true}
        customActionTitle={'Export to .xlsx'}
        customActionIcon={<IconDownload width="16" height="16" />}
      />
      <Table<TMappedProductOfferRecord>
        emptyTextDescription={
          debouncedSearch ? NO_RESULTS : NO_PRODUCTS_ASSIGNED
        }
        columns={columns}
        dataSource={data}
        onChange={handleTableChange}
        rowClassName={() => {
          return styles.interactive;
        }}
        onRow={(record) => ({
          onClick: () => {
            openRead(record);
          },
        })}
        loading={loading}
        width={width}
        height={'calc(100vh - 326px)'}
        total={total}
        current={current}
      />
    </>
  );
};

export default SuppliersProductsTable;
