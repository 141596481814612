import { Button, Typography } from 'antd';
import ModalWrapper from '../modal-wrapper/ModalWrapper';
import styles from './Modal.module.scss';
import { TModal } from 'src/contexts/modal-context';

type TModalProps = {
  modal: TModal | null;
  primaryAction: () => void;
  primaryIsLoading: boolean;
  secondaryActionTitle?: string;
  secondaryAction: () => void;
};

const { Text } = Typography;

const Modal = ({
  modal,
  primaryAction,
  primaryIsLoading,
  secondaryAction,
  secondaryActionTitle,
}: TModalProps) => {
  return (
    <ModalWrapper
      visible={!!modal}
      title={modal?.title || ''}
      close={secondaryAction}
    >
      <div className={styles.body}>
        <Text className={styles.description}>{modal?.description || ''}</Text>
        <div className={styles.buttons}>
          <Button onClick={secondaryAction}>{secondaryActionTitle}</Button>
          <Button
            loading={primaryIsLoading}
            type="primary"
            danger={modal?.danger}
            onClick={primaryAction}
          >
            {modal?.actionText}
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default Modal;
