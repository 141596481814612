import { Typography } from 'antd';
import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import styles from './DetailsItem.module.scss';

type TDetailsItemProps = PropsWithChildren<{
  className?: string;
  label: string;
}>;

const DetailsItem = ({ className, children, label }: TDetailsItemProps) => {
  return (
    <div className={clsx(styles.component, className)}>
      <Typography.Text className={styles.label}>{`${label}:`}</Typography.Text>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default DetailsItem;
