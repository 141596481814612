import clsx from 'clsx';
import { type FunctionComponent, type SVGProps } from 'react';

import styles from './IconFilter.module.scss';
import { TIconProps } from 'src/types/icons';

type IconFilterProps = Omit<TIconProps, 'iconId'> & SVGProps<SVGSVGElement>;

export const IconFilter: FunctionComponent<IconFilterProps> = ({
  className,
  width = 24,
  height = 24,
  fill,
  ...props
}): JSX.Element => {
  return (
    <svg
      className={clsx(className, styles.component)}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-element="icon"
      {...props}
    >
      <path
        d="M11 20C10.7166 20 10.4791 19.9042 10.2875 19.7125C10.0958 19.5208 9.99996 19.2833 9.99996 19V13L4.19996 5.6C3.94996 5.26667 3.91246 4.91667 4.08746 4.55C4.26246 4.18333 4.56662 4 4.99996 4H19C19.4333 4 19.7375 4.18333 19.9125 4.55C20.0875 4.91667 20.05 5.26667 19.8 5.6L14 13V19C14 19.2833 13.9041 19.5208 13.7125 19.7125C13.5208 19.9042 13.2833 20 13 20H11Z"
        fill={fill || 'currentColor'}
      />
    </svg>
  );
};
