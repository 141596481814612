import clsx from 'clsx';
import { type FunctionComponent, type SVGProps } from 'react';

import { Tooltip } from 'antd';
import { HiveStatus } from 'src/constants/hive-map';
import { TIconProps } from 'src/types/icons';
import styles from './IconDisconnected.module.scss';

type IconDisconnectedProps = Omit<TIconProps, 'iconId'> &
  SVGProps<SVGSVGElement>;

export const IconDisconnected: FunctionComponent<IconDisconnectedProps> = ({
  className,
  width = 24,
  height = 24,
  ...props
}): JSX.Element => {
  return (
    <Tooltip title={HiveStatus.DISCONNECTED}>
      <svg
        className={clsx(className, styles.component)}
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-element="icon"
        {...props}
      >
        <rect x="0.5" y="0.5" width="23" height="23" rx="7.5" fill="#FFF2F0" />
        <rect
          x="0.5"
          y="0.5"
          width="23"
          height="23"
          rx="7.5"
          stroke="#FFCCC7"
        />

        <path
          d="M11.9966 15.2C12.1656 15.2 12.3084 15.1428 12.4251 15.0285C12.5418 14.9141 12.6001 14.7725 12.6001 14.6035C12.6001 14.4345 12.5429 14.2916 12.4286 14.175C12.3143 14.0583 12.1726 14 12.0036 14C11.8346 14 11.6918 14.0571 11.5751 14.1715C11.4584 14.2858 11.4001 14.4275 11.4001 14.5965C11.4001 14.7655 11.4573 14.9083 11.5716 15.025C11.6859 15.1416 11.8276 15.2 11.9966 15.2ZM11.9966 12.8C12.1656 12.8 12.3084 12.7425 12.4251 12.6275C12.5418 12.5125 12.6001 12.37 12.6001 12.2V9.39998C12.6001 9.22998 12.5429 9.08748 12.4286 8.97248C12.3143 8.85748 12.1726 8.79998 12.0036 8.79998C11.8346 8.79998 11.6918 8.85748 11.5751 8.97248C11.4584 9.08748 11.4001 9.22998 11.4001 9.39998V12.2C11.4001 12.37 11.4573 12.5125 11.5716 12.6275C11.6859 12.7425 11.8276 12.8 11.9966 12.8ZM12.0048 18.4C11.1239 18.4 10.2945 18.2333 9.51676 17.9C8.73899 17.5666 8.05843 17.1083 7.4751 16.525C6.89176 15.9416 6.43343 15.2613 6.1001 14.484C5.76676 13.7066 5.6001 12.8761 5.6001 11.9923C5.6001 11.1085 5.76676 10.2805 6.1001 9.50831C6.43343 8.73609 6.89176 8.05831 7.4751 7.47498C8.05843 6.89164 8.73876 6.43331 9.5161 6.09998C10.2934 5.76664 11.124 5.59998 12.0078 5.59998C12.8915 5.59998 13.7195 5.76664 14.4918 6.09998C15.264 6.43331 15.9418 6.89164 16.5251 7.47498C17.1084 8.05831 17.5668 8.73731 17.9001 9.51198C18.2334 10.2868 18.4001 11.1145 18.4001 11.9953C18.4001 12.8762 18.2334 13.7055 17.9001 14.4833C17.5668 15.2611 17.1084 15.9416 16.5251 16.525C15.9418 17.1083 15.2628 17.5666 14.4881 17.9C13.7133 18.2333 12.8855 18.4 12.0048 18.4Z"
          fill="#FF4D4F"
        />
      </svg>
    </Tooltip>
  );
};
