import { TableProps } from 'antd';
import { SortOrder } from 'antd/es/table/interface';
import { useEffect, useState } from 'react';
import { BROADCAST_NAME } from 'src/constants/local-storage';
import { ITableParamsWithSearch } from 'src/types/generic';
import useDebouncer from './use-debouncer';

type TUseTableSettingsParams = {
  sortField: string;
  sortOrder: SortOrder;
  fetcher: (tableParams: ITableParamsWithSearch) => void;
};

const useTableSettings = ({
  sortField,
  sortOrder,
  fetcher,
}: TUseTableSettingsParams) => {
  const [search, setSearch] = useState<string>('');
  const [tableParams, setTableParams] = useState<ITableParamsWithSearch>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sortField,
    sortOrder,
    search: '',
  });

  // Note: debounces search value
  useDebouncer((value) => {
    setTableParams((params) => ({
      ...params,
      pagination: {
        current: value.length >= 3 ? 1 : params.pagination?.current,
        pageSize: params.pagination?.pageSize,
      },
      search: value,
    }));
  }, search || '');

  const fetchData = () => {
    fetcher(tableParams);
  };

  useEffect(() => {
    if (tableParams.search.length >= 3 || !tableParams.search) {
      fetchData();
    }
    const broadcast = new BroadcastChannel(BROADCAST_NAME);
    broadcast.onmessage = fetchData;
    return () => {
      broadcast.close();
    };
  }, [JSON.stringify(tableParams)]);

  const handleTableChange: TableProps<any>['onChange'] = (
    pagination,
    filters,
    sorter
  ) => {
    setTableParams((params) => ({
      pagination,
      filters,
      sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
      sortField: Array.isArray(sorter)
        ? undefined
        : sorter.order
          ? sorter.field
          : undefined,

      search: params.search,
    }));

    //   // `dataSource` is useless since `pageSize` changed
    //   if (pagination.pageSize !== tableParams.pagination?.pageSize) {
    //     setData([]);
    //   }
  };

  return {
    search,
    setSearch,
    tableParams,
    handleTableChange,
  };
};

export default useTableSettings;
