import clsx from 'clsx';
import { type FunctionComponent, type SVGProps } from 'react';

import styles from './IconCheckOutlined.module.scss';
import { TIconProps } from 'src/types/icons';

type IconCheckOutlinedProps = Omit<TIconProps, 'iconId'> & SVGProps<SVGSVGElement>;

export const IconCheckOutlined: FunctionComponent<IconCheckOutlinedProps> = ({
  className,
  width = 24,
  height = 24,
  fill,
  ...props
}): JSX.Element => {
  return (
    <svg
      className={clsx(className, styles.component)}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-element="icon"
      {...props}
    >
      <path
        d="M10.725 13.05L9.22502 11.575C9.04169 11.3917 8.83336 11.3 8.60003 11.3C8.36669 11.3 8.15836 11.3917 7.97502 11.575C7.79169 11.7584 7.70003 11.9709 7.70003 12.2125C7.70003 12.4542 7.79169 12.6667 7.97502 12.85L10.1 14.975C10.2834 15.1584 10.4917 15.25 10.725 15.25C10.9584 15.25 11.1667 15.1584 11.35 14.975L16.025 10.3C16.2084 10.1167 16.3 9.90419 16.3 9.66252C16.3 9.42086 16.2084 9.20836 16.025 9.02502C15.8417 8.84169 15.6334 8.75003 15.4 8.75003C15.1667 8.75003 14.9584 8.84169 14.775 9.02502L10.725 13.05ZM12 21.6C10.6834 21.6 9.44169 21.35 8.27502 20.85C7.10836 20.35 6.08752 19.6625 5.21252 18.7875C4.33752 17.9125 3.65002 16.8917 3.15002 15.725C2.65002 14.5584 2.40002 13.3167 2.40002 12C2.40002 10.6667 2.65002 9.42086 3.15002 8.26253C3.65002 7.10419 4.33752 6.08752 5.21252 5.21252C6.08752 4.33752 7.10836 3.65002 8.27502 3.15002C9.44169 2.65002 10.6834 2.40002 12 2.40002C13.3334 2.40002 14.5792 2.65002 15.7375 3.15002C16.8959 3.65002 17.9125 4.33752 18.7875 5.21252C19.6625 6.08752 20.35 7.10419 20.85 8.26253C21.35 9.42086 21.6 10.6667 21.6 12C21.6 13.3167 21.35 14.5584 20.85 15.725C20.35 16.8917 19.6625 17.9125 18.7875 18.7875C17.9125 19.6625 16.8959 20.35 15.7375 20.85C14.5792 21.35 13.3334 21.6 12 21.6Z"
        fill={fill || 'currentColor'}
      />
    </svg>
  );
};
