import clsx from 'clsx';
import { type FunctionComponent, type SVGProps } from 'react';

import styles from './IconDelete.module.scss';
import { TIconProps } from 'src/types/icons';

type IconDeleteProps = Omit<TIconProps, 'iconId'> & SVGProps<SVGSVGElement>;

export const IconDelete: FunctionComponent<IconDeleteProps> = ({
  className,
  width = 24,
  height = 24,
  fill,
  ...props
}): JSX.Element => {
  return (
    <svg
      className={clsx(className, styles.component)}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-element="icon"
      {...props}
    >
      <path
        d="M7.79999 20.4C7.30499 20.4 6.88124 20.2237 6.52874 19.8712C6.17624 19.5187 5.99999 19.095 5.99999 18.6V6.59998H5.69999C5.44499 6.59998 5.23124 6.51423 5.05874 6.34273C4.88624 6.17123 4.79999 5.95873 4.79999 5.70523C4.79999 5.45173 4.88624 5.23748 5.05874 5.06248C5.23124 4.88748 5.44499 4.79998 5.69999 4.79998H9.59999V4.49998C9.59999 4.24498 9.68624 4.03122 9.85874 3.85872C10.0312 3.68622 10.245 3.59998 10.5 3.59998H13.5C13.755 3.59998 13.9687 3.68622 14.1412 3.85872C14.3137 4.03122 14.4 4.24498 14.4 4.49998V4.79998H18.3C18.555 4.79998 18.7687 4.88572 18.9412 5.05722C19.1137 5.22872 19.2 5.44122 19.2 5.69472C19.2 5.94823 19.1137 6.16248 18.9412 6.33748C18.7687 6.51248 18.555 6.59998 18.3 6.59998H18V18.5892C18 19.0964 17.8237 19.525 17.4712 19.875C17.1187 20.225 16.695 20.4 16.2 20.4H7.79999ZM16.2 6.59998H7.79999V18.6H16.2V6.59998ZM10.4947 16.8C10.7482 16.8 10.9625 16.7137 11.1375 16.5412C11.3125 16.3687 11.4 16.155 11.4 15.9V9.29998C11.4 9.04498 11.3142 8.83122 11.1427 8.65873C10.9712 8.48623 10.7587 8.39998 10.5052 8.39998C10.2517 8.39998 10.0375 8.48623 9.86249 8.65873C9.68749 8.83122 9.59999 9.04498 9.59999 9.29998V15.9C9.59999 16.155 9.68574 16.3687 9.85724 16.5412C10.0287 16.7137 10.2412 16.8 10.4947 16.8ZM13.4947 16.8C13.7482 16.8 13.9625 16.7137 14.1375 16.5412C14.3125 16.3687 14.4 16.155 14.4 15.9V9.29998C14.4 9.04498 14.3142 8.83122 14.1427 8.65873C13.9712 8.48623 13.7587 8.39998 13.5052 8.39998C13.2517 8.39998 13.0375 8.48623 12.8625 8.65873C12.6875 8.83122 12.6 9.04498 12.6 9.29998V15.9C12.6 16.155 12.6857 16.3687 12.8572 16.5412C13.0287 16.7137 13.2412 16.8 13.4947 16.8Z"
        fill={fill || 'currentColor'}
      />
    </svg>
  );
};
