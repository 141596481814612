import { useEffect, useMemo, useState } from 'react';
import { TCheckboxOption } from 'src/components/table/configuration-drawer/checkbox-group/CheckboxGroup';
import {
  StaticReportColumns,
  defaultReportColumns,
  reportsColumnLabel,
} from 'src/constants/sustainability-report';
import { TReportColumns } from 'src/utils/table-configuration';

const useProductCheckboxes = (initialProductColumns: TReportColumns) => {
  const columnsOptions = useMemo(() => {
    return defaultReportColumns
      .map((column) => {
        if (column === StaticReportColumns.INVOICE_UOM) {
          return null;
        }
        if (column === StaticReportColumns.INVOICE_QUANTITY) {
          return {
            label: `${reportsColumnLabel[StaticReportColumns.INVOICE_QUANTITY]}, ${reportsColumnLabel[StaticReportColumns.INVOICE_UOM]}`,
            columns: [
              StaticReportColumns.INVOICE_QUANTITY,
              StaticReportColumns.INVOICE_UOM,
            ],
            value: column,
            disabled: false,
          };
        }
        return {
          label: reportsColumnLabel[column],
          columns: [column],
          value: column,
          disabled: column === StaticReportColumns.PRODUCT_NAME,
        };
      })
      .filter((el) => el) as TCheckboxOption[];
  }, []);

  const initialColumns = useMemo(
    () =>
      columnsOptions
        .filter((columnsOption) =>
          initialProductColumns.some((column) => column === columnsOption.value)
        )
        .map((el) => el.value),
    [columnsOptions, initialProductColumns]
  );

  const initialSelectedColumns = useMemo(
    () =>
      columnsOptions.map((columnsOption) =>
        initialProductColumns.some((column) => column === columnsOption.value)
      ),
    [initialProductColumns, columnsOptions]
  );

  const [selectedColumn, setSelectedColumn] = useState<boolean[]>([]);

  const [columns, setColumns] = useState<string[]>([]);

  useEffect(() => {
    setColumns(initialColumns);
  }, [initialColumns]);

  useEffect(() => {
    setSelectedColumn(initialSelectedColumns);
  }, [initialSelectedColumns]);

  useEffect(() => {
    if (!selectedColumn.length) {
      return;
    }
    const res = columnsOptions.reduce((acc, option, i) => {
      if (selectedColumn[i]) {
        return [...acc, ...option.columns];
      }
      return acc;
    }, [] as string[]);
    setColumns(res);
  }, [selectedColumn]);

  const allColumnsChecked = columnsOptions.map(() => true);
  const allColumnsUnchecked = columnsOptions.map((_, i) => i === 0);
  const isAllCallumnsChecked =
    allColumnsChecked.length === selectedColumn.filter((value) => value).length;

  return {
    columnsOptions,
    columns,
    setSelectedColumn,
    allColumnsChecked,
    allColumnsUnchecked,
    isAllCallumnsChecked,
  };
};

export default useProductCheckboxes;
