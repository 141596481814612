import { Breadcrumb, Layout, Typography, theme } from 'antd';
import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import useNavigationHeader from 'src/hooks/use-navigation-header';
import { IconInventory } from '../icons/inventory/IconInventory';
import styles from './Layout.module.scss';
import NavigationBar from './sider/NavigationBar';

const { Header, Content } = Layout;
const { Title } = Typography;

export type TGeneralLayoutProps = PropsWithChildren<{ withContent?: boolean }>;

const GeneralLayout = ({
  children,
  withContent = true,
}: TGeneralLayoutProps) => {
  const {
    token: { colorBgContainer, borderRadiusLG, marginLG, paddingLG },
  } = theme.useToken();

  const { icon, title, locationBreadcrumb, getLinkTo, items } =
    useNavigationHeader();

  return (
    <Layout className={styles.container}>
      <NavigationBar />
      <Layout>
        <Header
          className={styles.titleContainer}
          style={{ background: colorBgContainer }}
        >
          {locationBreadcrumb.length < 2 ? (
            <Title className={styles.title} level={5}>
              {title}
            </Title>
          ) : (
            <Breadcrumb
              itemRender={(route, _, routes, __) => {
                const index = routes.indexOf(route);

                if (routes.length !== index + 1) {
                  return (
                    <Link
                      className={styles.itemContainer}
                      to={getLinkTo(index)}
                    >
                      {!!index && icon}
                      {route.title}
                    </Link>
                  );
                }

                return (
                  <div className={styles.itemContainer}>
                    {index === 2 ? (
                      <IconInventory height="16" width="16" />
                    ) : (
                      icon
                    )}
                    <Title className={styles.item} level={5}>
                      {route.title}
                    </Title>
                  </div>
                );
              }}
              items={items}
            />
          )}
        </Header>
        {withContent ? (
          <Content
            style={{
              margin: marginLG,
              marginBottom: 0,
              padding: paddingLG,
              paddingBottom: 0,
              background: colorBgContainer,
              borderTopLeftRadius: borderRadiusLG,
              borderTopRightRadius: borderRadiusLG,
            }}
          >
            {children}
          </Content>
        ) : (
          children
        )}
      </Layout>
    </Layout>
  );
};

export default GeneralLayout;
