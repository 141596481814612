import { Dropdown, Tag, Typography, theme } from 'antd';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import {
  UserStatus,
  modalAction,
  modalActionDescription,
  modalActionLabel,
  modalActionTitle,
  modalDanger,
  userStatusColor,
  userStatusLabel,
} from 'src/constants/users';
import { IStatusAction } from 'src/types/general';
import { IconArrowDown } from '../icons/arrow-down/IconArrowDown';
import styles from './StatusPicker.module.scss';
import { IconArrowRightAlt } from '../icons/arrow-right-alt/IconArrowRightAlt';
import {
  ModalContextActions,
  useModalContext,
} from 'src/contexts/modal-context';

type TStatusPickerProps = {
  status: UserStatus;
  statusActions: IStatusAction[];
  readonly: boolean;
  userId: string;
  fullName: string | null;
};

const StatusPicker = ({
  status,
  statusActions,
  readonly,
  userId,
  fullName,
}: TStatusPickerProps) => {
  const { modalContextDispatch } = useModalContext();

  const {
    token: {
      red1,
      red2,
      red3,
      red4,
      red6,
      red7,
      geekblue1,
      geekblue2,
      geekblue3,
      geekblue4,
      geekblue6,
      geekblue7,
      magenta1,
      magenta2,
      magenta3,
      magenta4,
      magenta6,
      magenta7,
    },
  } = theme.useToken();

  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(false);

  const color = useMemo(() => {
    const color = userStatusColor[status];
    switch (color) {
      case 'magenta':
        return {
          color: hover ? magenta7 : magenta6,
          bgColor: hover ? magenta2 : magenta1,
          borderColor: hover ? magenta4 : magenta3,
        };
      case 'red':
        return {
          color: hover ? red7 : red6,
          bgColor: hover ? red2 : red1,
          borderColor: hover ? red4 : red3,
        };
      case 'geekblue':
        return {
          color: hover ? geekblue7 : geekblue6,
          bgColor: hover ? geekblue2 : geekblue1,
          borderColor: hover ? geekblue4 : geekblue3,
        };
      default:
        return {
          color: styles.defaultColor,
          bgColor: hover ? styles.defaultActiveBg : styles.defaultBg,
          borderColor: styles.defaultBorderColor,
        };
    }
  }, [status, hover]);

  return (
    <Dropdown
      onOpenChange={(value) => setOpen(value)}
      menu={{
        onClick: ({ key }) => {
          const status = Number(key) as UserStatus;
          // Note: used for better transitions
          setTimeout(() => {
            modalContextDispatch({
              type: ModalContextActions.SHOW_MODAL,
              value: {
                title: modalActionTitle[status]!,
                description: (
                  <>
                    {fullName} {modalActionDescription[status]}
                  </>
                ),
                actionText: modalActionLabel[status]!,
                action: modalAction[status]!,
                dataId: userId,
                subDataId: `${status}`,
                danger: modalDanger[status],
              },
            });
          }, 200);
        },
        items: statusActions.map((el) => ({
          key: el.status,
          label: (
            <div className={styles.option}>
              <Typography.Text>{el.action}</Typography.Text>
              <IconArrowRightAlt
                fill={styles.iconColor}
                height="16"
                width="16"
              />
              <Tag color={userStatusColor[el.status]}>
                {userStatusLabel[el.status]}
              </Tag>
            </div>
          ),
        })),
      }}
      disabled={readonly}
      trigger={['click']}
    >
      <Tag
        className={clsx({ [styles.action]: !readonly })}
        style={{
          backgroundColor: color.bgColor,
          borderColor: color.borderColor,
          color: color.color,
        }}
        icon={
          !readonly ? (
            <IconArrowDown
              height="16"
              width="16"
              className={clsx(styles.icon, {
                [styles.rotate]: open,
              })}
            />
          ) : undefined
        }
        onMouseEnter={() => {
          if (readonly) {
            return;
          }
          setHover(true);
        }}
        onMouseLeave={() => setHover(false)}
        onClick={() => {
          if (readonly) {
            return;
          }
        }}
      >
        {userStatusLabel[status]}
      </Tag>
    </Dropdown>
  );
};

export default StatusPicker;
