import { HTTP, useNetworkContext } from 'src/contexts/network-context';
import {
  TGetCustomerMetrics,
  TGetDashboardMetrics,
  TGetSupplierMetrics,
} from 'src/types/backend';

const useMetrics = () => {
  const { fetch } = useNetworkContext();

  const getDashboardMetrics = async () => {
    const result = await fetch<TGetDashboardMetrics, unknown>({
      url: '/dashboard/all',
      method: HTTP.GET,
    });

    return result;
  };
  const getSupplierMetrics = async () => {
    const result = await fetch<TGetSupplierMetrics, unknown>({
      url: '/dashboard/suppliers/status',
      method: HTTP.GET,
    });

    return result;
  };
  const getCustomerMetrics = async () => {
    const result = await fetch<TGetCustomerMetrics, unknown>({
      url: '/dashboard/customers/status',
      method: HTTP.GET,
    });

    return result;
  };

  return { getDashboardMetrics, getCustomerMetrics, getSupplierMetrics };
};

export default useMetrics;
