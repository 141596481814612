import {
  HTTP,
  TValidationError,
  useNetworkContext,
} from 'src/contexts/network-context';
import {
  TGetIssuePrioritiesResponse,
  TGetIssueTypesResponse,
} from 'src/types/backend';

const useSupport = () => {
  const { fetch } = useNetworkContext();

  const getIssueTypes = async () => {
    const result = await fetch<TGetIssueTypesResponse, unknown>({
      url: '/support/issueTypes',
      method: HTTP.GET,
    });

    return result;
  };

  const getIssuePriorities = async () => {
    const result = await fetch<TGetIssuePrioritiesResponse, unknown>({
      url: '/support/issuePriorities',
      method: HTTP.GET,
    });

    return result;
  };

  const createSupportRequest = async (data: FormData) => {
    const result = await fetch<unknown, TValidationError>({
      url: '/support/request',
      method: HTTP.POST,
      data,
    });

    return result;
  };

  return {
    getIssuePriorities,
    getIssueTypes,
    createSupportRequest,
  };
};

export default useSupport;
