import clsx from 'clsx';
import { type FunctionComponent, type SVGProps } from 'react';

import styles from './IconCheckbook.module.scss';
import { TIconProps } from 'src/types/icons';

type IconCheckbookProps = Omit<TIconProps, 'iconId'> & SVGProps<SVGSVGElement>;

export const IconCheckbook: FunctionComponent<IconCheckbookProps> = ({
  className,
  width = 24,
  height = 24,
  fill,
  ...props
}): JSX.Element => {
  return (
    <svg
      className={clsx(className, styles.component)}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-element="icon"
      {...props}
    >
      <path
        d="M7 15H12.575C12.9083 15 13.1583 14.8958 13.325 14.6875C13.4917 14.4792 13.575 14.25 13.575 14C13.575 13.75 13.4917 13.5208 13.325 13.3125C13.1583 13.1042 12.9083 13 12.575 13H7C6.71667 13 6.47917 13.0958 6.2875 13.2875C6.09583 13.4792 6 13.7167 6 14C6 14.2833 6.09583 14.5208 6.2875 14.7125C6.47917 14.9042 6.71667 15 7 15ZM7 11H11C11.2833 11 11.5208 10.9042 11.7125 10.7125C11.9042 10.5208 12 10.2833 12 10C12 9.71667 11.9042 9.47917 11.7125 9.2875C11.5208 9.09583 11.2833 9 11 9H7C6.71667 9 6.47917 9.09583 6.2875 9.2875C6.09583 9.47917 6 9.71667 6 10C6 10.2833 6.09583 10.5208 6.2875 10.7125C6.47917 10.9042 6.71667 11 7 11ZM4 19C3.45 19 2.97917 18.8042 2.5875 18.4125C2.19583 18.0208 2 17.55 2 17V7C2 6.45 2.19583 5.97917 2.5875 5.5875C2.97917 5.19583 3.45 5 4 5H20C20.55 5 21.0208 5.19583 21.4125 5.5875C21.8042 5.97917 22 6.45 22 7V8.5C22 8.78333 21.9042 9.02083 21.7125 9.2125C21.5208 9.40417 21.2833 9.5 21 9.5C20.7167 9.5 20.4792 9.40417 20.2875 9.2125C20.0958 9.02083 20 8.78333 20 8.5V7H4V17H10C10.2833 17 10.5208 17.0958 10.7125 17.2875C10.9042 17.4792 11 17.7167 11 18C11 18.2833 10.9042 18.5208 10.7125 18.7125C10.5208 18.9042 10.2833 19 10 19H4ZM22.9 12.3C22.9833 12.3833 23.025 12.475 23.025 12.575C23.025 12.675 22.9833 12.7667 22.9 12.85L22 13.75L20.25 12L21.15 11.1C21.2333 11.0167 21.325 10.975 21.425 10.975C21.525 10.975 21.6167 11.0167 21.7 11.1L22.9 12.3ZM21.4 14.35L15.05 20.7C14.95 20.8 14.8375 20.875 14.7125 20.925C14.5875 20.975 14.4583 21 14.325 21H13.5C13.3667 21 13.25 20.95 13.15 20.85C13.05 20.75 13 20.6333 13 20.5V19.675C13 19.5417 13.025 19.4125 13.075 19.2875C13.125 19.1625 13.2 19.05 13.3 18.95L19.65 12.6L21.4 14.35Z"
        fill={fill || 'currentColor'}
      />
    </svg>
  );
};
