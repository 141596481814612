import Cell from 'src/components/table/cell/Cell';
import File from 'src/components/table/file/File';
import Highlighter from 'src/components/table/highlighter/Highlighter';
import { IMappedSustainabilityReport } from 'src/types/mappings';
import { formatDate } from 'src/utils/date-formatter';
import { formatNumber } from 'src/utils/number-formatter';
import { NO_PROJECT, NO_SUPPLIERS_ADDED } from './validation';

export enum StaticReportColumns {
  PRODUCT_NAME = 'product.name',
  MATERIAL_NUMBER = 'product.materialNumber',
  PART_NUMBER = 'product.partNumber',
  BARCODE = 'product.barCode',
  SUPPLIER = 'supplier.name',
  ORDER = 'invoice.purchaseOrderNumber',
  INVOICE_NAME = 'invoice.fileName',
  INVOICE_DATE = 'invoice.invoiceDate',
  INVOICE_QUANTITY = 'invoice.quantity',
  INVOICE_UOM = 'invoice.unitOfMeasurement.shortName',
  PROJECT_ID = 'project.personalId',
  PROJECT_NAME = 'project.name',
  EPD = 'epd.fileName',
  CREATED_AT = 'createdAt',
  STAGE = 'stage.name',
}

export const defaultReportColumns = [
  StaticReportColumns.PRODUCT_NAME,
  StaticReportColumns.MATERIAL_NUMBER,
  StaticReportColumns.PART_NUMBER,
  StaticReportColumns.BARCODE,
  StaticReportColumns.SUPPLIER,
  StaticReportColumns.ORDER,
  StaticReportColumns.INVOICE_NAME,
  StaticReportColumns.INVOICE_DATE,
  StaticReportColumns.INVOICE_QUANTITY,
  StaticReportColumns.INVOICE_UOM,
  StaticReportColumns.PROJECT_ID,
  StaticReportColumns.PROJECT_NAME,
  StaticReportColumns.EPD,
  StaticReportColumns.CREATED_AT,
];

export const reportsColumnLabel = {
  [StaticReportColumns.PRODUCT_NAME]: 'Product name',
  [StaticReportColumns.MATERIAL_NUMBER]: 'Material number',
  [StaticReportColumns.PART_NUMBER]: 'Part number',
  [StaticReportColumns.BARCODE]: 'Barcode',
  [StaticReportColumns.SUPPLIER]: 'Supplier',
  [StaticReportColumns.ORDER]: 'Order #',
  [StaticReportColumns.INVOICE_NAME]: 'Invoice name',
  [StaticReportColumns.INVOICE_DATE]: 'Invoice date',
  [StaticReportColumns.INVOICE_QUANTITY]: 'Invoice Qty',
  [StaticReportColumns.INVOICE_UOM]: 'Invoice UoM',
  [StaticReportColumns.PROJECT_ID]: 'Project ID',
  [StaticReportColumns.PROJECT_NAME]: 'Project name',
  [StaticReportColumns.EPD]: 'EPD file',
  [StaticReportColumns.CREATED_AT]: 'Created',
  [StaticReportColumns.STAGE]: 'Product stage',
};

export const reportsColumnWidth = {
  [StaticReportColumns.PRODUCT_NAME]: 240,
  [StaticReportColumns.MATERIAL_NUMBER]: 155,
  [StaticReportColumns.PART_NUMBER]: 155,
  [StaticReportColumns.BARCODE]: 155,
  [StaticReportColumns.SUPPLIER]: 200,
  [StaticReportColumns.ORDER]: 155,
  [StaticReportColumns.INVOICE_NAME]: 155,
  [StaticReportColumns.INVOICE_DATE]: 140,
  [StaticReportColumns.INVOICE_QUANTITY]: 114,
  [StaticReportColumns.INVOICE_UOM]: 118,
  [StaticReportColumns.PROJECT_ID]: 140,
  [StaticReportColumns.PROJECT_NAME]: 240,
  [StaticReportColumns.EPD]: 155,
  [StaticReportColumns.CREATED_AT]: 160,
  [StaticReportColumns.STAGE]: 136,
};

export const reportFilterEmptyDescription = {
  [StaticReportColumns.SUPPLIER]: NO_SUPPLIERS_ADDED,
  [StaticReportColumns.PROJECT_ID]: NO_PROJECT,
  [StaticReportColumns.PROJECT_NAME]: NO_PROJECT,
};

export const reportFilterPlaceholder = {
  [StaticReportColumns.SUPPLIER]: 'GLN, supplier ID, name, address',
  [StaticReportColumns.PROJECT_ID]: 'Project ID',
  [StaticReportColumns.PROJECT_NAME]: 'Project name',
};

export const getReportsColumnRender = (search: string) => ({
  [StaticReportColumns.PRODUCT_NAME]: (
    value: IMappedSustainabilityReport[StaticReportColumns.PRODUCT_NAME]
  ) => {
    return (
      <Cell isValue={!!value}>
        <Highlighter value={value} search={search} />
      </Cell>
    );
  },
  [StaticReportColumns.MATERIAL_NUMBER]: (
    value: IMappedSustainabilityReport[StaticReportColumns.MATERIAL_NUMBER]
  ) => {
    return (
      <Cell isValue={!!value}>
        <Highlighter value={value} search={search} />
      </Cell>
    );
  },
  [StaticReportColumns.PART_NUMBER]: (
    value: IMappedSustainabilityReport[StaticReportColumns.PART_NUMBER]
  ) => {
    return <Cell isValue={!!value}>{value}</Cell>;
  },
  [StaticReportColumns.BARCODE]: (
    value: IMappedSustainabilityReport[StaticReportColumns.BARCODE]
  ) => {
    return (
      <Cell isValue={!!value}>
        <Highlighter value={value} search={search} />
      </Cell>
    );
  },
  [StaticReportColumns.SUPPLIER]: (
    value: IMappedSustainabilityReport[StaticReportColumns.SUPPLIER]
  ) => {
    return <Cell isValue={!!value}>{value}</Cell>;
  },
  [StaticReportColumns.ORDER]: (
    value: IMappedSustainabilityReport[StaticReportColumns.ORDER]
  ) => {
    return (
      <Cell isValue={!!value}>
        <Highlighter value={value} search={search} />
      </Cell>
    );
  },
  [StaticReportColumns.INVOICE_NAME]: (
    value: IMappedSustainabilityReport[StaticReportColumns.INVOICE_NAME]
  ) => {
    return (
      <Cell isValue={!!value}>
        <File file={value} invoice />
      </Cell>
    );
  },
  [StaticReportColumns.INVOICE_DATE]: (
    value: IMappedSustainabilityReport[StaticReportColumns.INVOICE_DATE]
  ) => {
    return <Cell isValue={!!value}>{formatDate({ date: value })}</Cell>;
  },
  [StaticReportColumns.INVOICE_QUANTITY]: (
    value: IMappedSustainabilityReport[StaticReportColumns.INVOICE_QUANTITY]
  ) => {
    return <Cell isValue={!!value}>{formatNumber(value)}</Cell>;
  },
  [StaticReportColumns.INVOICE_UOM]: (
    value: IMappedSustainabilityReport[StaticReportColumns.INVOICE_UOM]
  ) => {
    return <Cell isValue={!!value}>{value}</Cell>;
  },
  [StaticReportColumns.PROJECT_ID]: (
    value: IMappedSustainabilityReport[StaticReportColumns.PROJECT_ID]
  ) => {
    return <Cell isValue={!!value}>{value}</Cell>;
  },
  [StaticReportColumns.PROJECT_NAME]: (
    value: IMappedSustainabilityReport[StaticReportColumns.PROJECT_NAME]
  ) => {
    return <Cell isValue={!!value}>{value}</Cell>;
  },
  [StaticReportColumns.EPD]: (
    value: IMappedSustainabilityReport[StaticReportColumns.EPD]
  ) => {
    return (
      <Cell isValue={!!value}>
        <File file={value} />
      </Cell>
    );
  },
  [StaticReportColumns.CREATED_AT]: (
    value: IMappedSustainabilityReport[StaticReportColumns.CREATED_AT]
  ) => {
    return (
      <Cell isValue={!!value}>
        {formatDate({ date: value, format: 'DD/MM/YYYY hh:mm:ss' })}
      </Cell>
    );
  },
  [StaticReportColumns.STAGE]: (
    value: IMappedSustainabilityReport[StaticReportColumns.STAGE]
  ) => {
    return <Cell isValue={!!value}>{value}</Cell>;
  },
});
