import { LoadingOutlined } from '@ant-design/icons';
import { Button, Checkbox, Empty, Input, Spin } from 'antd';
import { ColumnFilterItem, FilterDropdownProps } from 'antd/es/table/interface';
import clsx from 'clsx';
import { IconCancel } from 'src/components/icons/cancel/IconCancel';
import { IconSearch } from 'src/components/icons/search/IconSearch';
import { NO_RESULTS } from 'src/constants/validation';
import styles from './TableFilters.module.scss';
import { useMemo, useState } from 'react';

type TTableFilterProps = FilterDropdownProps & {
  setSearch?: (value: string) => void;
  loading?: boolean;
  isFilterSearch: boolean;
  placeholder: string;
  emptyDescription: string;
};

const TableFilters = ({
  selectedKeys,
  setSelectedKeys,
  clearFilters,
  confirm,
  setSearch,
  filters,
  loading,
  isFilterSearch,
  placeholder,
  emptyDescription,
}: TTableFilterProps) => {
  const [selected, setSelected] = useState<ColumnFilterItem[]>([]);
  const [confirmed, setConfirmed] = useState<ColumnFilterItem[]>([]);
  const onClick = (filter: ColumnFilterItem, isSelected: boolean) => {
    if (isSelected) {
      setSelected(selected.filter((option) => option.value !== filter.value));
      setSelectedKeys(selectedKeys.filter((key) => key !== filter.value));
    } else {
      setSelected((selected) => [...selected, filter]);
      setSelectedKeys([...selectedKeys, filter.value as React.Key]);
    }
  };

  const mergedFilters = useMemo(
    () => [
      ...confirmed,
      ...(filters?.filter(
        (filter) => !confirmed.some((el) => el.value === filter.value)
      ) || []),
    ],
    [confirmed, filters]
  );
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Input
          allowClear={
            isFilterSearch &&
            !loading && {
              clearIcon: <IconCancel height="16" width="16" />,
            }
          }
          prefix={<IconSearch width="16" height="16" className={styles.icon} />}
          suffix={
            loading ? (
              <Spin size="small" indicator={<LoadingOutlined spin />} />
            ) : undefined
          }
          className={styles.search}
          placeholder={placeholder}
          onChange={(event) => {
            setSearch?.(event.target.value);
          }}
        />
      </div>
      <div className={styles.body}>
        {!!mergedFilters.length ? (
          mergedFilters.map((filter, i) => {
            const value = filter.value as React.Key;
            const isSelected = selectedKeys.includes(value);
            return (
              <div
                className={clsx(styles.row, {
                  [styles.match]: isFilterSearch && i == 0,
                  [styles.active]: isSelected,
                })}
                key={value}
                onClick={() => {
                  onClick(filter, isSelected);
                }}
              >
                <Checkbox checked={isSelected} />
                {filter.text}
              </div>
            );
          })
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={isFilterSearch ? NO_RESULTS : emptyDescription}
          />
        )}
      </div>
      <div className={styles.footer}>
        <Button
          size="small"
          type="text"
          disabled={!selectedKeys.length}
          onClick={() => {
            clearFilters?.();
            setSelected([]);
            setConfirmed([]);
          }}
        >
          Reset
        </Button>
        <Button
          size="small"
          type="primary"
          onClick={() => {
            setConfirmed(selected);
            confirm({ closeDropdown: true });
          }}
        >
          OK
        </Button>
      </div>
    </div>
  );
};

export default TableFilters;
