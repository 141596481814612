import { PropsWithChildren } from 'react';

type TCellProps = PropsWithChildren<{
  isValue: boolean;
}>;

const Cell = ({ children, isValue }: TCellProps) => {
  return <>{isValue ? children : '–'}</>;
};

export default Cell;
