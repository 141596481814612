import { SortOrder } from 'src/components/icons/sort/IconSort';
import { ITableParams } from 'src/types/generic';

export const sourcesTabs = [
  {
    key: '1',
    label: 'Invoices',
  },
  {
    key: '2',
    label: 'EPDs',
  },
];

export const initialSourcesTableParams: ITableParams = {
  pagination: {
    current: 1,
    pageSize: 10,
  },
  sortField: 'createdAt',
  sortOrder: SortOrder.DESC,
};
