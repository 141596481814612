import { useEffect, useState } from 'react';
import DashboardCard from 'src/components/dashboard/card/DashboardCard';
import DashboardSection from 'src/components/dashboard/section/DashboardSection';
import useMetrics from 'src/hooks/use-metrics';
import {
  MetricsGroups,
  TMappedMetric,
  getMetricMeta,
} from 'src/utils/getMetricsMeta';
import styles from './Dashboard.module.scss';
import Loader from 'src/components/loader/Loader';
import { LogoOnly } from 'src/components/logo/logo-only/LogoOnly';

const Dashboard = () => {
  const { getDashboardMetrics } = useMetrics();
  const [data, setData] = useState<TMappedMetric[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchMetrics = async () => {
      setLoading(true);
      const res = await getDashboardMetrics();
      if (res.result?.data) {
        setData(getMetricMeta(res.result.data));
      }
      setLoading(false);
    };
    fetchMetrics();
  }, []);

  return (
    <>
      <div className={styles.component}>
        <div className={styles.container}>
          {MetricsGroups.map((group, i) => {
            return (
              <DashboardSection title={group.title} key={i}>
                {data
                  .filter((el) => group.group === el.group)
                  .sort((a, b) => a.order - b.order)
                  .map((el, i) => {
                    return (
                      <DashboardCard
                        key={i}
                        title={el.title}
                        details={el.details}
                        emptyListMessage={el.emptyListMessage}
                        mode={el.mode}
                      />
                    );
                  })}
              </DashboardSection>
            );
          })}
        </div>
        <Loader visible={loading} />
      </div>
      <LogoOnly height="329" width="316" className={styles.illustration1} />
      <LogoOnly height="615" width="640" className={styles.illustration2} />
      <LogoOnly height="403" width="420" className={styles.illustration3} />
    </>
  );
};

export default Dashboard;
