import { CloseOutlined } from '@ant-design/icons';
import { Button, Typography, theme } from 'antd';
import { PropsWithChildren, useEffect, useRef } from 'react';
import useOnEscDown from 'src/hooks/use-on-esc-down';
import { IconBee } from '../icons/bee/IconBee';
import styles from './ModalWrapper.module.scss';

type TModalWrapperProps = PropsWithChildren<{
  close: () => void;
  visible: boolean;
  width?: string | number;
  height?: string | number;
  maxHeight?: string | number;
  title: string;
}>;

const { Text } = Typography;

const ModalWrapper = ({
  close,
  visible,
  children,
  width = 520,
  height = 'auto',
  maxHeight = 'auto',
  title,
}: TModalWrapperProps) => {
  const {
    token: { boxShadowTertiary },
  } = theme.useToken();

  const ref = useRef<HTMLDivElement>(null);

  useOnEscDown(close);

  if (!visible) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div
        className={styles.modal}
        style={{ boxShadow: boxShadowTertiary, width, height, maxHeight }}
        data-element="modal"
        ref={ref}
      >
        <Text className={styles.title}>{title}</Text>

        {children}
        <Button
          type="text"
          className={styles.close}
          onClick={close}
          icon={<CloseOutlined />}
        />
        <IconBee className={styles.bee} />
      </div>
    </div>
  );
};

export default ModalWrapper;
