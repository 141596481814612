import { useEffect } from 'react';

const useOnEscDown = (action: () => void) => {
  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        action();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);
};

export default useOnEscDown;
