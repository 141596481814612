import { InboxOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Typography } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import FormItem from 'src/components/form-item/FormItem';
import { LogoOnly } from 'src/components/logo/logo-only/LogoOnly';
import { SupportFormFields, supportFormLabels } from 'src/constants/support';
import { REQUIRED } from 'src/constants/validation';
import useSupportForm from 'src/hooks/use-support-form';
import styles from './Support.module.scss';
import Loader from 'src/components/loader/Loader';

const Support = () => {
  const {
    form,
    draggerProps,
    disabled,
    contextHolder,
    submit,
    priorityOptions,
    typeOptions,
    loading,
  } = useSupportForm();

  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <div className={styles.content}>
          <Typography.Title className={styles.title} level={5}>
            Need help? Contact our support team bee
          </Typography.Title>
          <Typography.Text className={styles.description}>
            Please fill out the form below. Our support team will review your
            request and get back to you as soon as possible.
          </Typography.Text>
          <Form
            form={form}
            layout="vertical"
            requiredMark={'optional'}
            className={styles.form}
          >
            <FormItem
              name={SupportFormFields.ISSUE_TYPE}
              label={supportFormLabels[SupportFormFields.ISSUE_TYPE]}
              rules={[{ required: true, message: REQUIRED }]}
            >
              <Select disabled={disabled} options={typeOptions} />
            </FormItem>
            <FormItem
              name={SupportFormFields.ISSUE_DESCRIPTION}
              label={supportFormLabels[SupportFormFields.ISSUE_DESCRIPTION]}
              rules={[{ required: true, message: REQUIRED }]}
            >
              <Input.TextArea disabled={disabled} />
            </FormItem>
            <FormItem
              name={SupportFormFields.FILE}
              label={supportFormLabels[SupportFormFields.FILE]}
            >
              <Dragger {...draggerProps}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single upload only.
                </p>
              </Dragger>
            </FormItem>
            <FormItem
              name={SupportFormFields.ISSUE_PRIORITY}
              label={supportFormLabels[SupportFormFields.ISSUE_PRIORITY]}
              rules={[{ required: true, message: REQUIRED }]}
            >
              <Select
                disabled={disabled}
                className={styles.priority}
                options={priorityOptions}
              />
            </FormItem>
          </Form>
          <div className={styles.buttonContainer}>
            <Button disabled={disabled}>Cancel</Button>
            <Button loading={disabled} type="primary" onClick={submit}>
              Send
            </Button>
          </div>
        </div>
        <LogoOnly height="329" width="316" className={styles.illustration1} />
        <LogoOnly height="615" width="640" className={styles.illustration2} />
        <LogoOnly height="403" width="420" className={styles.illustration3} />
      </div>
      {contextHolder}
      <Loader visible={loading} />
    </div>
  );
};

export default Support;
