import {
  StaticReportColumns,
  defaultReportColumns,
} from 'src/constants/sustainability-report';

const COLUMNS = 'SUSTAINABILITY_REPORT_COLUMNS';

export type TReportColumns = Array<StaticReportColumns | string>;

export const getInitialColumns = (): TReportColumns => {
  const res = sessionStorage.getItem(COLUMNS);
  if (res) {
    return JSON.parse(res);
  }
  return defaultReportColumns;
};

export const setInitialColumns = (value: TReportColumns) => {
  sessionStorage.setItem(COLUMNS, JSON.stringify(value));
};
