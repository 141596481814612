import { Drawer } from 'antd';
import { useMemo } from 'react';
import useUsersCrudForm from 'src/hooks/use-users-crud-form';
import Loader from '../loader/Loader';
import UsersDrawerForm from './form/UsersDrawerForm';

export enum Mode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

type TUsersDrawerProps = {
  open: boolean;
  onClose: () => void;
  mode: Mode | null;
  userId: string | null;
};

const UsersDrawer = ({ open, onClose, mode, userId }: TUsersDrawerProps) => {
  const title = useMemo(
    () => (mode === Mode.CREATE ? 'Create user' : 'Edit user'),
    [mode]
  );

  const {
    form,
    firstName,
    lastName,
    jobTitle,
    role,
    email,
    phoneNumber,
    dialCode,
    status,
    createdAt,
    createdByName,
    modifiedAt,
    modifiedByName,
    isChanged,
    loading,
    submit,
    closeDrawer,
    loadingText,
  } = useUsersCrudForm(userId, mode, onClose, open);

  return (
    <Drawer
      title={title}
      open={open}
      onClose={closeDrawer}
      width={495}
      footer={<Loader visible={loading} text={loadingText} />}
    >
      <UsersDrawerForm
        mode={mode}
        onClose={closeDrawer}
        form={form}
        isChanged={isChanged}
        submit={submit}
        firstName={firstName}
        lastName={lastName}
        jobTitle={jobTitle}
        role={role}
        email={email}
        phoneNumber={phoneNumber}
        dialCode={dialCode}
        status={status}
        createdAt={createdAt}
        createdByFullName={createdByName}
        modifiedAt={modifiedAt}
        modifiedByFullName={modifiedByName}
      />
    </Drawer>
  );
};

export default UsersDrawer;
