import { SortOrder } from 'src/components/icons/sort/IconSort';
import { StaticReportColumns } from 'src/constants/sustainability-report';
import { ITableParamsWithSearch } from 'src/types/generic';
import { formatStringToDate } from './date-formatter';

export const getSustainabilityReportParams = (data: ITableParamsWithSearch) => {
  const params = new URLSearchParams();
  params.append('Page', `${data.pagination?.current}`);
  params.append('PageSize', `${data.pagination?.pageSize}`);
  params.append('Search', data.search);
  if (data.sortField) {
    params.append('SortBy', `${data.sortField}`);
    params.append('Descending', `${data.sortOrder === SortOrder.DESC}`);
  }

  if (data.filters) {
    Object.entries(data.filters).forEach(([key, value]) => {
      if (key === StaticReportColumns.SUPPLIER) {
        value?.forEach((el) => {
          params.append('supplier.id', `${el}`);
        });
      }
      if (key === StaticReportColumns.INVOICE_DATE) {
        if (value?.filter((el) => el).length) {
          params.append(
            'invoice.invoiceDateFilter.from',
            `${formatStringToDate({ date: value[0] as string }).toDateString()}`
          );
          params.append(
            'invoice.invoiceDateFilter.to',
            `${formatStringToDate({ date: value[1] as string }).toDateString()}`
          );
        }
      }
      if (
        key === StaticReportColumns.PROJECT_ID ||
        key === StaticReportColumns.PROJECT_NAME
      ) {
        value?.forEach((el) => {
          params.append(key, `${el}`);
        });
      }
    });
  }
  return params;
};
