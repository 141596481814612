import { useEffect, useMemo, useState } from 'react';
import { SortOrder } from 'src/components/icons/sort/IconSort';
import { BROADCAST_NAME } from 'src/constants/local-storage';
import { useMessageContext } from 'src/contexts/message-context';
import { useModalContext } from 'src/contexts/modal-context';
import { ICompany } from 'src/types/general';
import { ITableParamsWithSearch } from 'src/types/generic';
import {
  TMappedCompanyRecord,
  getCompanyColumnSetting,
  getMappedCompanyData,
} from 'src/utils/map-companies-table';
import useCompanies from './use-companies';
import useTableSettings from './use-table-settings';
import useTableWidth from './use-table-width';

const useCompaniesList = (onClose: () => void) => {
  const { getCompanies, addSupplier } = useCompanies();

  const { showSuccessMessage, showErrorMessage } = useMessageContext();

  const { modalContextDispatch } = useModalContext();

  const [data, setData] = useState<ICompany[]>([]);
  const [mappedData, setMappedData] = useState<TMappedCompanyRecord[]>([]);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [creating, setCreating] = useState<boolean>(false);

  const fetchCompanies = async (tableParams: ITableParamsWithSearch) => {
    if (tableParams.search.length < 3) {
      setData([]);
      return;
    }

    setLoading(true);
    const result = await getCompanies(tableParams);

    if (result.result?.data.data) {
      setData(result.result.data.data);
    }
    setLoading(false);
  };

  const { tableParams, search, setSearch, handleTableChange } =
    useTableSettings({
      sortField: 'gln',
      sortOrder: SortOrder.ASC,
      fetcher: fetchCompanies,
    });

  const columns = useMemo(() => {
    const res = getCompanyColumnSetting<TMappedCompanyRecord>({
      modalDispatch: modalContextDispatch,
      search: tableParams.search,
    });
    return res;
  }, [tableParams]);

  useEffect(() => {
    setMappedData(getMappedCompanyData(data, selectedId));
  }, [data, selectedId]);

  const createSupplier = async () => {
    if (selectedId === null) {
      return;
    }
    setCreating(true);
    const name = data[selectedId].name;
    if (!name) {
      return;
    }
    const res = await addSupplier(name);
    if (!res.errors) {
      showSuccessMessage(`Supplier “${name}” added`);
      const broadcast = new BroadcastChannel(BROADCAST_NAME);
      broadcast.postMessage('refresh');
      broadcast.close();
    } else {
      showErrorMessage(`Supplier “${name}” not added`);
    }
    setCreating(false);
  };

  const close = () => {
    setData([]);
    setSearch('');
    onClose();
  };

  const tableWidth = useTableWidth(columns);

  return {
    handleTableChange,
    setSearch,
    loading,
    columns,
    width: tableWidth,
    data: mappedData,
    debouncedSearch: tableParams.search,
    open,
    setOpen,
    selectedId,
    setSelectedId,
    creating,
    createSupplier,
    search,
    close,
  };
};

export default useCompaniesList;
