import { Drawer } from 'antd';
import { IIndicator } from 'src/types/general';
import ConfigurationDrawerForm from './form/ConfigurationDrawerForm';
import { TReportColumns } from 'src/utils/table-configuration';

type TConfigurationDrawerProps = {
  open: boolean;
  onClose: () => void;
  onSave: (value: TReportColumns) => void;
};

const ConfigurationDrawer = ({
  open,
  onClose,
  onSave,
}: TConfigurationDrawerProps) => {
  return (
    <Drawer
      title="Table configuration"
      open={open}
      onClose={onClose}
      width={420}
    >
      {open && (
        <ConfigurationDrawerForm
          onSave={onSave}
          onClose={onClose}
        />
      )}
    </Drawer>
  );
};

export default ConfigurationDrawer;
