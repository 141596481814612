import { SortOrder } from 'src/components/icons/sort/IconSort';
import {
  HTTP,
  TResponse,
  useNetworkContext,
} from 'src/contexts/network-context';
import { TGetInvoicesParams, TGetInvoicesResponse } from 'src/types/backend';

export type TGetInvoices = (
  data: TGetInvoicesParams,
  abortController?: AbortController | null
) => Promise<TResponse<TGetInvoicesResponse, unknown>>;

const useSources = () => {
  const { fetch } = useNetworkContext();

  const getInvoices: TGetInvoices = async (data, abortController) => {
    const result = await fetch<TGetInvoicesResponse, unknown>({
      url: '/invoice/paged',
      method: HTTP.GET,
      signal: abortController?.signal || undefined,
      params: {
        Page: data.pagination?.current,
        PageSize: data.pagination?.pageSize,
        ...(data.sortField
          ? {
              SortBy: data.sortField,
              Descending: data.sortOrder === SortOrder.DESC,
            }
          : {}),
        Search: data.search,
      },
    });

    return result;
  };

  const deleteInvoice = async (invoiceId: string) => {
    const result = await fetch<unknown, unknown>({
      url: `/invoice/${invoiceId}`,
      method: HTTP.DELETE,
    });

    return result;
  };

  return {
    getInvoices,
    deleteInvoice,
  };
};

export default useSources;
