import { DefaultOptionType } from 'antd/es/select';
import { SortOrder } from 'src/components/icons/sort/IconSort';
import Cell from 'src/components/table/cell/Cell';
import File from 'src/components/table/file/File';
import { IMappedPurchaseHistory } from 'src/types/mappings';
import { formatDate } from 'src/utils/date-formatter';
import { formatNumber } from 'src/utils/number-formatter';

export enum PurchaseHistoryColumns {
  ORDER = 'invoice.purchaseOrderNumber',
  INVOICE = 'invoice.fileName',
  INVOICE_DATE = 'invoice.invoiceDate',
  QUANTITY = 'quantity',
  UOM = 'unitOfMeasurement',
  TOTAL_PRICE = 'totalAmount',
  TOTAL_CURRENCY = 'currency',
  PRICE = 'productOffer.price',
  CURRENCY = 'productOffer.currency',
  PROJECT_ID = 'project.personalId',
  PROJECT_NAME = 'project.name',
}

export const defaultPurchaseHistoryColumns = [
  PurchaseHistoryColumns.ORDER,
  PurchaseHistoryColumns.INVOICE,
  PurchaseHistoryColumns.INVOICE_DATE,
  PurchaseHistoryColumns.QUANTITY,
  PurchaseHistoryColumns.UOM,
  PurchaseHistoryColumns.TOTAL_PRICE,
  PurchaseHistoryColumns.TOTAL_CURRENCY,
  PurchaseHistoryColumns.PRICE,
  PurchaseHistoryColumns.CURRENCY,
  PurchaseHistoryColumns.PROJECT_ID,
  PurchaseHistoryColumns.PROJECT_NAME,
];

export const purchaseHistoryColumnLabel = {
  [PurchaseHistoryColumns.ORDER]: 'Order #',
  [PurchaseHistoryColumns.INVOICE]: 'Invoice name',
  [PurchaseHistoryColumns.INVOICE_DATE]: 'Invoice date',
  [PurchaseHistoryColumns.QUANTITY]: 'Invoice Qty',
  [PurchaseHistoryColumns.UOM]: 'Invoice UoM',
  [PurchaseHistoryColumns.TOTAL_PRICE]: 'Invoice amount',
  [PurchaseHistoryColumns.TOTAL_CURRENCY]: 'Currency',
  [PurchaseHistoryColumns.PRICE]: 'List price',
  [PurchaseHistoryColumns.CURRENCY]: 'Currency',
  [PurchaseHistoryColumns.PROJECT_ID]: 'Project ID',
  [PurchaseHistoryColumns.PROJECT_NAME]: 'Project name',
};

export const purchaseHistoryColumnWidth = {
  [PurchaseHistoryColumns.ORDER]: 120,
  [PurchaseHistoryColumns.INVOICE]: 200,
  [PurchaseHistoryColumns.INVOICE_DATE]: 120,
  [PurchaseHistoryColumns.QUANTITY]: 120,
  [PurchaseHistoryColumns.UOM]: 120,
  [PurchaseHistoryColumns.TOTAL_PRICE]: 140,
  [PurchaseHistoryColumns.TOTAL_CURRENCY]: 98,
  [PurchaseHistoryColumns.PRICE]: 98,
  [PurchaseHistoryColumns.CURRENCY]: 98,
  [PurchaseHistoryColumns.PROJECT_ID]: 136,
  [PurchaseHistoryColumns.PROJECT_NAME]: 200,
};

export const purchaseHistoryColumnFixed: Partial<
  Record<PurchaseHistoryColumns, 'left' | 'right'>
> = {
  [PurchaseHistoryColumns.ORDER]: 'left',
  [PurchaseHistoryColumns.INVOICE]: 'left',
  [PurchaseHistoryColumns.INVOICE_DATE]: 'left',
};

export const purchaseHistoryColumnSortOrder: Partial<
  Record<PurchaseHistoryColumns, SortOrder>
> = {
  [PurchaseHistoryColumns.INVOICE_DATE]: SortOrder.DESC,
};

export const purchaseHistoryColumnAlign: Partial<
  Record<PurchaseHistoryColumns, 'right'>
> = {
  [PurchaseHistoryColumns.QUANTITY]: 'right',
  [PurchaseHistoryColumns.TOTAL_PRICE]: 'right',
  [PurchaseHistoryColumns.PRICE]: 'right',
};

type TGetPurchaseHistoryColumnRenderParams = {
  currencies: DefaultOptionType[];
  uoms: DefaultOptionType[];
};

export const getPurchaseHistoryColumnRender = ({
  currencies,
  uoms,
}: TGetPurchaseHistoryColumnRenderParams) => ({
  [PurchaseHistoryColumns.ORDER]: (
    value: IMappedPurchaseHistory[PurchaseHistoryColumns.ORDER]
  ) => {
    return <Cell isValue={!!value}>{value}</Cell>;
  },
  [PurchaseHistoryColumns.INVOICE]: (
    value: IMappedPurchaseHistory[PurchaseHistoryColumns.INVOICE]
  ) => {
    return (
      <Cell isValue={!!value}>
        <File file={value} invoice />
      </Cell>
    );
  },
  [PurchaseHistoryColumns.INVOICE_DATE]: (
    value: IMappedPurchaseHistory[PurchaseHistoryColumns.INVOICE_DATE]
  ) => {
    return <Cell isValue={!!value}>{formatDate({ date: value })}</Cell>;
  },
  [PurchaseHistoryColumns.QUANTITY]: (
    value: IMappedPurchaseHistory[PurchaseHistoryColumns.QUANTITY]
  ) => {
    return <Cell isValue={!!value}>{formatNumber(value)}</Cell>;
  },
  [PurchaseHistoryColumns.UOM]: (
    value: IMappedPurchaseHistory[PurchaseHistoryColumns.UOM]
  ) => {
    return (
      <Cell isValue={!!value}>
        {uoms.find((el) => el.value === value)?.label}
      </Cell>
    );
  },
  [PurchaseHistoryColumns.TOTAL_PRICE]: (
    value: IMappedPurchaseHistory[PurchaseHistoryColumns.TOTAL_PRICE]
  ) => {
    return <Cell isValue={!!value}>{formatNumber(value)}</Cell>;
  },
  [PurchaseHistoryColumns.TOTAL_CURRENCY]: (
    value: IMappedPurchaseHistory[PurchaseHistoryColumns.TOTAL_CURRENCY]
  ) => {
    return (
      <Cell isValue={!!value}>
        {currencies.find((el) => el.value === value)?.label}
      </Cell>
    );
  },
  [PurchaseHistoryColumns.PRICE]: (
    value: IMappedPurchaseHistory[PurchaseHistoryColumns.PRICE]
  ) => {
    return <Cell isValue={!!value}>{formatNumber(value)}</Cell>;
  },
  [PurchaseHistoryColumns.CURRENCY]: (
    value: IMappedPurchaseHistory[PurchaseHistoryColumns.CURRENCY]
  ) => {
    return (
      <Cell isValue={!!value}>
        {currencies.find((el) => el.value === value)?.label}
      </Cell>
    );
  },
  [PurchaseHistoryColumns.PROJECT_ID]: (
    value: IMappedPurchaseHistory[PurchaseHistoryColumns.PROJECT_ID]
  ) => {
    return <Cell isValue={!!value}>{value}</Cell>;
  },
  [PurchaseHistoryColumns.PROJECT_NAME]: (
    value: IMappedPurchaseHistory[PurchaseHistoryColumns.PROJECT_NAME]
  ) => {
    return <Cell isValue={!!value}>{value}</Cell>;
  },
});
