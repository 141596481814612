import { Country } from 'country-state-city';
import { DEFAULT_COUNTRY_ISO, DEFAULT_DIAL_CODE } from 'src/constants/users';

export const getDialCode = (value: string | undefined, withDash = true) => {
  if (!value) {
    return DEFAULT_DIAL_CODE;
  }
  const parsedValue = (value.startsWith('+') ? value : `+${value}`).split(
    ' '
  )[0];

  if (!withDash) {
    return parsedValue.split('-').join('');
  }

  return parsedValue;
};

export const getPhoneNumber = (
  phoneNumber: string | null,
  isoCode: string | null
) => {
  if (!phoneNumber || !isoCode) {
    return null;
  }
  const dialCode = getDialCode(Country.getCountryByCode(isoCode)?.phonecode);
  return phoneNumber.slice(dialCode.length);
};

export const getDialCodeByIso = (isoCode: string | null) => {
  if (!isoCode) {
    return DEFAULT_DIAL_CODE;
  }
  const dialCode = getDialCode(Country.getCountryByCode(isoCode)?.phonecode);
  return dialCode;
};
