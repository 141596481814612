import DetailsItem from 'src/components/object-header/details-item/DetailsItem';
import Cell from 'src/components/table/cell/Cell';
import File from 'src/components/table/file/File';
import Url from 'src/components/table/url/Url';
import { IProduct } from 'src/types/general';
import { formatDate } from 'src/utils/date-formatter';
import { formatNumber } from 'src/utils/number-formatter';
import styles from './ProductInfo.module.scss';
import { DefaultOptionType } from 'antd/es/select';

type TProductInfoProps = Partial<IProduct> & {
  currencies: DefaultOptionType[];
  supplier?: string | null;
};

const ProductInfo = ({
  materialNumber,
  partNumber,
  barCode,
  rrpPrice,
  quantity,
  unitOfMeasurement,
  externalEpdUrl,
  epdFile,
  createdAt,
  createdByName,
  modifiedAt,
  modifiedByName,
  currency,
  currencies,
  supplier,
}: TProductInfoProps) => {
  return (
    <div className={styles.details}>
      <div className={styles.column}>
        <DetailsItem label="Material number">
          <Cell isValue={!!materialNumber}>{materialNumber}</Cell>
        </DetailsItem>
        <DetailsItem label="Part number">
          <Cell isValue={!!partNumber}>{partNumber}</Cell>
        </DetailsItem>
        <DetailsItem label="Barcode">
          <Cell isValue={!!barCode}>{barCode}</Cell>
        </DetailsItem>
        {typeof supplier !== 'undefined' && (
          <DetailsItem label="Supplier">
            <Cell isValue={!!supplier}>{supplier}</Cell>
          </DetailsItem>
        )}
      </div>
      <div className={styles.column}>
        <DetailsItem label="RRP">
          <Cell
            isValue={!!rrpPrice}
          >{`${formatNumber(rrpPrice)} ${currencies.find((el) => el.value === currency)?.label || ''}`}</Cell>
        </DetailsItem>
        <DetailsItem label="UoM">
          <Cell
            isValue={!!quantity}
          >{`${formatNumber(quantity)} ${unitOfMeasurement || ''}`}</Cell>
        </DetailsItem>
        <DetailsItem label="EPD link">
          <Cell isValue={!!externalEpdUrl}>
            {!!externalEpdUrl && <Url value={externalEpdUrl} />}
          </Cell>
        </DetailsItem>
        <DetailsItem label="EPD file">
          <Cell isValue={!!epdFile?.fileName}>
            <File file={epdFile} />
          </Cell>
        </DetailsItem>
      </div>
      <div className={styles.column}>
        {typeof createdAt !== 'undefined' && (
          <DetailsItem label="Created on">
            <Cell isValue={!!createdAt}>
              {!!createdAt && formatDate({ date: createdAt })}
            </Cell>
          </DetailsItem>
        )}
        {typeof createdByName !== 'undefined' && (
          <DetailsItem label="Created by">
            <Cell isValue={!!createdByName}>{createdByName}</Cell>
          </DetailsItem>
        )}
        {typeof modifiedAt !== 'undefined' && (
          <DetailsItem label="Modified on">
            <Cell isValue={!!modifiedAt}>
              {!!modifiedAt && formatDate({ date: modifiedAt })}
            </Cell>
          </DetailsItem>
        )}
        {typeof modifiedByName !== 'undefined' && (
          <DetailsItem label="Modified by">
            <Cell isValue={!!modifiedByName}>{modifiedByName}</Cell>
          </DetailsItem>
        )}
      </div>
    </div>
  );
};

export default ProductInfo;
