import HiveContainer from 'src/components/hexagon/container/HiveContainer';
import { HivePixLogo } from 'src/components/hexagon/logo/HivePixLogo';
import Loader from 'src/components/loader/Loader';
import { hiveColumns } from 'src/constants/hive-map';
import useHiveMap from 'src/hooks/use-hive-map';
import styles from './HiveMap.module.scss';

const Map = () => {
  const {
    loading,
    supplierColumns,
    customerColumns,
    navigateToCustomers,
    navigateToSuppliers,
  } = useHiveMap();
  return (
    <div className={styles.component}>
      <Loader visible={loading} />
      <div className={styles.container}>
        <HiveContainer
          columns={supplierColumns}
          className={styles.supplier}
          navigate={navigateToSuppliers}
        />
        <HiveContainer
          columns={customerColumns}
          className={styles.customer}
          navigate={navigateToCustomers}
        />
        <HivePixLogo className={styles.logo} />
        <HiveContainer columns={hiveColumns} className={styles.hive} />
      </div>
    </div>
  );
};

export default Map;
