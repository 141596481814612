import { useEffect, useMemo, useState } from 'react';
import { SortOrder } from 'src/components/icons/sort/IconSort';
import { StaticReportColumns } from 'src/constants/sustainability-report';
import { useMeasurementsContext } from 'src/contexts/measurements-context';
import { ISustainabilityReport } from 'src/types/general';
import { ITableParamsWithSearch } from 'src/types/generic';
import {
  TMappedSustainabilityReportRecord,
  getMappedReportData,
  getReportColumnSetting,
} from 'src/utils/map-sustainability-report';
import {
  TReportColumns,
  getInitialColumns,
} from 'src/utils/table-configuration';
import useProjectsFilters from './use-projects-filters';
import useReports from './use-reports';
import useSupplierFilters from './use-supplier-filters';
import useTableSettings from './use-table-settings';
import useTableWidth from './use-table-width';

const useInsightsList = () => {
  const { indicators } = useMeasurementsContext();
  const { getSustainabilityReport, exportReport } = useReports();

  const [data, setData] = useState<ISustainabilityReport[]>([]);

  const [selectedColumns, setSelectedColumns] =
    useState<TReportColumns>(getInitialColumns());
  const [mappedData, setMappedData] = useState<
    TMappedSustainabilityReportRecord[]
  >([]);
  const [total, setTotal] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchReports = async (tableParams: ITableParamsWithSearch) => {
    setLoading(true);
    const result = await getSustainabilityReport(tableParams);
    if (result.result?.data.data) {
      setData(result.result.data.data);
      setTotal(result.result.data.totalCount);
    }
    setLoading(false);
  };

  const { tableParams, search, setSearch, handleTableChange } =
    useTableSettings({
      sortField: 'createdAt',
      sortOrder: SortOrder.DESC,
      fetcher: fetchReports,
    });

  const {
    sortedFilters,
    setFilterSearch,
    filtersLoading,
    debouncedFilterSearch,
  } = useSupplierFilters(tableParams);

  const {
    sortedFiltersById,
    sortedFiltersByName,
    setFilterIdSearch,
    setFilterNameSearch,
    filtersIdLoading,
    filtersNameLoading,
    debouncedFilterIdSearch,
    debouncedFilterNameSearch,
  } = useProjectsFilters(tableParams);

  const columns = useMemo(() => {
    const res = getReportColumnSetting<TMappedSustainabilityReportRecord>({
      search: tableParams.search,
      indicators,
      properties: selectedColumns,
      supplierFilters: sortedFilters,
      setFilterSupplierSearch: setFilterSearch,
      isFilterSupplierSearch: !!debouncedFilterSearch,
      searchSupplierLoading: filtersLoading,
      projectIdFilters: sortedFiltersById,
      setFilterIdSearch,
      isFilterIdSearch: !!debouncedFilterIdSearch,
      searchIdLoading: filtersIdLoading,
      projectNameFilters: sortedFiltersByName,
      setFilterNameSearch,
      isFilterNameSearch: !!debouncedFilterNameSearch,
      searchNameLoading: filtersNameLoading,
    });
    return res;
  }, [
    tableParams,
    indicators,
    selectedColumns,
    sortedFilters,
    sortedFiltersById,
    sortedFiltersByName,
    filtersIdLoading,
    filtersNameLoading,
    filtersLoading,
  ]);

  const exportSettings = useMemo(() => {
    return columns.map((col) => {
      switch (col.key) {
        case StaticReportColumns.PRODUCT_NAME:
        case StaticReportColumns.MATERIAL_NUMBER:
        case StaticReportColumns.PART_NUMBER:
        case StaticReportColumns.BARCODE:
        case StaticReportColumns.SUPPLIER:
        case StaticReportColumns.ORDER:
        case StaticReportColumns.INVOICE_NAME:
        case StaticReportColumns.INVOICE_DATE:
        case StaticReportColumns.INVOICE_QUANTITY:
        case StaticReportColumns.INVOICE_UOM:
        case StaticReportColumns.PROJECT_ID:
        case StaticReportColumns.PROJECT_NAME:
        case StaticReportColumns.EPD:
        case StaticReportColumns.CREATED_AT:
        case StaticReportColumns.STAGE:
          return { key: col.title as string, value: col.key as string };
        default:
          return {
            key: col.title as string,
            value: `environmentalIndicatorsEffortValues.${col.key}.value`,
          };
      }
    });
  }, [columns]);

  useEffect(() => {
    setMappedData(getMappedReportData(data, indicators));
  }, [indicators, data]);

  const exportSustainabilityReport = async () => {
    await exportReport(
      {
        ...tableParams,
        pagination: {
          current: 1,
          pageSize: total,
        },
        search: tableParams.search.length >= 3 ? tableParams.search : '',
      },
      exportSettings
    );
  };

  const tableWidth = useTableWidth(columns);

  return {
    handleTableChange,
    setSearch,
    loading,
    columns,
    width: tableWidth,
    data: mappedData,
    total,
    open,
    setOpen,
    debouncedSearch: tableParams.search,
    search,
    setSelectedColumns,
    exportSustainabilityReport,
    current: tableParams.pagination?.current,
  };
};

export default useInsightsList;
