import clsx from 'clsx';
import { type FunctionComponent, type SVGProps } from 'react';

import styles from './IconConnected.module.scss';
import { TIconProps } from 'src/types/icons';
import { Tooltip } from 'antd';
import { HiveStatus } from 'src/constants/hive-map';

type IconConnectedProps = Omit<TIconProps, 'iconId'> & SVGProps<SVGSVGElement>;

export const IconConnected: FunctionComponent<IconConnectedProps> = ({
  className,
  width = 24,
  height = 24,
  ...props
}): JSX.Element => {
  return (
    <Tooltip title={HiveStatus.CONNECTED}>
      <svg
        className={clsx(className, styles.component)}
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-element="icon"
        {...props}
      >
        <rect x="0.5" y="0.5" width="23" height="23" rx="7.5" fill="#F6FFED" />
        <rect
          x="0.5"
          y="0.5"
          width="23"
          height="23"
          rx="7.5"
          stroke="#B7EB8F"
        />

        <path
          d="M11.1501 12.7L10.1501 11.7166C10.0279 11.5944 9.88899 11.5333 9.73343 11.5333C9.57788 11.5333 9.43899 11.5944 9.31676 11.7166C9.19454 11.8389 9.13343 11.9805 9.13343 12.1416C9.13343 12.3028 9.19454 12.4444 9.31676 12.5666L10.7334 13.9833C10.8557 14.1055 10.9945 14.1666 11.1501 14.1666C11.3057 14.1666 11.4445 14.1055 11.5668 13.9833L14.6834 10.8666C14.8057 10.7444 14.8668 10.6028 14.8668 10.4416C14.8668 10.2805 14.8057 10.1389 14.6834 10.0166C14.5612 9.89442 14.4223 9.83331 14.2668 9.83331C14.1112 9.83331 13.9723 9.89442 13.8501 10.0166L11.1501 12.7ZM12.0001 18.4C11.1223 18.4 10.2945 18.2333 9.51676 17.9C8.73899 17.5666 8.05843 17.1083 7.4751 16.525C6.89176 15.9416 6.43343 15.2611 6.1001 14.4833C5.76676 13.7055 5.6001 12.8778 5.6001 12C5.6001 11.1111 5.76676 10.2805 6.1001 9.50831C6.43343 8.73609 6.89176 8.05831 7.4751 7.47498C8.05843 6.89164 8.73899 6.43331 9.51676 6.09998C10.2945 5.76664 11.1223 5.59998 12.0001 5.59998C12.889 5.59998 13.7195 5.76664 14.4918 6.09998C15.264 6.43331 15.9418 6.89164 16.5251 7.47498C17.1084 8.05831 17.5668 8.73609 17.9001 9.50831C18.2334 10.2805 18.4001 11.1111 18.4001 12C18.4001 12.8778 18.2334 13.7055 17.9001 14.4833C17.5668 15.2611 17.1084 15.9416 16.5251 16.525C15.9418 17.1083 15.264 17.5666 14.4918 17.9C13.7195 18.2333 12.889 18.4 12.0001 18.4Z"
          fill="#52C41A"
        />
      </svg>
    </Tooltip>
  );
};
