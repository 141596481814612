import { useMemo, useState } from 'react';
import { SortOrder } from 'src/components/icons/sort/IconSort';
import { Mode } from 'src/components/users-drawer/UsersDrawer';
import { UsersColumns } from 'src/constants/users';
import { ITableParamsWithSearch } from 'src/types/generic';
import {
  TMappedUsersRecord,
  getMappedUsersData,
  getUsersColumnSetting,
} from 'src/utils/map-users';
import useTableSettings from './use-table-settings';
import useTableWidth from './use-table-width';
import useUsers from './use-users';
import { useModalContext } from 'src/contexts/modal-context';

const useUsersList = () => {
  const { getUsers } = useUsers();
  const { modalContextDispatch } = useModalContext();

  const [data, setData] = useState<TMappedUsersRecord[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [mode, setMode] = useState<Mode | null>(null);
  const [userId, setUserId] = useState<string | null>(null);

  const closeDrawer = () => {
    setUserId('');
    setOpen(false);
  };

  const openDrawer = (mode: Mode, userId?: string) => {
    setMode(mode);
    if (userId) {
      setUserId(userId);
    }
    setOpen(true);
  };

  const openEdit = (record: TMappedUsersRecord) => {
    openDrawer(Mode.EDIT, record.key);
  };

  const fetchUsers = async (tableParams: ITableParamsWithSearch) => {
    setLoading(true);
    const result = await getUsers({
      ...tableParams,
    });

    if (result.result?.data.data) {
      setData(getMappedUsersData(result.result.data.data));
      setTotal(result.result.data.totalCount);
    }
    setLoading(false);
  };

  const { tableParams, search, setSearch, handleTableChange } =
    useTableSettings({
      sortField: UsersColumns.NAME,
      sortOrder: SortOrder.ASC,
      fetcher: fetchUsers,
    });

  const columns = useMemo(() => {
    const res = getUsersColumnSetting({
      search: tableParams.search,
      openEdit,
      modalDispatch: modalContextDispatch,
    });
    return res;
  }, [tableParams]);

  const tableWidth = useTableWidth(columns);

  return {
    handleTableChange,
    setSearch,
    loading,
    columns,
    width: tableWidth,
    data,
    debouncedSearch: tableParams.search,
    current: tableParams.pagination?.current,
    selectedId,
    setSelectedId,
    total,
    search,
    open,
    closeDrawer,
    openDrawer,
    mode,
    userId,
  };
};

export default useUsersList;
