import type { THiveColumn } from 'src/components/hexagon/container/HiveContainer';
import { IconConnected } from 'src/components/hexagon/status/connected/IconConnected';
import { IconDisconnected } from 'src/components/hexagon/status/disconnected/IconDisconnected';
import { IconPending } from 'src/components/hexagon/status/pending/IconPending';
import { IconQualityIssue } from 'src/components/hexagon/status/quality-issue/IconQualityIssue';
import { IconSuspended } from 'src/components/hexagon/status/suspended/IconSuspended';

export enum HiveStatus {
  CONNECTED = 'Connected',
  SUSPENDED = 'Suspended',
  QUALITY_ISSUE = 'Data quality issue',
  DISCONNECTED = 'Disconnected',
  PENDING = 'Pending',
}

export const statusIcon = {
  [HiveStatus.CONNECTED]: <IconConnected />,
  [HiveStatus.SUSPENDED]: <IconSuspended />,
  [HiveStatus.QUALITY_ISSUE]: <IconQualityIssue />,
  [HiveStatus.DISCONNECTED]: <IconDisconnected />,
  [HiveStatus.PENDING]: <IconPending />,
};

export const hiveColumns: THiveColumn[] = [
  {
    items: [
      {
        type: 'blank',
      },
      {
        type: 'status',
        data: {
          title: 'MS Dynamics 365 Supply Chain Management',
          status: HiveStatus.CONNECTED,
        },
      },
      {
        type: 'status',
        data: {
          title: 'SAP S/4HANA',
          status: HiveStatus.CONNECTED,
        },
      },
    ],
  },
  {
    items: [
      {
        type: 'status',
        data: {
          title: '[ERP_name]',
          status: HiveStatus.CONNECTED,
        },
      },
      {
        type: 'status',
        data: {
          title: 'Oracle ERP Cloud',
          status: HiveStatus.SUSPENDED,
        },
      },
    ],
  },

  {
    items: [
      {
        type: 'hive-logo',
      },
      {
        type: 'status',
        data: {
          title: '[ERP_name]',
          status: HiveStatus.CONNECTED,
        },
      },
      {
        type: 'status',
        data: {
          title: '[ERP_name]',
          status: HiveStatus.CONNECTED,
        },
      },
    ],
  },
  {
    items: [
      {
        type: 'status',
        data: {
          title: '[ERP_name]',
          status: HiveStatus.DISCONNECTED,
        },
      },
      {
        type: 'status',
        data: {
          title: 'Oracle ERP Cloud',
          status: HiveStatus.QUALITY_ISSUE,
        },
      },
    ],
  },
  {
    items: [
      {
        type: 'blank',
      },
      {
        type: 'status',
        data: {
          title: '[ERP_name]',
          status: HiveStatus.PENDING,
        },
      },
      {
        type: 'status',
        data: {
          title: '[ERP_name]',
          status: HiveStatus.CONNECTED,
        },
      },
    ],
  },
];
