import clsx from 'clsx';
import { type FunctionComponent, type SVGProps } from 'react';

import styles from './IconSuspended.module.scss';
import { TIconProps } from 'src/types/icons';
import { Tooltip } from 'antd';
import { HiveStatus } from 'src/constants/hive-map';

type IconSuspendedProps = Omit<TIconProps, 'iconId'> & SVGProps<SVGSVGElement>;

export const IconSuspended: FunctionComponent<IconSuspendedProps> = ({
  className,
  width = 24,
  height = 24,
  ...props
}): JSX.Element => {
  return (
    <Tooltip title={HiveStatus.SUSPENDED}>
      <svg
        className={clsx(className, styles.component)}
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-element="icon"
        {...props}
      >
        <rect x="0.5" y="0.5" width="23" height="23" rx="7.5" fill="#FFF7E6" />
        <rect
          x="0.5"
          y="0.5"
          width="23"
          height="23"
          rx="7.5"
          stroke="#FFD591"
        />

        <path
          d="M10.6666 14.6666C10.8555 14.6666 11.0138 14.6028 11.1416 14.475C11.2694 14.3472 11.3333 14.1889 11.3333 14V9.99998C11.3333 9.81109 11.2694 9.65276 11.1416 9.52498C11.0138 9.3972 10.8555 9.33331 10.6666 9.33331C10.4777 9.33331 10.3194 9.3972 10.1916 9.52498C10.0638 9.65276 9.99992 9.81109 9.99992 9.99998V14C9.99992 14.1889 10.0638 14.3472 10.1916 14.475C10.3194 14.6028 10.4777 14.6666 10.6666 14.6666ZM13.3333 14.6666C13.5221 14.6666 13.6805 14.6028 13.8083 14.475C13.936 14.3472 13.9999 14.1889 13.9999 14V9.99998C13.9999 9.81109 13.936 9.65276 13.8083 9.52498C13.6805 9.3972 13.5221 9.33331 13.3333 9.33331C13.1444 9.33331 12.986 9.3972 12.8583 9.52498C12.7305 9.65276 12.6666 9.81109 12.6666 9.99998V14C12.6666 14.1889 12.7305 14.3472 12.8583 14.475C12.986 14.6028 13.1444 14.6666 13.3333 14.6666ZM11.9999 18.6666C11.0777 18.6666 10.211 18.4916 9.39992 18.1416C8.58881 17.7916 7.88325 17.3166 7.28325 16.7166C6.68325 16.1166 6.20825 15.4111 5.85825 14.6C5.50825 13.7889 5.33325 12.9222 5.33325 12C5.33325 11.0778 5.50825 10.2111 5.85825 9.39998C6.20825 8.58887 6.68325 7.88331 7.28325 7.28331C7.88325 6.68331 8.58881 6.20831 9.39992 5.85831C10.211 5.50831 11.0777 5.33331 11.9999 5.33331C12.9221 5.33331 13.7888 5.50831 14.5999 5.85831C15.411 6.20831 16.1166 6.68331 16.7166 7.28331C17.3166 7.88331 17.7916 8.58887 18.1416 9.39998C18.4916 10.2111 18.6666 11.0778 18.6666 12C18.6666 12.9222 18.4916 13.7889 18.1416 14.6C17.7916 15.4111 17.3166 16.1166 16.7166 16.7166C16.1166 17.3166 15.411 17.7916 14.5999 18.1416C13.7888 18.4916 12.9221 18.6666 11.9999 18.6666Z"
          fill="#FA8C16"
        />
      </svg>
    </Tooltip>
  );
};
