import { message, theme } from 'antd';
import { IconCheckOutlined } from 'src/components/icons/check-outlined/IconCheckOutlined';
import { IconError } from 'src/components/icons/error/IconError';
import { IconInfo } from 'src/components/icons/info/IconInfo';
import styles from 'src/styles/actions.module.scss';

const useMessage = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const {
    token: { colorSuccess, colorError, colorInfo },
  } = theme.useToken();

  const showSuccessMessage = (text: string) => {
    messageApi.success({
      content: text,
      icon: (
        <IconCheckOutlined
          className={styles.marginRight}
          height="16"
          width="16"
          fill={colorSuccess}
        />
      ),
    });
  };
  const showErrorMessage = (text: string) => {
    messageApi.error({
      content: text,
      icon: (
        <IconError
          className={styles.marginRight}
          height="16"
          width="16"
          fill={colorError}
        />
      ),
    });
  };
  const showInfoMessage = (text: string) => {
    messageApi.info({
      content: text,
      icon: (
        <IconInfo
          className={styles.marginRight}
          height="16"
          width="16"
          fill={colorInfo}
        />
      ),
    });
  };

  return {
    contextHolder,
    showSuccessMessage,
    showErrorMessage,
    showInfoMessage,
  };
};

export default useMessage;
