import { ReactNode } from 'react';
import styles from './DashboardCard.module.scss';
import clsx from 'clsx';
import { Card, Empty, Statistic } from 'antd';
import { useNavigate } from 'react-router-dom';
import Url from 'src/components/table/url/Url';

type TCardMode = 'button' | 'list' | 'info';

export type TCardInfo = {
  icon?: ReactNode;
  title: string;
  amount?: string;
  url?: string;
  danger?: boolean;
  secondary?: boolean;
  warning?: boolean;
};

export type TDashboardCardProps = {
  mode?: TCardMode;
  emptyListMessage?: string;
  title: TCardInfo | null;
  details: TCardInfo[];
};

const DashboardCard = ({
  mode = 'button',
  title,
  details,
  emptyListMessage = 'No data',
}: TDashboardCardProps) => {
  const isButton = mode === 'button';
  const isInfo = mode === 'info';
  const isList = mode === 'list';

  const cardTitle = title ? (
    <>
      {title.icon}
      {title.title}
    </>
  ) : undefined;

  const cardExtra = title ? title.amount : undefined;

  const navigate = useNavigate();

  const hoverable = isButton && !!title?.url;

  return (
    <Card
      className={clsx(styles.component, {
        [styles.info]: isInfo,
        [styles.noHover]: isInfo || !hoverable,
      })}
      bordered={false}
      title={cardTitle}
      extra={cardExtra}
      hoverable={hoverable}
      onClick={() => {
        if (isButton && title?.url) {
          navigate(title.url);
        }
      }}
    >
      {isList && !details.length ? (
        <Empty
          style={{ margin: 0 }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={emptyListMessage}
        />
      ) : (
        details.map((detail, i) =>
          isInfo ? (
            <Statistic
              key={i}
              title={detail.title}
              value={detail.amount}
              prefix={detail.icon}
            />
          ) : (
            <div className={styles.row} key={i}>
              <div className={styles.title}>
                {detail.url ? (
                  <Url value={detail.url} iconShown={false} openTab={false}>
                    {detail.title}
                  </Url>
                ) : (
                  detail.title
                )}
              </div>
              <div
                className={clsx(styles.amount, {
                  [styles.danger]: detail.danger,
                  [styles.secondary]: detail.secondary,
                  [styles.warning]: detail.warning,
                })}
              >
                {detail.amount}
              </div>
            </div>
          )
        )
      )}
    </Card>
  );
};

export default DashboardCard;
