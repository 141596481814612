import { Tabs, TabsProps } from 'antd';
import { useMemo } from 'react';
import ProductsDrawer from 'src/components/products/drawer/ProductsDrawer';
import SuppliersProductsTable from 'src/components/products/suppliers-table/SuppliersProductsTable';
import ProductsTable from 'src/components/products/table/ProductsTable';
import useProductsList from 'src/hooks/use-products-list';

const ProductList = () => {
  const {
    productId,
    mode,
    open,
    closeDrawer,
    defaultTab,
    handleTabChange,
    openDrawer,
  } = useProductsList();

  const productTabs: TabsProps['items'] = useMemo(
    () => [
      {
        key: '1',
        label: 'My company products',
        children: <ProductsTable openDrawer={openDrawer} />,
      },
      {
        key: '2',
        label: "My suppliers' products",
        children: <SuppliersProductsTable />,
      },
    ],
    []
  );

  return (
    <div>
      <Tabs
        activeKey={defaultTab}
        items={productTabs}
        onChange={handleTabChange}
      />

      <ProductsDrawer
        open={open}
        productId={productId}
        onClose={closeDrawer}
        mode={mode}
      />
    </div>
  );
};

export default ProductList;
