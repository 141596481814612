import { Button, TagProps, Tooltip } from 'antd';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { IconEdit } from 'src/components/icons/edit/IconEdit';
import { IconForwardInbox } from 'src/components/icons/forward-inbox/IconForwardInbox';
import { SortOrder } from 'src/components/icons/sort/IconSort';
import StatusPicker from 'src/components/status-picker/StatusPicker';
import Cell from 'src/components/table/cell/Cell';
import Email from 'src/components/table/email/Email';
import Highlighter from 'src/components/table/highlighter/Highlighter';
import { Mode } from 'src/components/users-drawer/UsersDrawer';
import {
  ActionType,
  ModalContextActions,
  TModalContextStateAction,
} from 'src/contexts/modal-context';
import styles from 'src/styles/actions.module.scss';
import { IMappedUsers } from 'src/types/mappings';
import { formatDate } from 'src/utils/date-formatter';
import { TMappedUsersRecord } from 'src/utils/map-users';

export enum UserStatus {
  NONE = 0,
  ACTIVE = 100,
  EXPIRED = 200,
  INACTIVE = 300,
  PENDING = 400,
}

export const modalDanger = {
  [UserStatus.INACTIVE]: true,
  [UserStatus.NONE]: false,
  [UserStatus.ACTIVE]: false,
  [UserStatus.EXPIRED]: false,
  [UserStatus.PENDING]: false,
};

export const modalAction: Partial<Record<UserStatus, ActionType>> = {
  [UserStatus.INACTIVE]: ActionType.DEACTIVATE_USER,
  [UserStatus.PENDING]: ActionType.INVITE_USER,
};

export const modalActionTitle: Partial<Record<UserStatus, string>> = {
  [UserStatus.INACTIVE]: 'Deactivate user',
  [UserStatus.PENDING]: 'Invite user',
};

export const modalActionLabel: Partial<Record<UserStatus, string>> = {
  [UserStatus.INACTIVE]: 'Deactivate',
  [UserStatus.PENDING]: 'Invite',
};

export const modalActionDescription: Partial<Record<UserStatus, ReactNode>> = {
  [UserStatus.INACTIVE]: (
    <>
      will lose access to the system, and their status will be set to inactive.
      <br />
      <br />
      You can invite the user again at any time.
    </>
  ),
  [UserStatus.PENDING]:
    'will receive an invitation to their specified email and the status will be set to invited.',
};

export const DEFAULT_DIAL_CODE = '+61';
export const DEFAULT_COUNTRY_ISO = 'AU';

export enum UserRoles {
  BASIC_USER = 'BasicUser',
  ADVANCED_USER = 'AdvancedUser',
  ADMIN = 'Admin',
}

export const userRolesLabels = {
  [UserRoles.BASIC_USER]: 'Basic user',
  [UserRoles.ADVANCED_USER]: 'Advanced user',
  [UserRoles.ADMIN]: 'Admin',
};

export enum UserFormFields {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  JOB_TITLE = 'jobTitle',
  ROLE = 'role',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
  COUNTRY_CODE = 'countryCode',
  STATUS = 'status',
  CREATED_AT = 'createdAt',
  CREATED_BY = 'createdByFullName',
  MODIFIED_AT = 'modifiedAt',
  MODIFIED_BY = 'modifiedByFullName',
}

export const userFormLabels: Partial<Record<UserFormFields, string>> = {
  [UserFormFields.FIRST_NAME]: 'First name',
  [UserFormFields.LAST_NAME]: 'Last name',
  [UserFormFields.JOB_TITLE]: 'Job title',
  [UserFormFields.ROLE]: 'Role',
  [UserFormFields.EMAIL]: 'Email',
  [UserFormFields.PHONE_NUMBER]: 'Phone',
  [UserFormFields.STATUS]: 'Status',
  [UserFormFields.CREATED_AT]: 'Created on',
  [UserFormFields.CREATED_BY]: 'Created by',
  [UserFormFields.MODIFIED_AT]: 'Modified on',
  [UserFormFields.MODIFIED_BY]: 'Modified by',
};

export const userStatusColor: Record<UserStatus, TagProps['color']> = {
  [UserStatus.NONE]: undefined,
  [UserStatus.ACTIVE]: undefined,
  [UserStatus.EXPIRED]: 'magenta',
  [UserStatus.INACTIVE]: 'red',
  [UserStatus.PENDING]: 'geekblue',
};

export const userStatusLabel = {
  [UserStatus.NONE]: '',
  [UserStatus.ACTIVE]: 'Active',
  [UserStatus.EXPIRED]: 'Expired',
  [UserStatus.INACTIVE]: 'Inactive',
  [UserStatus.PENDING]: 'Invited',
};

export enum UsersColumns {
  NAME = 'fullName',
  JOB_TITLE = 'jobTitle',
  STATUS = 'status',
  ROLE = 'role',
  EMAIL = 'email',
  PHONE = 'phoneNumber',
  CREATED_AT = 'createdAt',
  LAST_ACTIVITY = 'lastActiveAt',
  ACTIONS = 'actions',
}

export const defaultUserColumns = [
  UsersColumns.NAME,
  UsersColumns.JOB_TITLE,
  UsersColumns.STATUS,
  UsersColumns.ROLE,
  UsersColumns.EMAIL,
  UsersColumns.PHONE,
  UsersColumns.CREATED_AT,
  UsersColumns.LAST_ACTIVITY,
  UsersColumns.ACTIONS,
];

export const usersColumnLabel = {
  [UsersColumns.NAME]: 'Name',
  [UsersColumns.JOB_TITLE]: 'Job title',
  [UsersColumns.STATUS]: 'Status',
  [UsersColumns.ROLE]: 'Role',
  [UsersColumns.EMAIL]: 'Email',
  [UsersColumns.PHONE]: 'Phone',
  [UsersColumns.CREATED_AT]: 'Created on',
  [UsersColumns.LAST_ACTIVITY]: 'Last activity',
  [UsersColumns.ACTIONS]: '',
};

export const usersColumnWidth = {
  [UsersColumns.NAME]: 200,
  [UsersColumns.JOB_TITLE]: 200,
  [UsersColumns.STATUS]: 100,
  [UsersColumns.ROLE]: 114,
  [UsersColumns.EMAIL]: 200,
  [UsersColumns.PHONE]: 155,
  [UsersColumns.CREATED_AT]: 110,
  [UsersColumns.LAST_ACTIVITY]: 120,
  [UsersColumns.ACTIONS]: 60,
};

export const usersColumnFixed: Partial<Record<UsersColumns, 'left' | 'right'>> =
  {
    [UsersColumns.NAME]: 'left',
    [UsersColumns.ACTIONS]: 'right',
  };

export const usersColumnSortOrder: Partial<Record<UsersColumns, SortOrder>> = {
  [UsersColumns.NAME]: SortOrder.ASC,
};

export const getUsersColumnRender = (
  search: string,
  openEdit: (value: TMappedUsersRecord) => void,
  modalDispatch: React.Dispatch<TModalContextStateAction>
) => ({
  [UsersColumns.NAME]: (value: IMappedUsers[UsersColumns.NAME]) => {
    return (
      <Cell isValue={!!value}>
        <Highlighter value={value} search={search} />
      </Cell>
    );
  },
  [UsersColumns.JOB_TITLE]: (value: IMappedUsers[UsersColumns.JOB_TITLE]) => {
    return <Cell isValue={!!value}>{value}</Cell>;
  },
  [UsersColumns.STATUS]: (
    value: IMappedUsers[UsersColumns.STATUS],
    record: TMappedUsersRecord
  ) => {
    return (
      <Cell isValue={!!value.status}>
        <StatusPicker
          status={value.status}
          statusActions={value.statusActions}
          readonly={!value.canEdit || !value.statusActions.length}
          userId={record.key}
          fullName={record.fullName}
        />
      </Cell>
    );
  },
  [UsersColumns.ROLE]: (value: IMappedUsers[UsersColumns.ROLE]) => {
    return <Cell isValue={!!value}>{userRolesLabels[value!]}</Cell>;
  },
  [UsersColumns.EMAIL]: (value: IMappedUsers[UsersColumns.EMAIL]) => {
    return (
      <Cell isValue={!!value}>
        <Email search={search}>{value || ''}</Email>
      </Cell>
    );
  },
  [UsersColumns.PHONE]: (value: IMappedUsers[UsersColumns.PHONE]) => {
    return (
      <Cell isValue={!!value}>
        <Highlighter value={value} search={search} />
      </Cell>
    );
  },
  [UsersColumns.CREATED_AT]: (value: IMappedUsers[UsersColumns.CREATED_AT]) => {
    return <Cell isValue={!!value}>{formatDate({ date: value })}</Cell>;
  },
  [UsersColumns.LAST_ACTIVITY]: (
    value: IMappedUsers[UsersColumns.LAST_ACTIVITY]
  ) => {
    return <Cell isValue={!!value}>{formatDate({ date: value })}</Cell>;
  },
  [UsersColumns.ACTIONS]: (
    value: IMappedUsers[UsersColumns.ACTIONS],
    record: TMappedUsersRecord
  ) => {
    return (
      <div className={clsx(styles.component, styles.end)}>
        {value.canInvite && (
          <Tooltip title="Resend invitation">
            <Button
              size="small"
              type="text"
              onClick={(e) => {
                e.stopPropagation();
                modalDispatch({
                  type: ModalContextActions.SHOW_MODAL,
                  value: {
                    title: 'Resend invitation',
                    description: `A repeat invitation will be sent to ${record.fullName} specified email.`,
                    actionText: 'Resend',
                    action: ActionType.RESEND_INVITATION,
                    dataId: record.key,
                  },
                });
              }}
              icon={
                <IconForwardInbox
                  color={styles.iconFill}
                  height="16"
                  width="16"
                />
              }
            />
          </Tooltip>
        )}
        <Tooltip
          title={
            value.canEdit
              ? 'Edit'
              : 'Admin cannot manage their own user record. You can update personal data via profile.'
          }
        >
          <Button
            disabled={!value.canEdit}
            className={clsx({
              [styles.disabled]: !value.canEdit,
            })}
            size="small"
            type="text"
            onClick={(e) => {
              e.stopPropagation();
              openEdit(record);
            }}
            icon={<IconEdit color={styles.iconFill} height="16" width="16" />}
          />
        </Tooltip>
      </div>
    );
  },
});
