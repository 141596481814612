import { theme } from 'antd';
import React from 'react';
import { SignInIllustration } from 'src/components/sign-in/illustration/SignInIllustration';
import SignInModal from 'src/components/sign-in/modal/SignInModal';
import styles from './SignIn.module.scss';

const SignIn: React.FC = () => {
  const {
    token: { colorPrimary },
  } = theme.useToken();

  return (
    <div className={styles.container} style={{ backgroundColor: colorPrimary }}>
      <SignInIllustration className={styles.illustration} />
      <div className={styles.modalContainer}>
        <SignInModal className={styles.modal} />
      </div>
    </div>
  );
};

export default SignIn;
