import { useMemo, useState } from 'react';
import { SortOrder } from 'src/components/icons/sort/IconSort';
import { useModalContext } from 'src/contexts/modal-context';
import { useUserContext } from 'src/contexts/user-context';
import { ITableParamsWithSearch } from 'src/types/generic';
import {
  TMappedSupplierRecord,
  getCompanyColumnSetting,
  getMappedSupplierData,
} from 'src/utils/map-companies-table';
import useCompanies from './use-companies';
import useTableSettings from './use-table-settings';
import useTableWidth from './use-table-width';

const useSuppliersList = () => {
  const { getSuppliers } = useCompanies();

  const { modalContextDispatch } = useModalContext();
  const {
    userContextState: { isAdmin, isAdvancedUser },
  } = useUserContext();

  const basicUser = !isAdmin && !isAdvancedUser;

  const [data, setData] = useState<TMappedSupplierRecord[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchSuppliers = async (tableParams: ITableParamsWithSearch) => {
    setLoading(true);
    const result = await getSuppliers(tableParams);
    if (result.result?.data.data) {
      setData(getMappedSupplierData(result.result.data.data));
      setTotal(result.result.data.totalCount);
    }
    setLoading(false);
  };

  const { tableParams, search, setSearch, handleTableChange } =
    useTableSettings({
      sortField: 'gln',
      sortOrder: SortOrder.ASC,
      fetcher: fetchSuppliers,
    });

  const columns = useMemo(() => {
    const res = getCompanyColumnSetting<TMappedSupplierRecord>({
      modalDispatch: modalContextDispatch,
      search: tableParams.search,
      isSupplier: true,
      noAction: basicUser || !total,
    });
    return res;
  }, [tableParams, isAdmin, total]);

  const tableWidth = useTableWidth(columns);

  return {
    handleTableChange,
    setSearch,
    loading,
    columns,
    width: tableWidth,
    data,
    total,
    debouncedSearch: tableParams.search,
    open,
    setOpen,
    search,
    current: tableParams.pagination?.current,
  };
};

export default useSuppliersList;
