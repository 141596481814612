export enum ProtectedRoutes {
  DASHBOARD = '/',
  HIVE = '/my-hive',
  SUPPLIERS = '/my-suppliers',
  CUSTOMERS = '/my-customers',
  PRODUCTS = '/my-products',
  INSIGHTS = '/my-insights',
  SOURCES = '/my-sources',
  NEWS_AND_SUPPORT = '/support',
  HIVE_CONTROL_CENTRE = '/control-centre',
}

export enum Routes {
  SIGNIN = '/sign-in',
}

export const RouteTitle = {
  [ProtectedRoutes.DASHBOARD]: 'Dashboard',
  [ProtectedRoutes.HIVE]: 'My Hive',
  [ProtectedRoutes.SUPPLIERS]: 'My Suppliers',
  [ProtectedRoutes.CUSTOMERS]: 'My Customers',
  [ProtectedRoutes.PRODUCTS]: 'My Products',
  [ProtectedRoutes.INSIGHTS]: 'My Hive Insights',
  [ProtectedRoutes.NEWS_AND_SUPPORT]: 'News and Support',
  [ProtectedRoutes.SOURCES]: 'My Sources',
  [ProtectedRoutes.HIVE_CONTROL_CENTRE]: 'Hive Control Centre',
};
