import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from './ObjectHeader.module.scss';
import { Button, Tag, Typography } from 'antd';
import { PresetColorType } from 'antd/es/theme/internal';

type TObjectHeaderProps = {
  className?: string;
  Details?: ReactNode;
  Icon: ReactNode;
  title: string;
  status?: string;
  statusColor?: keyof PresetColorType;
  noActions?: boolean;
  primaryActionTitle?: string;
  primaryActionIcon?: ReactNode;
  primaryAction?: () => void;
  secondaryActionTitle?: string;
  secondaryActionIcon?: ReactNode;
  secondaryAction?: () => void;
};

const ObjectHeader = ({
  className,
  Details,
  Icon,
  title,
  status,
  statusColor,
  noActions = false,
  primaryAction,
  primaryActionTitle,
  primaryActionIcon,
  secondaryAction,
  secondaryActionTitle,
  secondaryActionIcon,
}: TObjectHeaderProps) => {
  return (
    <div className={clsx(styles.component, className)}>
      <div
        className={clsx(styles.header, {
          [styles.start]: noActions,
        })}
      >
        <div className={styles.titleContainer}>
          {Icon}
          <Typography.Title className={styles.title} level={5}>
            {title}
          </Typography.Title>
          {!!status && <Tag color={statusColor}>{status}</Tag>}
        </div>
        {!noActions && (
          <div className={styles.actions}>
            <Button
              size="small"
              type="primary"
              icon={primaryActionIcon}
              onClick={primaryAction}
            >
              {primaryActionTitle}
            </Button>
            <Button
              size="small"
              icon={secondaryActionIcon}
              onClick={secondaryAction}
            >
              {secondaryActionTitle}
            </Button>
          </div>
        )}
      </div>
      {Details}
    </div>
  );
};

export default ObjectHeader;
