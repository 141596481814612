import {
  PropsWithChildren,
  createContext,
  useContext,
  type FunctionComponent,
} from 'react';
import useMessage from 'src/hooks/use-message';

export type TMessageContextValue = {
  showInfoMessage: (text: string) => void;
  showErrorMessage: (text: string) => void;
  showSuccessMessage: (text: string) => void;
};
const messageContexInitialValue: TMessageContextValue = {
  showErrorMessage: () => {},
  showInfoMessage: () => {},
  showSuccessMessage: () => {},
};

const MessageContext = createContext<TMessageContextValue>(
  messageContexInitialValue
);

type MessageContextProviderProps = PropsWithChildren;

export const MessageContextProvider: FunctionComponent<
  MessageContextProviderProps
> = ({ children }): JSX.Element => {
  const {
    contextHolder,
    showInfoMessage,
    showErrorMessage,
    showSuccessMessage,
  } = useMessage();

  return (
    <MessageContext.Provider
      value={{ showErrorMessage, showInfoMessage, showSuccessMessage }}
    >
      {children}
      {contextHolder}
    </MessageContext.Provider>
  );
};

export const useMessageContext = () => {
  const context = useContext(MessageContext);

  if (!context) {
    throw new Error(
      '"MessageContext" context is not in scope. Use this hook in a component that is wrapped with the <MessageContextProvider /> component.'
    );
  }

  return context;
};
