import clsx from 'clsx';
import { type FunctionComponent, type SVGProps } from 'react';

import styles from './IconTrendingUp.module.scss';
import { TIconProps } from 'src/types/icons';

type IconTrendingUpProps = Omit<TIconProps, 'iconId'> & SVGProps<SVGSVGElement>;

export const IconTrendingUp: FunctionComponent<IconTrendingUpProps> = ({
  className,
  width = 24,
  height = 24,
  fill,
  ...props
}): JSX.Element => {
  return (
    <svg
      className={clsx(className, styles.component)}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-element="icon"
      {...props}
    >
      <path
        d="M2.7 17.625C2.5 17.425 2.40417 17.1875 2.4125 16.9125C2.42084 16.6375 2.51667 16.4083 2.7 16.225L7.975 10.875C8.35834 10.4916 8.83334 10.3 9.4 10.3C9.96667 10.3 10.4417 10.4916 10.825 10.875L13.4 13.475L18.6 8.32495H17C16.7167 8.32495 16.4792 8.22912 16.2875 8.03745C16.0958 7.84578 16 7.60828 16 7.32495C16 7.04162 16.0958 6.80412 16.2875 6.61245C16.4792 6.42078 16.7167 6.32495 17 6.32495H21C21.2833 6.32495 21.5208 6.42078 21.7125 6.61245C21.9042 6.80412 22 7.04162 22 7.32495V11.325C22 11.6083 21.9042 11.8458 21.7125 12.0375C21.5208 12.2291 21.2833 12.325 21 12.325C20.7167 12.325 20.4792 12.2291 20.2875 12.0375C20.0958 11.8458 20 11.6083 20 11.325V9.72495L14.825 14.9C14.4417 15.2833 13.9667 15.475 13.4 15.475C12.8333 15.475 12.3583 15.2833 11.975 14.9L9.4 12.325L4.1 17.625C3.91667 17.8083 3.68334 17.9 3.4 17.9C3.11667 17.9 2.88334 17.8083 2.7 17.625Z"
        fill={fill || 'currentColor'}
      />
    </svg>
  );
};
