import { Input, Tooltip } from 'antd';
import { IconCancel } from '../icons/cancel/IconCancel';
import { IconInfoOutlined } from '../icons/info-outlined/IconInfoOutlined';
import { IconSearch } from '../icons/search/IconSearch';
import styles from './SearchInput.module.scss';

type TSearchInputProps = {
  className?: string;
  placeholder?: string;
  search?: string | null;
  setSearch?: (value: string) => void;
};

const SearchInput = ({
  search,
  setSearch,
  className,
  placeholder,
}: TSearchInputProps) => {
  return (
    <Input
      allowClear={
        !!search && { clearIcon: <IconCancel height="16" width="16" /> }
      }
      prefix={<IconSearch width="16" height="16" className={styles.icon} />}
      suffix={
        !search ? (
          <Tooltip title="Type at least 3 characters to start searching">
            <div>
              <IconInfoOutlined
                width="16"
                height="16"
                className={styles.icon}
              />
            </div>
          </Tooltip>
        ) : undefined
      }
      className={className}
      placeholder={placeholder}
      onChange={(event) => {
        setSearch?.(event.target.value);
      }}
    />
  );
};

export default SearchInput;
