import { TResponse } from 'src/contexts/network-context';

export const downloadFile = (
  data: TResponse<Blob, unknown>,
  fileName: string
) => {
  const blob = data.result?.data;

  if (!blob) {
    return;
  }

  const href = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};
