import { Form } from 'antd';
import type { FormItemProps } from 'antd/es/form';
import { IconError } from 'src/components/icons/error/IconError';

type TFormItemProps = FormItemProps & {
  value?: string;
  className?: string;
};

const FormItem = ({
  className,
  children,
  label,
  name,
  rules,
  value,
  hasFeedback = false,
  tooltip,
  valuePropName,
  getValueFromEvent,
  ...props
}: TFormItemProps) => {
  return (
    <Form.Item
      className={className}
      label={label}
      name={name}
      rules={rules}
      tooltip={tooltip}
      valuePropName={valuePropName}
      getValueFromEvent={getValueFromEvent}
      hasFeedback={
        hasFeedback &&
        !value && {
          icons: () => ({
            error: <IconError />,
          }),
        }
      }
      {...props}
    >
      {children}
    </Form.Item>
  );
};

export default FormItem;
