import { Button, Tag, Tooltip } from 'antd';
import { PresetColorType } from 'antd/es/theme/interface';
import { IconDelete } from 'src/components/icons/delete/IconDelete';
import { SortOrder } from 'src/components/icons/sort/IconSort';
import Cell from 'src/components/table/cell/Cell';
import Email from 'src/components/table/email/Email';
import File from 'src/components/table/file/File';
import Highlighter from 'src/components/table/highlighter/Highlighter';
import {
  ActionType,
  ModalContextActions,
  TModalContextStateAction,
} from 'src/contexts/modal-context';
import styles from 'src/styles/actions.module.scss';
import { IMappedInvoice } from 'src/types/mappings';
import { formatDate } from 'src/utils/date-formatter';
import { TMappedInvoicesListRecord } from 'src/utils/map-invoices-table';

export enum InvoiceStatus {
  NONE = 0,
  FAILED = 100,
  PARSED = 200,
  PARTIALLY_PARSED = 300,
  PROCESSING = 400,
  UPLOADED = 500,
}

export const invoiceStatus = {
  [InvoiceStatus.FAILED]: 'Failed',
  [InvoiceStatus.PROCESSING]: 'Processing',
  [InvoiceStatus.PARTIALLY_PARSED]: 'Partially parsed',
  [InvoiceStatus.PARSED]: 'Parsed',
  [InvoiceStatus.UPLOADED]: 'Uploaded',
  [InvoiceStatus.NONE]: '',
};

export const invoiceStatusColor: Partial<
  Record<InvoiceStatus, keyof PresetColorType | undefined>
> = {
  [InvoiceStatus.FAILED]: 'red',
  [InvoiceStatus.PROCESSING]: 'geekblue',
  [InvoiceStatus.PARTIALLY_PARSED]: 'orange',
  [InvoiceStatus.UPLOADED]: 'green',
};

export enum InvoicesColumns {
  ORDER = 'purchaseOrderNumber',
  INVOICE = 'fileName',
  STATUS = 'hiveStatus',
  CUSTOMER = 'customer.name',
  SUPPLIER = 'supplier.name',
  CREATED_AT = 'createdAt',
  CREATED_BY = 'createdByFullName',
  CREATED_BY_EMAIL = 'createdByEmail',
  ACTIONS = 'actions',
}

export const defaultInvoicesColumns = [
  InvoicesColumns.ORDER,
  InvoicesColumns.INVOICE,
  InvoicesColumns.STATUS,
  InvoicesColumns.CUSTOMER,
  InvoicesColumns.SUPPLIER,
  InvoicesColumns.CREATED_AT,
  InvoicesColumns.CREATED_BY,
  InvoicesColumns.CREATED_BY_EMAIL,
  InvoicesColumns.ACTIONS,
];

export const invoicesColumnLabel = {
  [InvoicesColumns.ORDER]: 'Order #',
  [InvoicesColumns.INVOICE]: 'Invoice name',
  [InvoicesColumns.STATUS]: 'Hive Status',
  [InvoicesColumns.CUSTOMER]: 'Customer',
  [InvoicesColumns.SUPPLIER]: 'Supplier',
  [InvoicesColumns.CREATED_AT]: 'Uploaded on',
  [InvoicesColumns.CREATED_BY]: 'Uploaded by',
  [InvoicesColumns.CREATED_BY_EMAIL]: 'Email',
  [InvoicesColumns.ACTIONS]: '',
};

export const invoicesColumnWidth = {
  [InvoicesColumns.ORDER]: 120,
  [InvoicesColumns.INVOICE]: 200,
  [InvoicesColumns.STATUS]: 120,
  [InvoicesColumns.CUSTOMER]: 200,
  [InvoicesColumns.SUPPLIER]: 200,
  [InvoicesColumns.CREATED_AT]: 126,
  [InvoicesColumns.CREATED_BY]: 155,
  [InvoicesColumns.CREATED_BY_EMAIL]: 155,
  [InvoicesColumns.ACTIONS]: 32,
};

export const invoicesColumnFixed: Partial<
  Record<InvoicesColumns, 'left' | 'right'>
> = {
  [InvoicesColumns.ORDER]: 'left',
  [InvoicesColumns.INVOICE]: 'left',
  [InvoicesColumns.ACTIONS]: 'right',
};

export const invoicesColumnSortOrder: Partial<
  Record<InvoicesColumns, SortOrder>
> = {
  [InvoicesColumns.CREATED_AT]: SortOrder.DESC,
};

type TGetInvoicesColumnRenderParams = {
  search: string;
  modalDispatch: React.Dispatch<TModalContextStateAction>;
};

export const getInvoicesColumnRender = ({
  search,
  modalDispatch,
}: TGetInvoicesColumnRenderParams) => ({
  [InvoicesColumns.ORDER]: (value: IMappedInvoice[InvoicesColumns.ORDER]) => {
    return <Cell isValue={!!value}>{value}</Cell>;
  },
  [InvoicesColumns.INVOICE]: (
    value: IMappedInvoice[InvoicesColumns.INVOICE]
  ) => {
    return (
      <Cell isValue={!!value?.fileName}>
        <File search={search} file={value} invoice />
      </Cell>
    );
  },
  [InvoicesColumns.STATUS]: (value: IMappedInvoice[InvoicesColumns.STATUS]) => {
    return (
      <Cell isValue={!!invoiceStatus[value]}>
        <Tag color={invoiceStatusColor[value]}>{invoiceStatus[value]}</Tag>
      </Cell>
    );
  },
  [InvoicesColumns.CUSTOMER]: (
    value: IMappedInvoice[InvoicesColumns.CUSTOMER]
  ) => {
    return (
      <Cell isValue={!!value}>
        <Highlighter value={value} search={search} />
      </Cell>
    );
  },
  [InvoicesColumns.SUPPLIER]: (
    value: IMappedInvoice[InvoicesColumns.SUPPLIER]
  ) => {
    return <Cell isValue={!!value}>{value}</Cell>;
  },
  [InvoicesColumns.CREATED_AT]: (
    value: IMappedInvoice[InvoicesColumns.CREATED_AT]
  ) => {
    return <Cell isValue={!!value}>{formatDate({ date: value })}</Cell>;
  },
  [InvoicesColumns.CREATED_BY]: (
    value: IMappedInvoice[InvoicesColumns.CREATED_BY]
  ) => {
    return <Cell isValue={!!value}>{value}</Cell>;
  },
  [InvoicesColumns.CREATED_BY_EMAIL]: (
    value: IMappedInvoice[InvoicesColumns.CREATED_BY_EMAIL]
  ) => {
    return (
      <Cell isValue={!!value}>
        <Email>{value || ''}</Email>
      </Cell>
    );
  },
  [InvoicesColumns.ACTIONS]: (
    value: IMappedInvoice[InvoicesColumns.ACTIONS],
    record: TMappedInvoicesListRecord
  ) => {
    return (
      <div className={styles.component}>
        {value.canDelete && (
          <Tooltip title="Delete">
            <Button
              size="small"
              type="text"
              onClick={(e) => {
                e.stopPropagation();
                modalDispatch({
                  type: ModalContextActions.SHOW_MODAL,
                  value: {
                    title: 'Delete invoice',
                    description: `Invoice ”${record.fileName?.fileName}” will be permanently deleted.
                This action cannot be undone.`,
                    actionText: 'Delete',
                    action: ActionType.DELETE_INVOICE,
                    dataId: record.key,
                    dataName: record.fileName?.fileName || '-',
                  },
                });
              }}
              icon={
                <IconDelete color={styles.iconFill} height="16" width="16" />
              }
            />
          </Tooltip>
        )}
      </div>
    );
  },
});
