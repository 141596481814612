import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SortOrder } from 'src/components/icons/sort/IconSort';
import { ProtectedRoutes } from 'src/constants/routes';
import { useMeasurementsContext } from 'src/contexts/measurements-context';
import { useModalContext } from 'src/contexts/modal-context';
import { useUserContext } from 'src/contexts/user-context';
import { ITableParamsWithSearch } from 'src/types/generic';
import {
  TMappedProductOfferRecord,
  getAssignedProductsColumnSetting,
  getMappedProductOffersData,
} from 'src/utils/map-assigned-product-table';
import useProducts from './use-products';
import useSupplierFilters from './use-supplier-filters';
import useTableSettings from './use-table-settings';
import useTableWidth from './use-table-width';

const useSupplierProductsTable = () => {
  const {
    userContextState: { isAdmin, isAdvancedUser },
  } = useUserContext();

  const {
    uoms,
    currencies,
    loading: measurementsLoading,
  } = useMeasurementsContext();

  const { modalContextDispatch } = useModalContext();
  const { getSuppliersProducts } = useProducts();

  const navigate = useNavigate();

  const [data, setData] = useState<TMappedProductOfferRecord[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const isActionAllowed = isAdmin || isAdvancedUser;

  const fetchProducts = async (tableParams: ITableParamsWithSearch) => {
    setLoading(true);
    const result = await getSuppliersProducts(tableParams);
    if (result.result?.data.data) {
      setData(getMappedProductOffersData(result.result.data.data));
      setTotal(result.result.data.totalCount);
    }
    setLoading(false);
  };

  const { tableParams, search, setSearch, handleTableChange } =
    useTableSettings({
      sortField: 'createdAt',
      sortOrder: SortOrder.DESC,
      fetcher: fetchProducts,
    });

  const openRead = (record: TMappedProductOfferRecord) => {
    navigate(`${ProtectedRoutes.PRODUCTS}/${record.key}?isSupplier=true`);
  };

  const {
    sortedFilters,
    setFilterSearch,
    filtersLoading,
    debouncedFilterSearch,
  } = useSupplierFilters(tableParams);

  const columns = useMemo(() => {
    const res = getAssignedProductsColumnSetting({
      modalDispatch: modalContextDispatch,
      actionsHidden: true,
      currencies,
      uoms,
      search: tableParams.search,
      withSupplier: true,
      filters: sortedFilters,
      setFilterSearch,
      searchLoading: filtersLoading,
      isFilterSearch: !!debouncedFilterSearch,
    });
    return res;
  }, [tableParams, uoms, currencies, sortedFilters, filtersLoading]);

  const tableWidth = useTableWidth(columns);

  return {
    columns,
    data,
    total,
    handleTableChange,
    setSearch,
    loading: loading || measurementsLoading,
    width: tableWidth,
    debouncedSearch: tableParams.search,
    openRead,
    search,
    current: tableParams.pagination?.current,
  };
};

export default useSupplierProductsTable;
