import { Form } from 'antd';
import { useNavigate } from 'react-router-dom';
import { IconDownload } from 'src/components/icons/download/IconDownload';
import { IconHub } from 'src/components/icons/hub/IconHub';
import ObjectHeader from 'src/components/object-header/ObjectHeader';
import Table from 'src/components/table/Table';
import TableHeader from 'src/components/table/header/TableHeader';
import { NO_PRODUCTS_ASSIGNED } from 'src/constants/validation';
import useSupplierDetails from 'src/hooks/use-supplier-details';
import { TMappedProductOfferRecord } from 'src/utils/map-assigned-product-table';
import styles from './SupplierDetails.module.scss';

const SupplierDetails = () => {
  const {
    supplierName,
    columns,
    data,
    handleTableChange,
    loading,
    width,
    total,
    form,
  } = useSupplierDetails();

  const navigate = useNavigate();

  return (
    <>
      <ObjectHeader Icon={<IconHub />} title={supplierName || '-'} noActions />
      <div className={styles.container}>
        <TableHeader
          title="Assigned products"
          bold={false}
          isSearch={false}
          onlyPrimaryAction
          customActionTitle={'Export to .xlsx'}
          customActionIcon={<IconDownload width="16" height="16" />}
          noActions
        />
        <Form form={form} layout="vertical">
          <Table<TMappedProductOfferRecord>
            emptyTextDescription={NO_PRODUCTS_ASSIGNED}
            columns={columns}
            dataSource={data}
            rowClassName={() => {
              return styles.interactive;
            }}
            onRow={(record) => ({
              onClick: () => {
                navigate(record.key);
              },
            })}
            onChange={handleTableChange}
            loading={loading}
            width={width}
            height="calc(100vh - 328px)"
            total={total}
          />
        </Form>
      </div>
    </>
  );
};

export default SupplierDetails;
