import clsx from 'clsx';
import { type FunctionComponent, type SVGProps } from 'react';

import styles from './IconArrowDown.module.scss';
import { TIconProps } from 'src/types/icons';

type IconArrowDownProps = Omit<TIconProps, 'iconId'> & SVGProps<SVGSVGElement>;

export const IconArrowDown: FunctionComponent<IconArrowDownProps> = ({
  className,
  width = 24,
  height = 24,
  fill,
  ...props
}): JSX.Element => {
  return (
    <svg
      className={clsx(className, styles.component)}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-element="icon"
      {...props}
    >
      <path
        d="M11.995 15.2998C11.875 15.2998 11.7625 15.2789 11.6575 15.2373C11.5525 15.1956 11.4583 15.1331 11.375 15.0498L6.6335 10.3083C6.4445 10.1193 6.35416 9.90392 6.3625 9.66226C6.37083 9.42059 6.46666 9.20809 6.65 9.02476C6.83333 8.84142 7.04583 8.74976 7.2875 8.74976C7.52916 8.74976 7.74166 8.84142 7.925 9.02476L12 13.1248L16.1 9.02476C16.2833 8.84142 16.4958 8.75392 16.7375 8.76226C16.9792 8.77059 17.1917 8.86642 17.375 9.04976C17.5583 9.23309 17.65 9.44559 17.65 9.68726C17.65 9.92892 17.5555 10.1438 17.3665 10.3318L12.625 15.0498C12.535 15.1331 12.4375 15.1956 12.3325 15.2373C12.2275 15.2789 12.115 15.2998 11.995 15.2998Z"
        fill={fill || 'currentColor'}
      />
    </svg>
  );
};
