import { Form, GetProp, UploadFile, UploadProps, message, theme } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { IconCheckOutlined } from 'src/components/icons/check-outlined/IconCheckOutlined';
import File from 'src/components/table/file/File';
import styles from 'src/pages/support/Support.module.scss';
import useSupport from './use-support';
import { DefaultOptionType } from 'antd/es/select';
import { SupportFormFields } from 'src/constants/support';
import { IconError } from 'src/components/icons/error/IconError';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const useSupportForm = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const attachments = Form.useWatch('attachments', form);
  const [file, setFile] = useState<UploadFile | null>(null);
  const [disabled, setDisabled] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [priorityOptions, setPriorityOptions] = useState<DefaultOptionType[]>(
    []
  );
  const [typeOptions, setTypeOptions] = useState<DefaultOptionType[]>([]);

  const { getIssuePriorities, getIssueTypes, createSupportRequest } =
    useSupport();

  useEffect(() => {
    const fetchOptions = async () => {
      setLoading(true);
      const priorities = await getIssuePriorities();
      const types = await getIssueTypes();

      if (priorities.result) {
        setPriorityOptions(
          priorities.result.data.map((el) => ({ value: el.id, label: el.name }))
        );
      }

      if (types.result) {
        setTypeOptions(
          types.result.data.map((el) => ({ value: el.id, label: el.name }))
        );
      }
      setLoading(false);
    };
    fetchOptions();
  }, []);

  const defaultPriority = useMemo(
    () =>
      priorityOptions.find((el) => (el.label as string).includes('Minor'))
        ?.value || '',
    [priorityOptions]
  );

  useEffect(() => {
    form.setFieldValue(SupportFormFields.ISSUE_PRIORITY, defaultPriority);
  }, [defaultPriority]);

  const {
    token: { colorSuccess, colorError },
  } = theme.useToken();

  useEffect(() => {
    form.setFieldValue('priority', '1');
  }, []);

  const draggerProps: UploadProps = {
    style: {
      ...(file
        ? {
            display: 'none',
          }
        : {
            maxHeight: '146px',
          }),
    },
    multiple: false,
    disabled,
    maxCount: 1,
    onRemove: () => {
      setFile(null);
    },
    itemRender: (_, file, __, actions) => {
      return (
        <File
          file={{
            fileName: file.name || '',
            blobName: attachments?.blobName || file.name || '',
            createdAt: new Date(),
          }}
          onDelete={disabled ? undefined : actions.remove}
        />
      );
    },
    beforeUpload: (file) => {
      setFile(file);

      return false;
    },
    fileList: file ? [file] : [],
  };

  const submit = () => {
    form
      .validateFields()
      .then(async (data) => {
        setDisabled(true);

        const body = new FormData();
        body.append(SupportFormFields.FILE, file as FileType);
        body.append(
          SupportFormFields.ISSUE_DESCRIPTION,
          data[SupportFormFields.ISSUE_DESCRIPTION]
        );
        body.append(
          SupportFormFields.ISSUE_PRIORITY,
          data[SupportFormFields.ISSUE_PRIORITY]
        );
        body.append(
          SupportFormFields.ISSUE_TYPE,
          data[SupportFormFields.ISSUE_TYPE]
        );

        const res = await createSupportRequest(body);

        setDisabled(false);

        if (res.errors) {
          messageApi.error({
            content: 'Support request was not sent',
            icon: (
              <IconError
                className={styles.marginRight}
                height="16"
                width="16"
                fill={colorError}
              />
            ),
          });
          return;
        }

        form.setFieldsValue({
          [SupportFormFields.ISSUE_TYPE]: null,
          [SupportFormFields.ISSUE_DESCRIPTION]: null,
          [SupportFormFields.ISSUE_PRIORITY]: defaultPriority,
          [SupportFormFields.FILE]: null,
        });
        setFile(null);

        messageApi.success({
          content: 'Support request sent',
          icon: (
            <IconCheckOutlined
              className={styles.marginRight}
              height="16"
              width="16"
              fill={colorSuccess}
            />
          ),
        });
      })
      .catch(() => {});
  };

  return {
    form,
    draggerProps,
    contextHolder,
    disabled,
    submit,
    priorityOptions,
    typeOptions,
    loading,
  };
};

export default useSupportForm;
